import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FileUploadControl } from '@iplab/ngx-file-upload';

import { EditableCellChangeI } from '../../../shared/models';

const DEFAULT_TABLE_MESSAGES = {
  noRecords: 'Generic.messages.noFilesSelected',
};
const FILE_NAME_KEY_CUSTOM = 'fileName';

@Component({
  selector: 'prosumer-upload-form-list',
  templateUrl: './upload-form-list.component.html',
  styleUrls: ['./upload-form-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UploadFormListComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<File[] | File | undefined>();
  @Output() editableCellChanged = new EventEmitter<EditableCellChangeI>();
  @Input() multipleFiles = false;
  @Input() hideTableHeader = true;
  @Input() hideTableMessages = false;
  @Input() formTitle: string | undefined = undefined;
  @Input() infoMessage: string | undefined = undefined;
  @Input() acceptedFiles: string | undefined = undefined; // ex: '.gms|audio/*'
  @Input() columnDef: Record<string, Record<string, string>>;
  @Input() tableMessages: Record<string, string> = DEFAULT_TABLE_MESSAGES;
  @Input() customTableDataActive: boolean = false;
  @Input() disabledTable: boolean = false;
  @Input() set setCustomTableData(list: Record<string, string>[]) {
    if (list.every((d) => Object.keys(d).includes(FILE_NAME_KEY_CUSTOM))) {
      this.tableData.set(list);
    }
  }
  @Input() set disabledFileCtrl(d: boolean) {
    this.fileUploadCtrl.disable(!!d);
  }

  readonly fileUploadCtrl = new FileUploadControl({ listVisible: false });
  readonly tableData = signal<any[]>([]);

  ngOnInit(): void {
    this.subToCtrlChange();
  }

  onDelete(data: File | Record<string, string>) {
    if (this.customTableDataActive) {
      this.deleteWhenCustom(data as Record<string, string>);
    } else {
      this.fileUploadCtrl.removeFile(data as File);
    }
  }

  onEditableCellChange(e: EditableCellChangeI) {
    this.editableCellChanged.emit(e);
  }

  private subToCtrlChange() {
    this.fileUploadCtrl.valueChanges.subscribe((v) => {
      if (!this.customTableDataActive) this.tableData.set(v);
      this.valueChanged.emit(this.determineValIfMultiple(v));
    });
  }

  private determineValIfMultiple(v: File[]): File[] | File {
    if (this.multipleFiles) {
      return v;
    } else {
      return v[0];
    }
  }

  private deleteWhenCustom(d: Record<string, string>) {
    const res = this.fileUploadCtrl.value.find(
      (f) => f.name === d[FILE_NAME_KEY_CUSTOM],
    );
    if (res) this.fileUploadCtrl.removeFile(res);
  }
}
