import { Utils } from 'prosumer-core/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, Inject } from '@angular/core';
import { EquipmentResult } from '@prosumer/results/models';

import { ResultsPerceptionService } from '../case-results-perception';
import {
  RESULT_VIZ_SERVICE,
  ResultsVisualizerController,
} from '../results-visualizer';
import { EquipmentResultsService } from './equipment-results.service';

@Component({
  selector: 'prosumer-equipment-results',
  templateUrl: './equipment-results.component.html',
  styleUrls: ['./equipment-results.component.scss'],
  providers: [
    { provide: RESULT_VIZ_SERVICE, useClass: EquipmentResultsService },
  ],
  standalone: false,
})
export class EquipmentResultsComponent extends ResultsVisualizerController<EquipmentResult> {
  constructor(
    @Inject(RESULT_VIZ_SERVICE) service: EquipmentResultsService,
    perception: ResultsPerceptionService,
  ) {
    super(service, perception);
  }

  getLegendNames$(): Observable<string[]> {
    return this.cumulative$.pipe(
      map((cumu) => cumu.map((cumuSingle) => cumuSingle.name)),
      map((cumu) => Utils.removeDuplicates(cumu)),
    );
  }
}
