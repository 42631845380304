import {
  EnergyGridConnection,
  EnergyVector,
  Equipment,
  SpinningReserve,
} from 'prosumer-app/+scenario/models';
import { BaseFormComponent } from 'prosumer-app/libs/eyes-shared';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';

@Component({
  selector: 'prosumer-spinning-reserves-form',
  templateUrl: './spinning-reserves-form.component.html',
  styleUrls: ['./spinning-reserves-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SpinningReservesComponent extends BaseFormComponent {
  @Input() energyVectors: Array<EnergyVector>;
  @Input() equipments: Array<Equipment>;
  @Input() markets: Array<EnergyGridConnection>;
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() scenarioIdentity: {
    projectId: string;
    caseId: string;
    scenarioId: string;
  };

  @Input() hideAdd: boolean;
  @Input() hideEdit: boolean;
  @Input() hideDelete: boolean;
  @Input() hideView: boolean;

  get marginsControl() {
    return this.controls.margins;
  }

  get activationsControl() {
    return this.controls.activations;
  }

  get equipmentsControl() {
    return this.controls.equipments;
  }

  get marketsControl() {
    return this.controls.markets;
  }

  get networksControl() {
    return this.controls.networks;
  }

  get binToDeleteControl() {
    return this.controls.binToDelete;
  }

  constructor(
    public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  defineForm() {
    return {
      activations: [],
      binToDelete: [],
      equipments: [],
      margins: [],
      markets: [],
      networks: [],
    } as SpinningReserve;
  }
}
