import moment from 'moment';
import { DEFAULT_DEBOUNCE_TIME } from 'prosumer-app/app.references';
import { dayUtils } from 'prosumer-app/shared';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { TraditionalDispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

@Component({
  selector: 'prosumer-weekly-dispatch',
  templateUrl: './weekly-dispatch.component.html',
  styleUrls: ['./weekly-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WeeklyDispatchComponent
  extends TraditionalDispatchAdapter
  implements OnInit
{
  constructor(
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  @Input() type: 'production' | 'consumption';
  @Input() chartTitle: string;
  @Input() scenarioName: string;
  @Output() dataLoaded = new EventEmitter<Array<any>>();

  data$: Observable<any>;
  allData: any;

  ngOnInit() {
    this.data$ = combineLatest([
      this._resultStore.loading$,
      this._resultStore.isOutputSplit$,
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      combineLatest([
        this._dispatchService.minDate$,
        this._dispatchService.maxDate$,
      ]).pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed$),
      ),
    ]).pipe(
      switchMap(
        ([loading, isSplit, year, node, energyVector, [minDay, maxDay]]) =>
          this._resultStore
            .getRawDispatchByParams$(year, node, energyVector, this.type)
            .pipe(
              tap((data) => {
                this.allData = { dispatchData: data };
              }),
              map((filteredData) => {
                const minDate =
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  year && minDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).dayOfYear(minDay)
                    : undefined;
                const maxDate =
                  year && maxDay
                    ? // eslint-disable-next-line @typescript-eslint/naming-convention
                      moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 23,
                        m: 59,
                        s: 59,
                        ms: 999,
                      }).dayOfYear(maxDay)
                    : undefined;
                return !filteredData || filteredData.length === 0
                  ? undefined
                  : filteredData.map((filteredDataItem) => {
                      const dataSet = !filteredDataItem.values
                        ? []
                        : (filteredDataItem.values as Array<any>)
                            .map((value, index) => ({
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              hour: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              })
                                .hour(index)
                                .get('h'),
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              moment: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              }).hour(index),
                              // eslint-disable-next-line @typescript-eslint/naming-convention
                              day: moment({
                                y: +year,
                                M: 0,
                                d: 1,
                                h: 0,
                                m: 0,
                                s: 0,
                                ms: 0,
                              })
                                .hour(index)
                                .get('d'),
                              value,
                            }))
                            .filter((dataSetItem) =>
                              minDate && maxDate
                                ? minDate.isSameOrBefore(dataSetItem.moment) &&
                                  maxDate.isSameOrAfter(dataSetItem.moment)
                                : true,
                            );

                      if (
                        dataSet &&
                        dataSet instanceof Array &&
                        dataSet.length > 0
                      ) {
                        const series = [];
                        const groupedData = this.groupByDayAndHour(dataSet);
                        Array.from(Array(168).keys()).forEach((i) => {
                          const weeklyDataSet = groupedData.filter(
                            (data) => data && data.newIndex === i + 1,
                          );
                          if (weeklyDataSet.length > 0) {
                            const weeklyData = weeklyDataSet
                              .map((data) => ({
                                ...data,
                                name: '' + data.newIndex,
                              }))
                              .reduce((prev, curr) => ({ ...curr }));
                            series.push({
                              ...weeklyData,
                              value: weeklyData.average,
                            });
                          } else {
                            series.push({
                              hour: i,
                              value: 0.0,
                              average: 0.0,
                              day: Math.trunc((i + 1) / 24),
                              name: (i + 1).toString(),
                              newIndex: i + 1,
                            });
                          }
                        });
                        return {
                          name: filteredDataItem.der,
                          series,
                        };
                      }
                      return {
                        name: filteredDataItem.der,
                        series: undefined,
                      };
                    });
              }),
              takeUntil(this.componentDestroyed$),
            ),
      ),
      tap((data) => this.dataLoaded.emit(data)),
      takeUntil(this.componentDestroyed$),
    );
    this.initializeChartDataAndColors();
  }

  groupByDayAndHour(dataSet: Array<any>) {
    const holder = {};
    const result = dataSet.slice().reduce((acc, current) => {
      const key = current.day + '-' + current.hour;
      // new index based on day and hour
      current.newIndex = this.computeNewIndex(current.day, current.hour);

      if (!holder[key]) {
        // create a copy of current as initial value
        holder[key] = Object.assign({}, current);
        holder[key].count = 1;
        acc.push(holder[key]);
      } else {
        holder[key].value += current.value;
        holder[key].count += 1;
      }
      holder[key].average = holder[key].value / holder[key].count;

      return acc;
    }, []);

    // sort result based on index; return data from Monday to Sunday
    result.sort((a, b) => a.newIndex - b.newIndex);
    return result;
  }

  computeNewIndex(day: number, hour: number) {
    // day 0 = Sunday but display in the graph should be Monday to Sunday
    const startHour = day === 0 ? 144 : (day - 1) * 24;
    // start of new index is 1
    return startHour + hour + 1;
  }

  formatTick(tick: string): string {
    return dayUtils.prependDay(Number(tick));
  }
  getChartName(): string {
    return this.chartTitle;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.data$;
  }
}
