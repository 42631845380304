import { Directive, OnInit } from '@angular/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { NodeStore } from 'prosumer-app/stores/node';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerNodesGetter]',
  standalone: false,
})
export class NodesGetterDirective implements OnInit {
  constructor(
    private readonly nodes: NodeStore,
    private notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.nodes
      .getNodes()
      .pipe(take(1))
      .subscribe({ error: () => this.notifs.showError(this.getErrorMsg()) });
  }

  private getErrorMsg(): string {
    return `Failed to get the nodes for this scenario`;
  }
}
