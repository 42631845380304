import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnergyVector } from 'prosumer-app/+scenario/models';
import { DIRECTIONS } from 'prosumer-app/app.references';
import {
  CustomValidators,
  FormDialogComponent,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { provideUpserter } from 'prosumer-app/stores';
import { SpinningReserveActivationStore } from 'prosumer-app/stores/spinning-reserve-activation';
import { of } from 'rxjs';

import { ReserveActivationFormDialogData } from './activation-form-dialog.model';

@Component({
  selector: 'prosumer-reserve-activation-form-dialog',
  templateUrl: './activation-form-dialog.component.html',
  styleUrls: ['./activation-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideUpserter(SpinningReserveActivationStore)],
  animations: [fadeInAnimation],
  standalone: false,
})
export class ReserveActivationFormDialogComponent
  extends FormDialogComponent<
    ReserveActivationFormDialogComponent,
    ReserveActivationFormDialogData
  >
  implements AfterViewInit, OnInit
{
  energyVectorOptions: Array<EnergyVector> = [];
  directions = DIRECTIONS;

  sortedDirections = Object.keys(DIRECTIONS)
    .map((dirKey) => ({
      key: dirKey,
      value: DIRECTIONS[dirKey],
    }))
    .sort();

  get energyVectorIdControl() {
    return this.form.controls.energyVectorId;
  }

  get directionControl() {
    return this.form.controls.direction;
  }

  get rateControl() {
    return this.form.controls.rate;
  }

  get durationControl() {
    return this.form.controls.duration;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReserveActivationFormDialogData,
    public changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ReserveActivationFormDialogComponent>,
    public formBuilder: UntypedFormBuilder,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      energyVectorId: ['', Validators.required],
      direction: ['up', Validators.required],
      rate: ['0.0', [Validators.required, Validators.min(0)]],
      duration: ['0.0', [Validators.required, Validators.min(0)]],
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.energyVectorOptions = this.data.energyVectorOptions || [];
    this.updateValidators();
  }

  ngAfterViewInit() {
    setTimeout(() =>
      this.form.patchValue(
        {
          ...this.data.currentReserveActivation,
        } || {},
      ),
    );
  }

  updateValidators() {
    this.energyVectorIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.currentReserveActivationList &&
          this.data.currentReserveActivationList.length > 0
          ? of(this.data.currentReserveActivationList)
          : null,
        'energyVectorId',
        this.data.currentReserveActivation,
        { direction: this.directionControl as UntypedFormControl },
      ),
    ]);

    this.directionControl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.energyVectorIdControl.updateValueAndValidity());
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }
}
