import { EyesSharedModule } from 'prosumer-libs/eyes-shared/eyes-shared.module';
import { TDBCommodityPricesAdditionalCosts } from 'prosumer-shared/modules/tdb/models/CommodityPrices.model';
import { TdbAdditionalCostsHelperService } from 'prosumer-shared/modules/tdb/services/tdb-additional-costs-helper/tdb-additional-costs-helper.service';
import { SharedModule } from 'prosumer-shared/shared.module';
import { debounceTime, map } from 'rxjs/operators';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-tdb-commodity-optional-filters',
  templateUrl: './tdb-commodity-optional-filters.component.html',
  styleUrls: ['./tdb-commodity-optional-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    EyesSharedModule,
    SharedModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TdbCommodityOptionalFiltersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TdbCommodityOptionalFiltersComponent),
      multi: true,
    },
  ],
})
export class TdbCommodityOptionalFiltersComponent
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() yearList: [number, number];
  readonly secondaryForm: UntypedFormGroup = this.initForm();

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly helper: TdbAdditionalCostsHelperService,
  ) {}

  public registerOnTouched(fn: () => void) {}
  public registerOnChange = (fn: (value: string) => void) => {
    this.onChange = fn;
  };
  public writeValue(v: any) {
    if (!v) this.initFormValues();
  }
  public setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.secondaryForm.disable();
    } else {
      this.secondaryForm.enable();
    }
  }
  onChange(v: any) {}
  validate(ctrl: AbstractControl): ValidationErrors | null {
    return this.secondaryForm.invalid ? { required: true } : null;
  }

  ngOnInit() {
    this.initFormValues();
    this.subToFormChanges();
  }

  private initForm() {
    return this._formBuilder.group({
      [TDBCommodityPricesAdditionalCosts.margin]: [null, [Validators.required]],
      [TDBCommodityPricesAdditionalCosts.transpoAndDistrib]: [
        null,
        [Validators.required],
      ],
      [TDBCommodityPricesAdditionalCosts.indexation]: [
        null,
        [Validators.required],
      ],
      [TDBCommodityPricesAdditionalCosts.taxes]: [null, [Validators.required]],
    });
  }

  private initFormValues() {
    const initialV = this.helper.initYearlyValue(this.yearList);
    this.secondaryForm.setValue({
      [TDBCommodityPricesAdditionalCosts.margin]: initialV,
      [TDBCommodityPricesAdditionalCosts.transpoAndDistrib]: initialV,
      [TDBCommodityPricesAdditionalCosts.indexation]: initialV,
      [TDBCommodityPricesAdditionalCosts.taxes]: initialV,
    });
  }

  private subToFormChanges() {
    this.secondaryForm.valueChanges
      .pipe(
        debounceTime(300),
        map((d) => this.helper.formatPercentageToFloat(d)),
      )
      .subscribe((d) => {
        this.onChange(d);
      });
  }
}
