import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Scenario } from 'prosumer-app/+scenario/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ScenarioStatusDialogComponent } from '../scenario-status-dialog';

@Component({
  selector: 'prosumer-scenario-status',
  templateUrl: './scenario-status.component.html',
  styleUrls: ['./scenario-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioStatusComponent {
  private readonly scenarioCache = new BehaviorSubject<Scenario>(undefined);
  @Input() set scenario(scenario: Scenario) {
    this.scenarioCache.next(scenario);
  }
  readonly scenario$ = this.selectTruthyScenario();

  constructor(private readonly dialog: MatDialog) {}

  shouldShowVariations(scenario: Scenario): boolean {
    return [scenario.hasScenarioVariations].every(Boolean);
  }

  onVariationsClick(): void {
    this.dialog.open(
      ScenarioStatusDialogComponent,
      this.buildVariationsConfig(this.scenarioCache.value),
    );
  }

  private buildVariationsConfig(data: Scenario): MatDialogConfig {
    return { data, width: '800px' };
  }

  private selectTruthyScenario(): Observable<Scenario> {
    return this.scenarioCache.pipe(filter((scenario) => !!scenario));
  }
}
