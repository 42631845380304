import { InjectionToken } from '@angular/core';

export const TRAINING_URL_REDIRECT_PROVIDER = new InjectionToken(
  'training-url-redirect',
);
export const UPDATES_URL_REDIRECT_PROVIDER = new InjectionToken(
  'updates-url-redirect',
);
export const DOCUMENTATION_URL_REDIRECT_PROVIDER = new InjectionToken(
  'documentation-url-redirect',
);
