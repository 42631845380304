import moment, { MomentInput } from 'moment';
import { Coerce } from 'prosumer-core/utils/coercion.util';

import { Pipe, PipeTransform } from '@angular/core';

export const REFERENCE_DATE_FORMAT = 'DD-MMM-YYYY';

@Pipe({
  name: 'referenceStr',
  standalone: false,
})
export class TDBReferencePipe implements PipeTransform {
  transform(record: Record<string, string | number> | undefined): string {
    record = Coerce.toObject(record);
    if (!Object.values(record).length) return '';
    const { updatedAt, filterDate, ...filters } = record;
    const momentDate = this.toMomentInput(filterDate, updatedAt);
    const dateStr = moment(momentDate).format(REFERENCE_DATE_FORMAT);
    return Object.values(filters).join(' | ').concat(' at ').concat(dateStr);
  }

  private toMomentInput(
    updatedAt: string | number,
    filterDate: string | number,
  ): MomentInput {
    return filterDate ?? updatedAt;
  }
}
