import { Directive, OnInit } from '@angular/core';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { FuelStore } from 'prosumer-app/stores/fuel';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerFuelsGetter]',
  standalone: false,
})
export class FuelsGetterDirective implements OnInit {
  constructor(
    private readonly fuels: FuelStore,
    private notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.fuels
      .getFuels()
      .pipe(take(1))
      .subscribe({ error: () => this.notifs.showError(this.getErrorMsg()) });
  }

  private getErrorMsg(): string {
    return `Failed to get the fuels for this scenario`;
  }
}
