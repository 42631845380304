import { PipeUtils } from 'prosumer-core/utils';
import { StackedAreaData } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';

import { ResultNameValue, VisualizerData } from '../../results-visualizer';
import { MainOutputResultsService } from '../main-output-results.service';

@Component({
  selector: 'prosumer-total-discounted-cost',
  templateUrl: './total-discounted-cost.component.html',
  styleUrls: ['./total-discounted-cost.component.scss'],
  standalone: false,
})
export class TotalDiscountedCostComponent implements OnInit {
  @Input() colors: PerceptionMap;
  @Input() scenarioName: string;

  yAxisLabel = 'k€';
  yAxisValueConvertion = 1000;
  data$: Observable<StackedAreaData>;

  constructor(private results: MainOutputResultsService) {}

  ngOnInit(): void {
    this.results.discountedCost$.subscribe((data) => {
      const filteredData = this.filterEmptyValues(data);
      this.data$ = this.getChartJSDataStream(of(filteredData));
    });
  }

  private filterEmptyValues(data: VisualizerData[]): VisualizerData[] {
    return data.filter((datum) => !!datum.series);
  }

  private getChartJSDataStream(
    cumulative: Observable<VisualizerData[]>,
  ): Observable<StackedAreaData> {
    return cumulative.pipe(
      PipeUtils.filterOutEmpty,
      map((data) => this.mapToChartJSData(data)),
    );
  }

  private mapToChartJSData(data: VisualizerData[]): StackedAreaData {
    const years = data[0].series.map((siri) => siri.name);
    return {
      xAxisTicks: years,
      stacks: data.map((datum) => ({
        label: datum.name,
        points: this.mapToValueConvertedPoints(datum.series),
      })),
      xAxisLabel: 'Years',
      yAxisLabel: this.yAxisLabel,
      name: 'Total Discounted Cost',
    };
  }

  private mapToValueConvertedPoints(values: ResultNameValue[]): number[] {
    return values.map(({ value }) => value / this.yAxisValueConvertion);
  }
}
