import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filterNilValue } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoherenceResult } from 'prosumer-app/+scenario/models';
import { CoherenceTracker } from 'prosumer-app/+scenario/services';
import { ScenarioWizardStep } from 'prosumer-app/+scenario/services/scenario-completion';
import {
  ScenarioGeneric,
  ScenarioGenericQuery,
} from 'prosumer-app/stores/scenario-generic';
import { Observable } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';

type HasScenarioTab = Readonly<{
  scenarioTab: string;
}>;

@Directive({
  selector: '[prosumerCoherenceChecker]',
  standalone: false,
})
@UntilDestroy()
export class CoherenceCheckerDirective implements OnInit {
  @Output() results = new EventEmitter<CoherenceResult[]>();
  @Output() coherencing = new EventEmitter<boolean>();
  @Output() haveErrors = new EventEmitter<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly coherencer: CoherenceTracker,
    private readonly scenarios: ScenarioGenericQuery,
  ) {}

  ngOnInit(): void {
    this.subscribeToActiveTabForCoherenceCheck();
    this.subscribeToTrackerForResultsPropagation();
    this.subscribeToTrackerForCoherencingPropagation();
    this.subscribeToTrackerForErrorPropagation();
  }

  private subscribeToActiveTabForCoherenceCheck(): void {
    this.selectOnlyLaunchTab()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.doCoherenceCheck());
  }

  private subscribeToTrackerForResultsPropagation(): void {
    this.coherencer
      .selectRecentChecks()
      .pipe(untilDestroyed(this))
      .subscribe((checks) => {
        this.results.emit(checks.results);
      });
  }

  private subscribeToTrackerForCoherencingPropagation(): void {
    this.coherencer
      .selectChecking()
      .pipe(untilDestroyed(this))
      .subscribe((checking) => {
        this.coherencing.emit(checking);
      });
  }

  private subscribeToTrackerForErrorPropagation(): void {
    this.coherencer
      .selectHasErrors()
      .pipe(untilDestroyed(this))
      .subscribe((hasErrors) => {
        this.haveErrors.emit(hasErrors);
      });
  }

  private doCoherenceCheck(): void {
    this.selectTruthyActiveScenario()
      .pipe(
        mergeMap((scenario) => this.coherencer.check(scenario)),
        take(1),
      )
      .subscribe();
  }

  private selectTruthyActiveScenario(): Observable<ScenarioGeneric> {
    return this.scenarios.selectActive().pipe(filterNilValue());
  }

  private selectOnlyLaunchTab(): Observable<unknown> {
    return this.route.queryParams.pipe(
      filter(
        (params: HasScenarioTab) =>
          params.scenarioTab === ScenarioWizardStep.launch,
      ),
    );
  }
}
