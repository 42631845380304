import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prosumer-session-expired-message',
  templateUrl: './session-expired-message.component.html',
  styleUrls: ['./session-expired-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SessionExpiredMessageComponent {
  constructor(
    private router: Router,
    public translate: TranslateService,
  ) {}

  redirectLogin() {
    this.router.navigate(['/login']);
  }
}
