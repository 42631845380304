import { EnergyVector, Equipment } from 'prosumer-app/+scenario/models';
import { VariationFinder } from 'prosumer-app/+scenario/services';
import { DIRECTIONS, PARTICIPATIONS } from 'prosumer-app/app.references';
import {
  CustomValidators,
  fadeInAnimation,
  FormDialogComponent,
} from 'prosumer-app/libs/eyes-shared';
import { of } from 'rxjs';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ReserveEquipmentStore } from 'prosumer-app/stores/reserve-equipment';
import { provideUpserter } from 'prosumer-app/stores/scenario-detail';
import { ReserveEquipmentFormDialogData } from './equipment-form-dialog.model';

@Component({
  selector: 'prosumer-reserve-equipment-form-dialog',
  templateUrl: './equipment-form-dialog.component.html',
  styleUrls: ['./equipment-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInAnimation],
  providers: [provideUpserter(ReserveEquipmentStore)],
  standalone: false,
})
export class ReserveEquipmentFormDialogComponent
  extends FormDialogComponent<
    ReserveEquipmentFormDialogComponent,
    ReserveEquipmentFormDialogData
  >
  implements AfterViewInit, OnInit
{
  equipmentOptions: Array<Equipment> = [];
  energyVectorOptions: Array<EnergyVector> = [];

  sortedDirections = Object.keys(DIRECTIONS)
    .map((dirKey) => ({
      key: dirKey,
      value: DIRECTIONS[dirKey],
    }))
    .sort();

  sortedParticipations = Object.keys(PARTICIPATIONS)
    .map((dirKey) => ({
      key: dirKey,
      value: PARTICIPATIONS[dirKey],
    }))
    .sort();

  get equipmentIdControl() {
    return this.form.controls.equipmentId;
  }

  get energyVectorIdControl() {
    return this.form.controls.energyVectorId;
  }

  get directionControl() {
    return this.form.controls.direction;
  }

  get participationControl() {
    return this.form.controls.participation;
  }

  get requirementControl() {
    return this.form.controls.requirement;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReserveEquipmentFormDialogData,
    public changeDetector: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ReserveEquipmentFormDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private readonly varFinder: VariationFinder,
  ) {
    super(null, changeDetector, dialogRef, formBuilder);
  }

  defineForm() {
    return {
      equipmentId: ['', Validators.required],
      energyVectorId: ['', Validators.required],
      direction: ['up', Validators.required],
      participation: [true, Validators.required],
      requirement: ['0.0', [Validators.required, Validators.min(0)]],
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.energyVectorOptions = this.data.energyVectorOptions || [];
    if (this.data.equipmentOptions) {
      this.data.equipmentOptions
        .map((equipment) => this.toWithVariationInName(equipment))
        .forEach((option) => this.equipmentOptions.push(option));
    }
    this.updateValidators();
  }

  ngAfterViewInit() {
    setTimeout(() =>
      this.form.patchValue(
        {
          ...this.data.currentReserveEquipment,
        } || {},
      ),
    );
  }

  updateValidators() {
    this.equipmentIdControl.setAsyncValidators([
      CustomValidators.dataExist(
        !!this.data.currentReserveEquipmentList &&
          this.data.currentReserveEquipmentList.length > 0
          ? of(this.data.currentReserveEquipmentList)
          : null,
        'equipmentId',
        this.data.currentReserveEquipment,
        {
          energyVectorId: this.energyVectorIdControl as UntypedFormControl,
          direction: this.directionControl as UntypedFormControl,
        },
      ),
    ]);

    this.energyVectorIdControl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.equipmentIdControl.updateValueAndValidity());

    this.directionControl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.equipmentIdControl.updateValueAndValidity());
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt(): void {
    this.changeDetector.detectChanges();
  }

  private toWithVariationInName(equipment: Equipment): Equipment {
    return {
      ...equipment,
      name: this.concatenateVariationName(equipment.name, equipment),
    };
  }

  private concatenateVariationName(name: string, equipment: Equipment): string {
    return `${name} (${this.varFinder.getVariationName(
      equipment.scenarioVariation,
    )})`;
  }
}
