import { ResultStore } from 'prosumer-app/stores';
import {
  BarDatum,
  StackedBarMeta,
} from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { DispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

const _arrDaysAndMonths = () => ({
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
});

@Component({
  selector: 'prosumer-monthly-dispatch',
  templateUrl: './monthly-dispatch.component.html',
  styleUrls: ['./monthly-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MonthlyDispatchComponent
  extends DispatchAdapter<VisualizerData[], StackedBarMeta>
  implements OnInit
{
  // Selector inputs/outputs:
  @Input() type: 'production' | 'consumption';
  @Input() chartTitle: string;
  @Input() scenarioName: string;
  @Output() dataLoaded = new EventEmitter<Array<any>>();

  // Class members:
  data$: Observable<any>;
  allData: any;
  loaded$ = new BehaviorSubject<boolean>(false);
  startingHour = 0;

  constructor(
    private _resultStore: ResultStore,
    private _dispatchService: DispatchService,
    private _titleCase: TitleCasePipe,
    perception: ResultsPerceptionService,
  ) {
    super(perception);
  }

  ngOnInit() {
    // Subscribe to dispatch changes
    this.data$ = combineLatest([
      this._resultStore.loading$,
      this._resultStore.isOutputSplit$,
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
    ]).pipe(
      filter(([loading, isSplit]) => !loading || !isSplit),
      switchMap(([loading, isSplit, year, node, energyVector]) =>
        this._resultStore
          .getRawDispatchByParams$(year, node, energyVector, this.type)
          .pipe(
            tap((data) => {
              this.startingHour = 0;
              this.allData = { dispatchData: data };
            }),
            // Iterate through all months
            map((filteredData) =>
              !filteredData || filteredData.length === 0
                ? undefined
                : Object.keys(_arrDaysAndMonths()).map((month) => {
                    const endingHour =
                      this.startingHour + _arrDaysAndMonths()[month] * 24;
                    // Initialize the bar section in the graph (der object)
                    const derOfTheMonth = {
                      name: this._titleCase.transform(month).substring(0, 3),
                      // Construct a bar piece for the stacked bar (der entry)
                      series: filteredData.map((derEntry) => ({
                        name: derEntry.der,
                        // Get the average value for that month based on starting hour and end
                        value: derEntry.values
                          .slice(this.startingHour, endingHour)
                          .reduce((acc, hourlyVal) => acc + hourlyVal, 0.0),
                      })),
                    };
                    this.startingHour = endingHour;
                    return derOfTheMonth;
                  }),
            ),
            takeUntil(this.componentDestroyed$),
          ),
      ),
      tap((data) => {
        this.dataLoaded.emit(data);
        this.startingHour = 0;
      }),
      takeUntil(this.componentDestroyed$),
    );
    this.initializeChartDataAndColors();
  }

  formatTick = (tick: string) => tick;

  getChartName(): string {
    return this.chartTitle;
  }

  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.data$;
  }

  getLegendNames(data: VisualizerData[]): string[] {
    return data.map((d) => d.name);
  }

  formatTicks(data: StackedBarMeta): StackedBarMeta {
    return {
      ...data,
      axisTicks: data.axisTicks.map((tick) => this.formatTick(tick)),
    };
  }

  mapToChartJSChart(from: VisualizerData[]): StackedBarMeta {
    const barData = this.mapToStackBarMetaData(from);
    return {
      axisTicks: from.map((f) => f.name),
      data: this.filterOutZeroValues(barData),
    };
  }

  filterOutZeroValues(data: BarDatum[]): BarDatum[] {
    // test if all elements in values are falsy, then invert to filter them out
    return data.filter(
      (datum) => !(Object.values(datum.values) || []).every((siri) => !!!siri),
    );
  }

  private mapToStackBarMetaData(data: VisualizerData[]): BarDatum[] {
    const derNameValues = {};
    data.forEach((element) => {
      element.series.forEach((value) => {
        const monthValueObj = {};
        monthValueObj[element.name] = value.value;
        derNameValues[value.name] = {
          ...derNameValues[value.name],
          ...monthValueObj,
        };
      });
    });

    const stackBarData = [];
    Object.entries(derNameValues).forEach(([key, value]) =>
      stackBarData.push({
        name: key,
        values: value,
      }),
    );

    return stackBarData;
  }

  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Months', yAxisName: 'kWh' };
  }
}
