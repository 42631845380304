import { Case, NodeType } from 'prosumer-app/+case/models';
import { CaseFacadeService } from 'prosumer-app/+case/state';
import { Scenario } from 'prosumer-app/+scenario/models';
import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { ScenarioListExt } from 'prosumer-app/+scenario/utils';
import { EXECUTION_STATUS, SCENARIO_TYPE } from 'prosumer-app/app.references';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseDialogComponent } from 'prosumer-app/libs/eyes-shared';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { isResultsDownloadable, ViewLogsComponent } from 'prosumer-app/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DuplicateDialogOut } from '../scenario-details';
import { ScenarioDuplicateDialogComponent } from '../scenario-duplicate-dialog';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-actions',
  templateUrl: './scenario-actions.component.html',
  styleUrls: ['./scenario-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioActionsComponent {
  private scenarioSubj = new BehaviorSubject<Scenario>(undefined);
  private selectedCaseNodeType: NodeType | undefined;

  @Input() canDelete = false;
  @Input() hideDownloadFiles = true;
  @Input() set scenario(scenario: Scenario) {
    this.scenarioSubj.next(scenario);
  }
  @Output() delete = new EventEmitter();

  readonly simulating$: Observable<boolean> = this.selectSimulating();
  readonly scenario$ = this.scenarioSubj.asObservable();

  constructor(
    private keeper: ActiveKeeperService,
    private uglyDialog: DialogService,
    private dialog: MatDialog,
    private scenarios: ScenarioFacadeService,
    private cases: CaseFacadeService,
  ) {
    this.subscribeToSelectedCaseForNodeTypeSetting();
  }

  disabledDownloadInput(): Observable<boolean> {
    return this.scenarioSubj.pipe(
      map(
        (scenario) =>
          !scenario ||
          scenario.copyStatus !== undefined ||
          scenario.downloading !== undefined,
      ),
    );
  }

  disabledDownloadOutput(): Observable<boolean> {
    return this.scenarioSubj.pipe(
      map((scenario) => !isResultsDownloadable(scenario)),
    );
  }

  disabledDuplicate(): Observable<boolean> {
    return this.scenarioSubj.pipe(
      filter(Boolean),
      map(
        (scenario: Scenario) =>
          scenario.scenarioType === SCENARIO_TYPE.UPLOADED,
      ),
    );
  }

  disabledDelete(): Observable<boolean> {
    return this.keeper
      .selectProjectEditPermission()
      .pipe(map((value) => !value));
  }

  disabledDownloadFullLogs(): Observable<boolean> {
    return this.scenario$.pipe(
      map((scenario) => !ScenarioListExt.isLogClickable(scenario)),
    );
  }

  onDelete(): void {
    if (this.canDelete) {
      this.uglyDialog
        .openDialog(
          BaseDialogComponent,
          this.createDeleteDialogData(this.getScenario()),
        )
        .pipe(
          take(1),
          untilDestroyed(this),
          filter((confirmed: boolean) => confirmed),
        )
        .subscribe(() => this.scenarios.delete(this.getScenario()));
    } else {
      this.delete.emit();
    }
  }

  onDuplicate(): void {
    this.uglyDialog
      .openDialog(
        ScenarioDuplicateDialogComponent,
        this.createDuplicateDialogData(this.getScenario()),
      )
      .pipe(take(1))
      .subscribe((data: DuplicateDialogOut) =>
        data
          ? this.scenarios.copy(
              this.getScenario(),
              data.duplicateName,
              data.projectId,
              data.caseId,
            )
          : {},
      );
  }

  onViewRealTime(): void {
    this.dialog.open(
      ViewLogsComponent,
      this.createViewLogsConfig(this.getScenario()),
    );
  }

  onDownloadInput(): void {
    const { scenarioType } = this.getScenario();
    if (scenarioType === SCENARIO_TYPE.UPLOADED) {
      this.scenarios.download(this.getScenario(), 'getSigned', 'input');
    } else {
      // trigger wss hook to download file
      this.scenarios.getDraftInput(this.getScenario(), 'input');
    }
  }

  onDownloadOutput(): void {
    this.scenarios.download(this.getScenario(), 'getOutputSigned', 'output');
  }

  onDownloadLogsFile(): void {
    this.scenarios.download(this.getScenario(), 'getLogSigned', 'log');
  }

  private createDeleteDialogData(scenario: Scenario): unknown {
    return {
      title: 'Delete Scenario',
      message: `Do you wish to delete "${scenario.name}" scenario?`,
      confirm: 'Delete',
      close: 'Cancel',
    };
  }

  private getScenario(): Scenario {
    return this.scenarioSubj.value;
  }

  private selectSimulating(): Observable<boolean> {
    return this.selectScenario().pipe(
      map(
        (scenario) =>
          !!scenario.run && scenario.run.status === EXECUTION_STATUS.RUNNING,
      ),
    );
  }

  private selectScenario(): Observable<Scenario> {
    return this.scenarioSubj
      .asObservable()
      .pipe(filter((scenario) => !!scenario));
  }

  private createViewLogsConfig(scenario: Scenario): MatDialogConfig {
    return {
      width: '800px',
      minHeight: '300px',
      data: {
        projectId: scenario.projectId,
        caseId: scenario.caseId,
        scenarioId: scenario.id,
      },
    };
  }

  private createDuplicateDialogData(scenario: Scenario): unknown {
    return {
      scenario,
      duplicateName: scenario.name,
      entityArchetype: 'scenario',
      width: 500,
      disableClose: true,
      selectedCaseNodeType: this.selectedCaseNodeType,
    };
  }

  private subscribeToSelectedCaseForNodeTypeSetting(): void {
    this.selectTruthySelectedCase()
      .pipe(take(1))
      .subscribe((casein) => (this.selectedCaseNodeType = casein.nodeType));
  }

  private selectTruthySelectedCase(): Observable<Case> {
    return this.cases.selectedData$.pipe(filter((casein) => !!casein));
  }
}
