import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TopologyCompletion } from 'prosumer-app/+scenario/services/completion-strategies/topology-completion.strategy';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import {
  FormFieldErrorMessageMap,
  StepFormComponent,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NodeQuery } from 'prosumer-app/stores/node';
import { Line, Node } from '../../models';

const SPINNER_COLOR = 'accent';
const SPINNER_DIAMETER = 21;
@UntilDestroy()
@Component({
  selector: 'prosumer-topology-form',
  templateUrl: './topology-form.component.html',
  styleUrls: ['./topology-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: 'nodes', useClass: ManagedDataService },
    { provide: 'lines', useClass: ManagedDataService },
  ],
  standalone: false,
})
export class TopologyFormComponent
  extends StepFormComponent
  implements AfterViewInit
{
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;
  @Input() loadFormData: ScenarioWizardStep;

  nodesList$ = this.nodeQuery.selectActiveNodes().pipe(untilDestroyed(this));
  nodesLoading$ = this.nodeQuery.selectLoading().pipe(untilDestroyed(this));

  nodeErrorsMessages: FormFieldErrorMessageMap = {
    alreadyExists: this.translate.instant('Scenario.messages.nodeExist'),
    invalidCharacter: this.translate.instant(
      'Scenario.messages.nodeInvalidCharacter',
    ),
    invalidString: this.translate.instant('Scenario.messages.invalidString'),
  };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private completion: ScenarioCompletionService,
    private nodeQuery: NodeQuery,
  ) {
    super(ngControl, changeDetector, formBuilder);

    this.subscribeToValueChangesForCompletionTracking();
  }

  defineForm() {
    return {
      nodes: [],
      lines: [],
    };
  }

  ngAfterViewInit() {
    this.nodesList$.subscribe((list: any) => {
      this.form.controls.nodes.patchValue(list);
      this.form.markAsDirty();
      this.changeDetector.markForCheck();
    });

    // Added here the onInit implem to avoid the dirtying issue
    this.initFormListener();
    this.enableValidator(false);
  }

  getLinesWithoutReferenceNode(lines: Array<Line>, node: Node) {
    return lines.filter(
      (line) =>
        line.originNodeId !== node.value &&
        line.destinationNodeId !== node.value,
    );
  }

  private subscribeToValueChangesForCompletionTracking(): void {
    const strategy = new TopologyCompletion();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((form) => {
      this.completion.setForStep(
        ScenarioWizardStep.topology,
        strategy.determineStatus(form),
      );
    });
  }

  get spinnerColor(): string {
    return SPINNER_COLOR;
  }

  get spinnerDiameter(): number {
    return SPINNER_DIAMETER;
  }
}
