import { Scenario } from 'prosumer-app/+scenario/models';
import {
  EXECUTION_STATUS_FINISHED,
  EXECUTION_STATUS_SUCCESS,
  SCENARIO_TYPE,
} from 'prosumer-app/app.references';
import { isResultsDownloadable } from 'prosumer-app/shared';

export class ScenarioListExt {
  static isLogClickable(scenario: Scenario): boolean {
    return [
      !this.isProcessing(scenario),
      !this.hasScenarioError(scenario),
      this.hasScenarioLogFile(scenario),
    ].every(Boolean);
  }

  static isScenarioClickable(scenario: Scenario): boolean {
    const isScenarioClickable = [
      !this.isScenarioUpload(scenario),
      this.hasSuccessStatus(scenario),
      isResultsDownloadable(scenario),
    ].some(Boolean);
    return [
      !this.isProcessing(scenario),
      !this.hasScenarioError(scenario),
      isScenarioClickable,
    ].every(Boolean);
  }

  static hasScenarioError(scenario: Scenario): boolean {
    return !!scenario && !!scenario.error;
  }

  private static isProcessing(scenario: Scenario): boolean {
    if (!scenario) return false;
    const { copying, deleting, copyStatus } = scenario;
    return [copying, deleting, !!copyStatus].some(Boolean);
  }

  private static isScenarioUpload(scenario: Scenario): boolean {
    return scenario.scenarioType === SCENARIO_TYPE.UPLOADED;
  }

  private static hasFinishedStatus(scenario: Scenario): boolean {
    return EXECUTION_STATUS_FINISHED.includes(scenario.status);
  }

  private static hasSuccessStatus(scenario: Scenario): boolean {
    return EXECUTION_STATUS_SUCCESS.includes(
      scenario.status || scenario.run.status,
    );
  }

  private static hasScenarioLogFile(scenario: Scenario): boolean {
    if (!scenario) return false;
    return (
      !!scenario.run?.logFile?.key || !!scenario.mergeResults?.logFile?.key
    );
  }
}
