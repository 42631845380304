import moment from 'moment';
import {
  DAY_FORMAT,
  HOUR_FORMAT,
  MONTH_FORMAT,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_HOUR_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
  YEAR_FORMAT,
} from 'prosumer-app/app.references';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import { contains, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { PipeUtils } from 'prosumer-core/utils';
import { LineData } from 'prosumer-shared/modules/chartjs/line-chartjs/line-chartjs.model';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';
import {
  ResultNameValue,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer';

import { updateLegendFilter } from '../../shared/helper';
import { StorageDispatchAdapter } from '../adapters';
import { DispatchService } from '../dispatch.service';

const STORAGE_CHARGE = 'Storage Charge';
const STORAGE_DISCH = 'Storage Discharge';
const DEBOUNDE_TIME = 1000;

@UntilDestroy()
@Component({
  selector: 'prosumer-storage-raw-dispatch',
  templateUrl: './storage-raw-dispatch.component.html',
  styleUrls: ['./storage-raw-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StorageRawDispatchComponent
  extends StorageDispatchAdapter
  implements OnInit
{
  @Output() dataLoaded = new EventEmitter<Array<any>>();
  @Input() scenarioName: string;
  @Input() set storageOptions(storageOptions: Array<FormFieldOption<string>>) {
    this._storageOptions.next(storageOptions);
  }
  readonly _storageOptions = new BehaviorSubject<FormFieldOption<string>[]>([]);
  readonly storageOptions$ = this._storageOptions.asObservable().pipe(
    debounceTime(DEBOUNDE_TIME),
    PipeUtils.filterOutUndefined,
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    tap((d) => d.length && this.storageControl.setValue(d[0].value)),
    untilDestroyed(this),
  );

  storageSoc$: Observable<any>;
  storageCharge$: Observable<any>;
  lineData$: Observable<LineData>;
  wrappedChartData$: Observable<StackedBarMeta>;
  wrappedColor$: Observable<PerceptionMap>;

  view: number[];
  legendFilter$ = new BehaviorSubject<Array<string>>([]);
  storageControl = new FormControl<string>(undefined);
  readonly _becauseValueChangeWontWork = new BehaviorSubject<string>(undefined);

  constructor(
    private _logger: LoggerService,
    private _dispatchService: DispatchService,
    private _resultStore: ResultStore,
    public perception: ResultsPerceptionService,
  ) {
    super(perception);
    this.loadStorages();
    this.initializeChartDataAndColors();
  }

  ngOnInit() {
    this.registerObservables();
    this.subToCtrlChange();
  }

  private registerObservables() {
    this.lineData$ = this.getLineDataStream();
    this.wrappedChartData$ = this.chartData$.pipe(
      debounceTime(DEBOUNDE_TIME),
      PipeUtils.filterOutUndefined,
      untilDestroyed(this),
    );
    this.wrappedColor$ = this.colors$.pipe(
      debounceTime(DEBOUNDE_TIME),
      PipeUtils.filterOutUndefined,
      untilDestroyed(this),
    );
  }

  private subToCtrlChange() {
    this.storageControl.valueChanges.subscribe((d) => {
      this._becauseValueChangeWontWork.next(d);
    });
  }

  /**
   * Based on the storage selected, the chart is loaded with the data from result store
   */
  loadStorages() {
    this.storageSoc$ = this.weirdRebundantCombine().pipe(
      switchMap(
        ([year, node, energyVector, minDay, maxDay, legendFilter, ctrlVal]) =>
          this._resultStore
            .getStorageSoc$(year, node, energyVector, ctrlVal)
            .pipe(
              map((filteredData) => {
                const minDate =
                  year && minDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).dayOfYear(minDay)
                    : undefined;
                const maxDate =
                  year && maxDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 23,
                        m: 59,
                        s: 59,
                        ms: 999,
                      }).dayOfYear(maxDay)
                    : undefined;
                return !filteredData || filteredData.values === 0
                  ? undefined
                  : [
                      {
                        name: 'Storage SoC',
                        series: filteredData.values
                          .map((value, index) => ({
                            name: moment({
                              y: +year,
                              M: 0,
                              d: 1,
                              h: 0,
                              m: 0,
                              s: 0,
                              ms: 0,
                            })
                              .hour(index)
                              .toISOString(),
                            moment: moment({
                              y: +year,
                              M: 0,
                              d: 1,
                              h: 0,
                              m: 0,
                              s: 0,
                              ms: 0,
                            }).hour(index),
                            value,
                          }))
                          .filter((value) =>
                            minDate && maxDate
                              ? minDate.isSameOrBefore(value.moment) &&
                                maxDate.isSameOrAfter(value.moment)
                              : true,
                          ),
                      },
                    ];
              }),
            ),
      ),
      PipeUtils.filterOutUndefined,
      tap((data) => this.dataLoaded.emit(data)),
      this.tapToRegisterLineLegendName.bind(this),
      untilDestroyed(this),
    );

    // for battery cahrge, inverse the values with (*-1) so that the bar charges would match storage soc
    this.storageCharge$ = this.weirdRebundantCombine().pipe(
      switchMap(
        ([year, node, energyVector, minDay, maxDay, legendFilter, ctrlVal]) =>
          this._resultStore
            .getStorageCharge$(year, node, energyVector, ctrlVal)
            .pipe(
              map((filteredData) => {
                const minDate =
                  year && minDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 0,
                        m: 0,
                        s: 0,
                        ms: 0,
                      }).dayOfYear(minDay)
                    : undefined;
                const maxDate =
                  year && maxDay
                    ? moment({
                        y: +year,
                        M: 0,
                        d: 1,
                        h: 23,
                        m: 59,
                        s: 59,
                        ms: 999,
                      }).dayOfYear(maxDay)
                    : undefined;
                return !filteredData || filteredData.values === 0
                  ? undefined
                  : filteredData.values
                      .map((value, index) => {
                        let v = value * -1;
                        if (contains(legendFilter, STORAGE_CHARGE) && v > 0) {
                          v = 0;
                        }
                        if (contains(legendFilter, STORAGE_DISCH) && v < 0) {
                          v = 0;
                        }
                        return {
                          name: moment({
                            y: +year,
                            M: 0,
                            d: 1,
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                          })
                            .hour(index)
                            .toISOString(),
                          moment: moment({
                            y: +year,
                            M: 0,
                            d: 1,
                            h: 0,
                            m: 0,
                            s: 0,
                            ms: 0,
                          }).hour(index),
                          value: v,
                        };
                      })
                      .filter((value) =>
                        minDate && maxDate
                          ? minDate.isSameOrBefore(value.moment) &&
                            maxDate.isSameOrAfter(value.moment)
                          : true,
                      );
              }),
            ),
      ),
      PipeUtils.filterOutUndefined,
      tap((data) => {
        this.dataLoaded.emit(data);
        this.updateView(data.length);
      }),
      untilDestroyed(this),
    );
  }

  /**
   * Gives predefined width and height depending on data.
   * This function helps the chart-container when data exceeds 7 days.
   *
   * @param len - storageCharge data length
   */
  updateView(len: number) {
    if (len > 168 && len < 481) {
      this.view = [5000, 350];
    }
    if (len > 480) {
      this.view = [8000, 350];
    }
  }

  /**
   * Function handling of output select from base chart
   *
   * @param selected - some data
   */
  onSelect(selected: string) {
    this._logger.debug(selected);
    updateLegendFilter(this.legendFilter$, selected);
  }

  /**
   * Returns a format string for Y-axis (Power [kWh])
   *
   * @param data - some param to be acceptable in typescript
   */
  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  /**
   * Function helper to define YDomain of custom chart
   *
   * @param min - min values of the results[]
   * @param max - max values of the results[]
   */
  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  /**
   * Function helper to define YDomain Line of custom chart
   *
   * @param min - min values of the results[]
   * @param max - max values of the results[]
   */
  yRightAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  /**
   * Storage SoC format - Y Right axis. Appending % sign.
   *
   * @param data - series data
   */
  yRightTickFormat(data) {
    return `${data}%`;
  }

  /**
   * Helper to interpret moment data in X-Axis to a more readable human info
   *
   * @param value - moment data
   */
  chartXAxisFormat(value: any) {
    return moment(value).format(
      `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`,
    );
  }

  /**
   * Helper to interpret moment data in Tootltip to a more readable human info
   *
   * @param value - moment data
   */
  formatTooltipTitle(obj: any): string {
    return moment((obj || {}).name).format(
      `${MONTH_FORMAT} ${DAY_FORMAT}, ${YEAR_FORMAT} ${HOUR_FORMAT}`,
    );
  }

  getChartName(): string {
    return `Storage Raw Dispatch (${this.storageControl.value})`;
  }
  getNgxChartsDataStream(): Observable<ResultNameValue[]> {
    return this.storageCharge$;
  }
  formatTick(tick: string): string {
    return this.chartXAxisFormat(tick);
  }

  private getLineDataStream(): Observable<LineData> {
    return this.storageSoc$.pipe(
      debounceTime(DEBOUNDE_TIME),
      PipeUtils.filterOutEmpty,
      map((data) =>
        this.mapNgxLineThingyToChartJSLineData(data as VisualizerData[]),
      ),
      untilDestroyed(this),
    );
  }

  private mapNgxLineThingyToChartJSLineData(
    thingy: VisualizerData[],
  ): LineData {
    const soleValue = thingy[0];
    return {
      name: soleValue.name,
      points: soleValue.series.map((siri) => siri.value),
      ticks: [],
    };
  }

  private tapToRegisterLineLegendName(
    $: Observable<VisualizerData[]>,
  ): Observable<VisualizerData[]> {
    return $.pipe(
      tap((data) =>
        this.perception.registerLegendNames(this.caseId, [data[0].name]),
      ),
    );
  }

  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return {
      ...data,
      xAxisName: 'Hours',
      yAxisName: 'Power [kW]',
      rightYAxisName: 'Storage SoC [%]',
    };
  }

  private weirdRebundantCombine() {
    return combineLatest([
      this._dispatchService.year$,
      this._dispatchService.node$,
      this._dispatchService.energyVector$,
      this._dispatchService.minDate$,
      this._dispatchService.maxDate$,
      this.legendFilter$,
      this._becauseValueChangeWontWork,
    ]);
  }
}
