import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';

export enum ScenarioBatchAction {
  download = 'download',
  delete = 'delete',
}

@Component({
  selector: 'prosumer-scenario-batch',
  templateUrl: './scenario-batch.component.html',
  styleUrls: ['./scenario-batch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioBatchComponent {
  readonly booleanArray = signal<boolean[]>([]);
  readonly canDownload = computed(() => this.booleanArray().every(Boolean));

  @Input() set selectedWithDownloadState(value: boolean[]) {
    this.booleanArray.set(value);
  }
  @Output() actionClick = new EventEmitter<ScenarioBatchAction>();

  onActionClick(action: string): void {
    this.actionClick.emit(action as ScenarioBatchAction);
  }
}
