import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';
import { ResultsPerceptionDialogData } from './results-perception-dialog.model';

@Component({
  selector: 'prosumer-results-perception-dialog',
  templateUrl: './results-perception-dialog.component.html',
  styleUrls: ['./results-perception-dialog.component.scss'],
  standalone: false,
})
export class ResultsPerceptionDialogComponent {
  private updatedPerception: PerceptionMap;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResultsPerceptionDialogData,
    private perceptionService: ResultsPerceptionService,
    public dialogRef: MatDialogRef<ResultsPerceptionDialogComponent>,
  ) {}

  onPerceptionChange(update: PerceptionMap): void {
    this.updatedPerception = update;
  }

  onApply(): void {
    this.perceptionService.updatePerception(
      this.data.caseObject.id,
      this.updatedPerception,
    );
    this.dialogRef.close();
  }
}
