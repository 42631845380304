import { Coerce, PipeUtils } from 'prosumer-core/utils';
import {
  ScenarioMapper,
  ScenarioMappingKey,
  ScenarioMapType,
} from 'prosumer-scenario/services/mappers';
import { ScenarioInfoService } from 'prosumer-scenario/services/scenario-info/scenario-info.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
} from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SavingStatus, UpdateStatus } from './scenario-updater.model';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[scenarioUpdater]',
  standalone: false,
})
export class ScenarioUpdaterDirective implements OnInit {
  private readonly valueCache = new BehaviorSubject<unknown>(null);
  @Input() set nextValue(value: unknown) {
    this.valueCache.next(value);
  }

  @Input() invalid = false;
  @Input() key: ScenarioMappingKey;
  @Input() view: string;
  @Input() spin = true;
  @Input() hasSkip = true;
  @Input() mapType = ScenarioMapType.default;
  @Output() statusChange = new EventEmitter<UpdateStatus>();

  constructor(
    private viewContainer: ViewContainerRef,
    private readonly info: ScenarioInfoService,
  ) {}

  ngOnInit(): void {
    this.subscribeToNewValueForScenarioUpdating();
  }

  private subscribeToNewValueForScenarioUpdating(): void {
    this.selectNewValue()
      .pipe(
        untilDestroyed(this),
        filter(() => !this.invalid),
      )
      .subscribe((value) => this.updateScenario(value));
  }

  private selectNewValue(): Observable<unknown> {
    return this.valueCache.pipe(
      PipeUtils.filterOutEmptyString,
      PipeUtils.filterOutNullUndefined,
      debounceTime(900),
      skip(this.getSkipValue()),
      distinctUntilChanged((prev, curr) => prev == curr), // eslint-disable-line
    );
  }

  private updateScenario(value: unknown) {
    this.addSpinner();
    this.emitUpdateStatus('loading');
    this.info.updateScenario(this.formatUpdateData(value)).subscribe({
      next: this.handleUpdateSuccess.bind(this),
      error: this.handleUpdateError.bind(this),
    });
  }

  private handleUpdateSuccess(): void {
    this.removeLastAttachedView();
    this.emitUpdateStatus('success');
  }
  private handleUpdateError(error: HttpErrorResponse): void {
    this.removeLastAttachedView();
    this.emitUpdateStatus('failed', this.getErrorMessage(error));
  }

  private emitUpdateStatus(status: SavingStatus, message: string = '') {
    const keys = this.key.split('.');
    this.statusChange.emit({ key: keys[keys.length - 1], status, message });
  }

  public addSpinner() {
    if (this.spin) this.attachSpinner();
  }

  private attachSpinner(): void {
    const compSpinner =
      this.viewContainer.createComponent<MatProgressSpinner>(
        MatProgressSpinner,
      );
    compSpinner.instance.diameter = 24;
    compSpinner.instance.mode = 'indeterminate';
    compSpinner.instance.color = 'accent';
    compSpinner.instance._elementRef.nativeElement.classList.add(
      'spin-on-store-request',
    );
    compSpinner.instance._elementRef.nativeElement.setAttribute(
      'data-testid',
      'spinner',
    );
  }

  public removeLastAttachedView() {
    this.viewContainer.detach();
  }

  private formatUpdateData(value: unknown): unknown {
    const data = ScenarioMapper.getMapperFn(this.key)(
      value,
      this.mapType,
    ) as Record<string, string>;
    return { ...data, view: this.view };
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    return Coerce.toObject(error.error).error ?? error.message;
  }

  private getSkipValue(): number {
    return +this.hasSkip;
  }
}
