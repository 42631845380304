import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Scenario } from 'prosumer-app/+scenario/models';
import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { ScenarioListExt } from 'prosumer-app/+scenario/utils';
import { PROSUMER_USER_ROLES } from 'prosumer-app/app.references';
import { UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MessagesDialogComponent, ScenarioSource } from '../messages-dialog';
import { ClientUserPrefs } from '../scenario-details';

export enum LogsMode {
  icon = 'icon',
  text = 'text',
}

@Component({
  selector: 'prosumer-scenario-logs',
  templateUrl: './scenario-logs.component.html',
  styleUrls: ['./scenario-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioLogsComponent {
  private readonly scenarioCache = new BehaviorSubject<Scenario>(undefined);
  @Input() set scenario(scenario: Scenario) {
    this.scenarioCache.next(scenario);
  }
  readonly scenario$ = this.selectTruthyScenario();
  readonly disabled$ = this.selectLogsDisabled();
  private isClientExpert = false;

  @Input() mode: LogsMode = LogsMode.icon;

  constructor(
    private readonly dialog: MatDialog,
    private readonly users: UserFacadeService,
    private readonly scenarios: ScenarioFacadeService,
  ) {
    this.subscribeToClientUserPrefsForIsClientExpertSetting();
  }

  onClick(): void {
    this.openMessagesDialog()
      .afterClosed()
      .pipe(take(1))
      .subscribe((download) => this.conditionallyDownload(download));
  }

  private selectLogsDisabled(): Observable<boolean> {
    return this.scenario$.pipe(
      map((scenario) => !ScenarioListExt.isLogClickable(scenario)),
    );
  }

  private conditionallyDownload(should: boolean): void {
    if (should) {
      this.performDownloadOfLogs(this.scenarioCache.value);
    }
  }

  private performDownloadOfLogs(forThis: Scenario): void {
    this.scenarios.download(forThis, 'getLogSigned', 'log');
  }

  private openMessagesDialog(): MatDialogRef<unknown> {
    return this.dialog.open(
      MessagesDialogComponent,
      this.buildMessagesDialogConfig(this.scenarioCache.value),
    );
  }

  private subscribeToClientUserPrefsForIsClientExpertSetting(): void {
    this.selectClientUserPrefs()
      .pipe(
        filter((prefs) => !!prefs),
        take(1),
      )
      .subscribe(
        (prefs) =>
          (this.isClientExpert = this.isClientUserPrefsForExpert(prefs)),
      );
  }

  private selectClientUserPrefs(): Observable<ClientUserPrefs> {
    return this.users.clientUserPrefs$.pipe(filter((prefs) => !!prefs));
  }

  private isClientUserPrefsForExpert(prefs: ClientUserPrefs): boolean {
    return prefs.prosumerRole === PROSUMER_USER_ROLES.expert;
  }

  private buildMessagesDialogConfig(data: Scenario): MatDialogConfig {
    return { data: this.buildRedundantData(data), width: '800px' };
  }

  private buildRedundantData(data: Scenario): MessagesDialogData {
    return {
      data: {
        ...data,
        source: 'Simulation',
        isClientExpert: this.isClientExpert,
      },
    };
  }

  private selectTruthyScenario(): Observable<Scenario> {
    return this.scenarioCache.pipe(filter((scenario) => !!scenario));
  }
}

type MessagesDialogData = {
  data: Scenario & { source: ScenarioSource; isClientExpert: boolean };
};
