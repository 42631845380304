import { Component, Input, OnInit } from '@angular/core';
import { PlotlyData } from './sankey-chart.model';
import {
  SankeyResult,
  SankeyResultEnhanced,
} from '@prosumer/results/models/flow-results.model';
import { BehaviorSubject } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import { combineLatest } from 'rxjs';
// import { FlowResultsEditorBasicHelperService } from '@prosumer/results/services/flow-results-editor-basic-helper';

@Component({
  selector: 'prosumer-sankey-chart',
  templateUrl: './sankey-chart.component.html',
  styleUrls: ['./sankey-chart.component.scss'],
  standalone: false,
})
export class SankeyChartComponent implements OnInit {
  @Input() set sankeyData(data: SankeyResult) {
    if (!!!data) {
      return;
    }
    this.data$.next(data);
  }

  @Input() set colors(data: PerceptionMap) {
    if (!!!data) {
      return;
    }
    this.colors$.next(data);
  }

  data$ = new BehaviorSubject<SankeyResult | SankeyResultEnhanced>(undefined);
  colors$ = new BehaviorSubject<PerceptionMap>(undefined);
  plotlyData: PlotlyData;
  plotlyRevision = 0;

  constructor() {} // private readonly basicEditorHelper: FlowResultsEditorBasicHelperService,

  ngOnInit(): void {
    this.subcribeToChange();
  }

  private subcribeToChange() {
    combineLatest([this.data$, this.colors$])
      .pipe(filter(([data, color]) => !!data || !!color))
      .subscribe(([data, color]) => {
        this.plotlyData = this.mapDataToPlotly(data, color);
        this.plotlyRevision++;
      });
    // this.basicEditorHelper.sankeyData$.pipe(skip(1)).subscribe((d) => {
    //   this.data$.next(d);
    // });
  }

  private mapDataToPlotly(
    data: SankeyResult | SankeyResultEnhanced,
    color: PerceptionMap,
  ): PlotlyData {
    if (!!!data || !!!color) {
      return undefined;
    }

    const uniqueLabels = data.nodes.map((node) => node.name);
    const colorMap = data.nodes.map((node) => node.color || color[node.name]);

    return {
      data: [
        {
          type: 'sankey',
          orientation: 'h',
          valueformat: '.2f',
          valuesuffix: ' unit',
          node: {
            pad: 15,
            thickness: 10,
            line: { color: 'black', width: 0.5 },
            label: uniqueLabels,
            color: colorMap,
          },
          link: {
            source: data.links.map((d) => uniqueLabels.indexOf(d.source)),
            target: data.links.map((d) => uniqueLabels.indexOf(d.target)),
            value: data.links.map((d) => d.value),
            color: data.links.map(
              (d) =>
                (d.color || colorMap[uniqueLabels.indexOf(d.source)]) + '66',
            ),
          },
        },
      ],
      layout: {
        height: 800,
      },
    };
  }
}
