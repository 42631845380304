import { ProsumerView } from 'prosumer-app/+scenario/models';
import {
  ScenarioMappingKey,
  ScenarioMapType,
} from 'prosumer-app/+scenario/services';
import { fadeInAnimation } from 'prosumer-app/libs/eyes-shared';
import { GeneralValidators } from 'prosumer-app/shared';
import { UpdateStatus } from 'prosumer-app/shared/directives/scenario-updater';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  OptimizationOptions,
  ScenarioGenericQuery,
} from 'prosumer-app/stores/scenario-generic';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'prosumer-options-form',
  templateUrl: './options-form.component.html',
  styleUrls: ['./options-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OptionsFormComponent implements OnInit {
  readonly updateView: ProsumerView = ProsumerView.optimizationCockpit;
  readonly mapTypeString = ScenarioMapType.stringValue;
  readonly mapTypeBit = ScenarioMapType.bitValue;

  readonly mipGapKey: ScenarioMappingKey = 'mipGap';
  readonly limitRunHourKey: ScenarioMappingKey = 'limitRunHour';
  readonly preventSimultaneousChargeKey: ScenarioMappingKey =
    'preventSimultaneousCharge';
  readonly lcoxKey: ScenarioMappingKey = 'lcox';
  readonly preventCurtailmentKey: ScenarioMappingKey = 'preventCurtailment';

  readonly optionKeyLoading = new BehaviorSubject<Record<string, boolean>>({
    [this.mipGapKey]: false,
    [this.limitRunHourKey]: false,
    [this.preventSimultaneousChargeKey]: false,
    [this.lcoxKey]: false,
    [this.preventCurtailmentKey]: false,
  });

  @Input() isViewOnly: boolean;
  @Input() scenarioIdentity: unknown = {};

  readonly form = new UntypedFormGroup({
    [this.mipGapKey]: new UntypedFormControl(undefined, [
      Validators.required,
      GeneralValidators.nonNegativeValue(),
    ]),
    [this.limitRunHourKey]: new UntypedFormControl(undefined, [
      Validators.required,
      GeneralValidators.nonNegativeValue(),
    ]),
    [this.preventSimultaneousChargeKey]: new UntypedFormControl(
      false,
      Validators.required,
    ),
    [this.lcoxKey]: new UntypedFormControl(false, Validators.required),
    [this.preventCurtailmentKey]: new UntypedFormControl(
      false,
      Validators.required,
    ),
  });

  readonly loadingMap$ = this.optionKeyLoading.asObservable();
  readonly mipGap$ = this.selectMipGapValueChanges().pipe(
    PipeUtils.filterOutUndefined,
    tap(() => this.form.controls[this.mipGapKey].markAsTouched()),
  );
  readonly limitRunHour$ = this.selectLimitRunHourValueChanges().pipe(
    PipeUtils.filterOutUndefined,
    tap(() => this.form.controls[this.limitRunHourKey].markAsTouched()),
  );
  readonly preventSimultaneousCharge$ =
    this.selectPreventSimultaneousChargeValueChanges();
  readonly lcox$ = this.selectLCOxValueChanges();
  readonly preventCurtailment$ = this.selectPreventCurtailmentKeyValueChanges();

  constructor(
    private readonly scenario: ScenarioGenericQuery,
    private notifService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.patchInitFormValues();
  }

  private patchInitFormValues(): void {
    this.take1TruthyOptimizationOptions().subscribe((options) => {
      this.form.patchValue(options, { emitEvent: false });
    });
  }

  private take1TruthyOptimizationOptions(): Observable<OptimizationOptions> {
    return this.scenario.selectActiveOptions().pipe();
  }

  private selectMipGapValueChanges(): Observable<number> {
    return this.selectControlValueChanges(this.mipGapKey) as Observable<number>;
  }

  private selectLimitRunHourValueChanges(): Observable<number> {
    return this.selectControlValueChanges(
      this.limitRunHourKey,
    ) as Observable<number>;
  }

  private selectPreventSimultaneousChargeValueChanges(): Observable<boolean> {
    return this.selectControlValueChanges(
      this.preventSimultaneousChargeKey,
    ) as Observable<boolean>;
  }

  private selectLCOxValueChanges(): Observable<boolean> {
    return this.selectControlValueChanges(this.lcoxKey) as Observable<boolean>;
  }

  private selectPreventCurtailmentKeyValueChanges(): Observable<boolean> {
    return this.selectControlValueChanges(
      this.preventCurtailmentKey,
    ) as Observable<boolean>;
  }

  private selectControlValueChanges(controlName: string): Observable<unknown> {
    return this.form.get(controlName).valueChanges;
  }

  setOptionLoading(change: UpdateStatus): void {
    this.checkForResError(change);
    this.optionKeyLoading.next({
      ...this.optionKeyLoading.getValue(),
      [change.key]: this.isLoading(change),
    });
  }

  private checkForResError(change: UpdateStatus): void {
    if (change.status === 'failed') {
      this.notifService.showError(`ERROR: ${change.message}`);
    }
  }

  private isLoading(change: UpdateStatus): boolean {
    return change.status === 'loading';
  }
}
