import {
  generateShadedScheme,
  RESULTS_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'prosumer-sizing-output',
  templateUrl: './sizing-output.component.html',
  styleUrls: ['./sizing-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: false,
})
export class SizingOutputComponent extends BaseComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CHART_ID = 'sizing';

  constructor(
    private _resultStore: ResultStore,
    public decimalPipe: CurrencyPipe,
  ) {
    super();
  }

  highContrastScheme = RESULTS_DEFAULT_SCHEME;
  @Input() view = [550, 350];
  data: any;

  ngOnInit() {
    const cache$ = this._resultStore.chartData$(this.CHART_ID);
    combineLatest([
      this._resultStore.sizingData$.pipe(take(1)),
      cache$.pipe(take(1)),
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([fresh, cached]) => {
        this.data = cached || fresh;
        if (!cached) {
          this._resultStore.updateChartDataMapping(this.CHART_ID, fresh);
        }
        this.highContrastScheme = generateShadedScheme(
          (this.data || []).length,
        );
      });
  }

  formatValue(num: any) {
    if (num) {
      return this.decimalPipe.transform(num, '', '', '1.0-0');
    }
    return num;
  }
}
