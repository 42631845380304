import * as i0 from '@angular/core';
import { Directive, Input, EventEmitter, ViewContainerRef, Component, ViewChild, ContentChild, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["outlet"];
class NgxRerenderDirective {
  templateRef;
  viewContainer;
  constructor(templateRef, viewContainer) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
  }
  set mcRerender(_val) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
  static ɵfac = function NgxRerenderDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxRerenderDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxRerenderDirective,
    selectors: [["", "mcRerender", ""]],
    inputs: {
      mcRerender: "mcRerender"
    },
    standalone: false
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxRerenderDirective, [{
    type: Directive,
    args: [{
      selector: '[mcRerender]'
    }]
  }], () => [{
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], {
    mcRerender: [{
      type: Input
    }]
  });
})();
class NgxRerenderContentDirective {
  templateRef;
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
  static ɵfac = function NgxRerenderContentDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxRerenderContentDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NgxRerenderContentDirective,
    selectors: [["", "mcRerenderContent", ""]],
    standalone: false
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxRerenderContentDirective, [{
    type: Directive,
    args: [{
      selector: '[mcRerenderContent]'
    }]
  }], () => [{
    type: i0.TemplateRef
  }], null);
})();
class NgxRerenderComponent {
  outletRef;
  content;
  trigger;
  triggerChange = new EventEmitter();
  ngOnChanges(changes) {
    if (!changes.trigger || changes.trigger.currentValue === undefined) {
      return;
    }
    const triggerValue = changes.trigger.currentValue;
    if (!this.outletRef) {
      /**
       * Even if the initial outlet is not yet ready on the first onChange
       * we still ne to reset the boolean binding,
       * otherwise the next change to true will not properly be tracked
       */
      if (typeof triggerValue === 'boolean') {
        this.setBooleanBindingToFalse();
      }
      return;
    }
    if (typeof triggerValue !== 'boolean') {
      this.rerender();
      return;
    }
    if (triggerValue) {
      this.rerender();
      this.setBooleanBindingToFalse();
    }
  }
  rerender() {
    if (!this.outletRef) {
      return;
    }
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.content.templateRef);
  }
  setBooleanBindingToFalse() {
    setTimeout(() => this.triggerChange.emit(false));
  }
  static ɵfac = function NgxRerenderComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxRerenderComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: NgxRerenderComponent,
    selectors: [["mc-rerender"]],
    contentQueries: function NgxRerenderComponent_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, NgxRerenderContentDirective, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      }
    },
    viewQuery: function NgxRerenderComponent_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.outletRef = _t.first);
      }
    },
    inputs: {
      trigger: "trigger"
    },
    outputs: {
      triggerChange: "triggerChange"
    },
    standalone: false,
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 2,
    vars: 1,
    consts: [["outlet", ""], [3, "ngTemplateOutlet"]],
    template: function NgxRerenderComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementContainer(0, 1, 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngTemplateOutlet", ctx.content.templateRef);
      }
    },
    dependencies: [i1.NgTemplateOutlet],
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxRerenderComponent, [{
    type: Component,
    args: [{
      selector: 'mc-rerender',
      template: `
    <ng-container [ngTemplateOutlet]="content.templateRef" #outlet></ng-container>
  `
    }]
  }], null, {
    outletRef: [{
      type: ViewChild,
      args: ['outlet', {
        read: ViewContainerRef
      }]
    }],
    content: [{
      type: ContentChild,
      args: [NgxRerenderContentDirective]
    }],
    trigger: [{
      type: Input
    }],
    triggerChange: [{
      type: Output
    }]
  });
})();
class NgxRerenderModule {
  static ɵfac = function NgxRerenderModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NgxRerenderModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: NgxRerenderModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxRerenderModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxRerenderComponent, NgxRerenderDirective, NgxRerenderContentDirective],
      imports: [CommonModule],
      exports: [NgxRerenderComponent, NgxRerenderDirective, NgxRerenderContentDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-rerender
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxRerenderComponent, NgxRerenderContentDirective, NgxRerenderDirective, NgxRerenderModule };
