import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  DEFAULT_LOAD_CHART_DEBOUNCE_TIME,
  STORAGE_COLORS,
  STORAGE_TYPE,
  generateShadedScheme,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  FormFieldOption,
  fadeInAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { CompareDispatchResultItem, Result } from 'prosumer-scenario/models';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { CompareDispatchData } from './compare-dispatch.model';

export const DEFAULT_RAW_START = 1;
export const DEFAULT_RAW_END = 7;
export const DEFAULT_DAILY_END = 31;

@Component({
  selector: 'prosumer-compare-dispatch',
  templateUrl: './compare-dispatch.component.html',
  styleUrls: ['./compare-dispatch.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompareDispatchComponent
  extends BaseComponent
  implements AfterViewInit, OnInit
{
  storageType = STORAGE_TYPE;

  @Input() typeOptions: Array<FormFieldOption<string>>;
  @Input() nodeOptions: Array<string>;
  @Input() energyVectorOptions: Array<string>;
  @Input() resultTypeOptions: Array<string>;
  @Input() yearOptions: Array<string>;
  @Input() caseId: string;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  typeToggleControl = this.formBuilder.control('raw');
  resultTypeToggleControl = this.formBuilder.control('production');
  yearSelectControl = this.formBuilder.control(null);
  nodeSelectControl = this.formBuilder.control(null);
  energyVectorSelectControl = this.formBuilder.control(null);

  sliderStart = new BehaviorSubject<number>(DEFAULT_RAW_START);
  sliderEnd = new BehaviorSubject<number>(DEFAULT_RAW_END);

  _selectedResults = new BehaviorSubject<Array<Result>>([]);
  @Input() set selectedResults(value: Array<Result>) {
    this._selectedResults.next(value);
  }

  _dispatchResults = new BehaviorSubject<Array<CompareDispatchResultItem>>([]);
  @Input() set dispatchResults(value: Array<CompareDispatchResultItem>) {
    this._dispatchResults.next(value);
  }

  selectedResults$ = this._selectedResults
    .asObservable()
    .pipe(this.takeUntilShare());

  dispatchResults$ = this._dispatchResults
    .asObservable()
    .pipe(this.takeUntilShare());

  sliderStart$ = this.sliderStart.asObservable().pipe(this.takeUntilShare());

  sliderEnd$ = this.sliderEnd.asObservable().pipe(this.takeUntilShare());

  customColors$ = this.dispatchResults$.pipe(
    debounceTime(DEFAULT_LOAD_CHART_DEBOUNCE_TIME),
    take(1),
    map((data) => {
      const equipments = Array.from(
        new Set(data.map((item) => item.equipment)),
      );
      const { domain } = generateShadedScheme(equipments.length);
      return equipments.map((e, i) => ({ name: e, value: domain[i] }));
    }),
    this.takeUntilShare(),
  );

  storageColors$ = of(STORAGE_COLORS);

  typeValue$ = this.typeToggleControl.valueChanges.pipe(
    startWith(this.typeToggleControl.value),
    tap((value) => {
      switch (value) {
        case 'raw':
          this.onSliderStartChange(DEFAULT_RAW_START);
          this.onSliderEndChange(DEFAULT_RAW_END);
          break;
        case 'daily':
          this.onSliderStartChange(DEFAULT_RAW_START);
          this.onSliderEndChange(DEFAULT_DAILY_END);
          break;
        case 'weekly':
          this.onSliderStartChange(DEFAULT_RAW_START);
          this.onSliderEndChange(DEFAULT_DAILY_END);
          break;
        default:
          this.onSliderStartChange(DEFAULT_RAW_START);
          this.onSliderEndChange(DEFAULT_RAW_END);
      }
    }),
    this.takeUntilShare(),
  );

  resultTypeValue$ = this.resultTypeToggleControl.valueChanges.pipe(
    startWith(this.resultTypeToggleControl.value),
    this.takeUntilShare(),
  );

  yearValue$ = this.yearSelectControl.valueChanges.pipe(
    startWith(this.yearSelectControl.value),
    this.takeUntilShare(),
  );

  nodeValue$ = this.nodeSelectControl.valueChanges.pipe(
    startWith(this.nodeSelectControl.value),
    this.takeUntilShare(),
  );

  energyVectorValue$ = this.energyVectorSelectControl.valueChanges.pipe(
    startWith(this.energyVectorSelectControl.value),
    this.takeUntilShare(),
  );

  isErrorShown$ = of(false).pipe(
    // this.selectedScenarios$.pipe(
    // map(scenarios => scenarios.length < DEFAULT_SUMMARY_MAX_SELECTION),
    this.takeUntilShare(),
  );

  flexWidthBasedOnDataLength$: Observable<number>;

  /**
   * An observable that maps and filters dispatch results and values based on the latest values of the selected scenarios,
   * result type, year, ndoe, energy vector filters, as well as the start and end of the sliders
   */
  filteredData$ = combineLatest([
    this.selectedResults$,
    this.dispatchResults$,
    this.resultTypeValue$,
    this.yearValue$,
    this.nodeValue$,
    this.energyVectorValue$,
    this.sliderStart$,
    this.sliderEnd$,
    this.typeValue$,
  ]).pipe(
    debounceTime(DEFAULT_LOAD_CHART_DEBOUNCE_TIME),
    distinctUntilChanged(),
    map(
      ([
        results,
        dispatchResults,
        resultType,
        year,
        node,
        energyVector,
        start,
        end,
        type,
      ]) =>
        results.map((result) => ({
          ...result,
          start,
          end,
          dataList: (dispatchResults as Array<CompareDispatchResultItem>)
            .filter(
              (dispatchResult) =>
                result.id === dispatchResult?.id &&
                result.name === dispatchResult?.name &&
                (resultType === dispatchResult.resultType ||
                  (resultType === this.storageType &&
                    dispatchResult.resultType === 'soc' &&
                    type === 'raw')) &&
                year === dispatchResult.year &&
                node === dispatchResult.node &&
                energyVector === dispatchResult.energyVector,
            )
            .map((filteredResult) => ({
              ...filteredResult,
              values:
                type === 'monthly'
                  ? filteredResult.values
                  : filteredResult.values.slice((start - 1) * 24, end * 24),
            })),
        })),
    ),
    map((data: CompareDispatchData[]) =>
      data.filter((d) => !!d.dataList.length),
    ),
    this.takeUntilShare(),
  );

  ngAfterViewInit() {
    this.yearSelectControl.setValue(this.yearOptions[0]);
    this.nodeSelectControl.setValue(this.nodeOptions[0]);
    this.energyVectorSelectControl.setValue(this.energyVectorOptions[0]);
  }

  onSliderStartChange(value: number) {
    this.sliderStart.next(value);
  }

  onSliderEndChange(value: number) {
    this.sliderEnd.next(value);
  }

  ngOnInit(): void {
    this.flexWidthBasedOnDataLength$ =
      this.getFlexWidthBasedOnDataLengthStream();
  }

  private getFlexWidthBasedOnDataLengthStream(): Observable<number> {
    return this.filteredData$.pipe(map((data) => (data.length > 1 ? 45 : 100)));
  }
}
