import { ScenarioVariationQuery } from 'prosumer-app/stores/scenario-variation/scenario-variation.query';
import { NO_VARIATION } from 'prosumer-app/stores/scenario-variation/scenario-variation.state';
import { Coerce } from 'prosumer-core/utils';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VariationFinder {
  constructor(private readonly variations: ScenarioVariationQuery) {}

  getVariationName(id: string): string {
    return Coerce.toString(this.variations.getName(id), NO_VARIATION);
  }
}
