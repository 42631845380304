import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface CaseProjectIdsI {
  projectId: string;
  caseId: string;
}

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-simulation',
  templateUrl: './scenario-simulation.component.html',
  styleUrls: ['./scenario-simulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioSimulationComponent {
  readonly runningIcon = '/assets/icons/running.png';
  private info: CaseProjectIdsI;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.route.parent?.params
      .pipe(untilDestroyed(this))
      .subscribe((p) => this.extractInfo(p));
  }

  onOK(): void {
    this.router.navigate(this.urlFragmentsToSelectedCase());
  }

  private urlFragmentsToSelectedCase(): string[] {
    return ['projects', this.info.projectId, 'cases', this.info.caseId];
  }

  private extractInfo(d: Params) {
    this.info = d as CaseProjectIdsI;
  }
}
