import { CaseFacadeService } from 'prosumer-app/+case/state';
import { ProjectFacadeService } from 'prosumer-app/+project/state';
import {
  ScenarioWizardStep,
  WizardStepCriteria,
} from 'prosumer-app/+scenario/services/scenario-completion';
import {
  EXECUTION_STATUS,
  PROSUMER_USER_ROLES,
} from 'prosumer-app/app.references';
import { LoggerService, UserFacadeService } from 'prosumer-app/libs/eyes-core';
import { PageMode, StepperFormComponent } from 'prosumer-app/libs/eyes-shared';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { ConflictCheckerService } from 'prosumer-app/shared';
import { ScenarioGenericQuery } from 'prosumer-app/stores/scenario-generic';
import { PipeUtils } from 'prosumer-core/utils';
import { PermissionCheckerService } from 'prosumer-shared/services/permission-checker';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  delay,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  startWith,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  CoherenceResult,
  MobilityForm,
  Scenario,
  TopologyForm,
} from '../../models';
import {
  EnergyVectorCascaderService,
  LoadCascaderService,
  NodeCascaderService,
  VehicleCascaderService,
} from '../../services';
import { ScenarioFacadeService } from '../../state';
import { ScenarioPageExts } from './scenario-page.exts';

export const BUTTON_LABELS = {
  previous: 'Generic.labels.previous',
  cancel: 'Generic.labels.cancel',
  back: 'Generic.labels.back',
  next: 'Generic.labels.next',
  save: 'Generic.labels.save',
  create: 'Generic.labels.create',
  simulate: 'Generic.labels.simulate',
  yes: 'Generic.labels.yes',
  no: 'Generic.labels.no',
};

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-page',
  templateUrl: './scenario-page.component.html',
  styleUrls: ['./scenario-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    EnergyVectorCascaderService,
    NodeCascaderService,
    LoadCascaderService,
    VehicleCascaderService,
  ],
  standalone: false,
})
export class ScenarioPageComponent
  extends StepperFormComponent<Scenario>
  implements OnInit, AfterViewInit
{
  coherenceResults: CoherenceResult[] = [];
  coherencing = false;
  hasCoherenceErrs = false;

  readonly timeToWaitForStepper = 200;
  @ViewChild(MatStepper) stepper: MatStepper;

  private readonly loadFormData = new BehaviorSubject(
    ScenarioWizardStep.general,
  );
  loadFormData$ = this.loadFormData.asObservable();

  /* Route Observables */
  routeMode$: Observable<PageMode> = this._route.data.pipe(
    take(1),
    filter((data) => !!data),
    map((data) => data.mode || 'read'),
    this.takeUntilShare(),
  );

  routeParams$ = this._route.params.pipe(take(1), this.takeUntilShare());

  // Indicates if we should fetch data
  fetchData$ = this.routeMode$.pipe(
    filter((mode) => mode === 'update' || mode === 'read'),
    this.takeUntilShare(),
  );

  projectIdParam$ = this.routeParams$.pipe(
    filter((params) => !!params && !!params.projectId),
    map((params) => params.projectId),
    tap((projectId) => this._projectFacade.selectId(projectId)),
    tap((projectId) =>
      this.form.controls.projectId.patchValue(projectId, { emitEvent: false }),
    ),
    this.takeUntilShare(),
  );

  caseIdParam$ = this.routeParams$.pipe(
    filter((params) => !!params && !!params.caseId),
    map((params) => params.caseId),
    tap((caseId) => this._caseFacade.selectId(caseId)),
    tap((caseId) =>
      this.form.controls.caseId.patchValue(caseId, { emitEvent: false }),
    ),
    this.takeUntilShare(),
  );

  scenarioIdParam$ = this.routeParams$.pipe(
    filter((params) => !!params && !!params.scenarioId),
    map((params) => params.scenarioId),
    tap((scenarioId) => this._scenarioFacade.selectId(scenarioId)),
    tap((scenarioId) =>
      this.form.controls.id.patchValue(scenarioId, { emitEvent: false }),
    ),
    this.takeUntilShare(),
  );
  /* Project Facade Observable/s */
  selectedProject$ = this._projectFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  /* Case Facade Observable/s */
  selectedCase$ = this._caseFacade.selectedData$.pipe(this.takeUntilShare());

  isMultiNode$ = this.selectedCase$.pipe(
    filter((_case) => !!_case && _case.loaded),
    map((_case) => _case.nodeType === 'multiple'),
    take(1),
  );

  _prefillWACC$ = this.selectedCase$.pipe(
    withLatestFrom(this.routeMode$),
    filter(([_case, routeMode]) => !!_case && routeMode === 'create'),
    map(([_c]) => _c),
    tap((_case) => this.form.get('general').patchValue({ wacc: _case.wacc })),
    this.takeUntilShare(),
  );

  /* Scenario Facade Observables */
  scenarioMap$ = this._scenarioFacade.dataMap$.pipe(this.takeUntilShare());
  selectedId$ = this._scenarioFacade.selectedId$.pipe(this.takeUntilShare());
  selectedScenario$ = this._scenarioFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );
  selectedScenarioQuery$ = this._scenarioQuery
    .selectActiveScenarioMapped()
    .pipe(this.takeUntilShare());

  // Progress Indicators
  loading$ = this.selectedScenario$.pipe(
    filter((scenario) => !!scenario),
    map((scenario) => scenario.loading),
    this.takeUntilShare(),
  );

  loaded$ = this.selectedScenario$.pipe(
    filter((scenario) => !!scenario),
    map((scenario) => scenario.loaded),
    this.takeUntilShare(),
  );

  updating$ = this.selectedScenario$.pipe(
    filter((scenario) => !!scenario),
    map((scenario) => scenario.updating),
    this.takeUntilShare(),
  );

  checkingConflicts$ = this._conflictCheckerService.checkingConflicts$.pipe(
    this.takeUntilShare(),
  );

  saving$ = combineLatest([
    this._scenarioFacade.creating$.pipe(startWith(false)),
    this.updating$.pipe(startWith(false)),
    this.checkingConflicts$.pipe(startWith(false)),
  ]).pipe(
    map(
      ([creating, updating, checkingConflicts]) =>
        creating || updating || checkingConflicts,
    ),
    this.takeUntilShare(),
  );

  simulating$ = this._scenarioFacade.simulating$.pipe(this.takeUntilShare());

  validating$ = this._scenarioFacade.validating$.pipe(this.takeUntilShare());

  // Options
  predefinedEnergyVectors$ = this._scenarioFacade.predefinedEnergyVectors$.pipe(
    this.takeUntilShare(),
  );

  // Selected References
  results$ = this._scenarioFacade.recentCoherenceResults$.pipe(
    this.takeUntilShare(),
  );
  selectedEnergyVectors$ = this._scenarioFacade.selectedEnergyVectors$.pipe(
    this.takeUntilShare(),
  );
  selectedNodes$ = this._scenarioFacade.selectedNodes$.pipe(
    this.takeUntilShare(),
  );
  selectedEquipments$ = this._scenarioFacade.selectedEquipments$.pipe(
    this.takeUntilShare(),
  );
  selectedEnergyGridConnections$ =
    this._scenarioFacade.selectedEnergyGridConnections$.pipe(
      this.takeUntilShare(),
    );
  selectedLoads$ = this._scenarioFacade.selectedLoads$.pipe(
    this.takeUntilShare(),
  );
  selectedNettings$ = this._scenarioFacade.selectedNettings$.pipe(
    this.takeUntilShare(),
  );
  selectedScenarioVariations$ =
    this._scenarioFacade.selectedScenarioVariations$.pipe(
      this.takeUntilShare(),
    );

  /**
   * start year of project duration.
   */
  startYear$ = this._scenarioFacade.selectedStartYear$.pipe(
    this.takeUntilShare(),
  );
  /**
   * end year of project duration.
   */
  endYear$ = this._scenarioFacade.selectedEndYear$.pipe(this.takeUntilShare());

  durationStart = 0;
  durationEnd = 0;

  /* Scenario Action Dispatchers */
  // Retrieve scenario from API if there is no selected scenario or scenario does not contain general field yet
  getScenarioData$ = this.fetchData$.pipe(
    mergeMap(() => this.selectedScenario$),
    take(1),
    withLatestFrom(
      this.projectIdParam$,
      this.caseIdParam$,
      this.scenarioIdParam$,
    ),
    tap(([scenario, projectId, caseId, scenarioId]) => {
      const forced = scenario && scenario.general ? false : true;
      return this._scenarioFacade.get(
        scenarioId,
        { projectId, caseId },
        forced,
      );
    }),
    this.takeUntilShare(),
  );

  getCaseData$ = this.selectedCase$.pipe(
    take(1),
    filter((_case) => !!!_case),
    withLatestFrom(this.projectIdParam$, this.caseIdParam$),
    tap(([, projectId, caseId]) => this._caseFacade.get(caseId, { projectId })),
    this.takeUntilShare(),
  );

  getProjectData$ = this.selectedProject$.pipe(
    take(1),
    filter((project) => !!!project),
    withLatestFrom(this.projectIdParam$),
    tap(([, projectId]) => this._projectFacade.get(projectId)),
    this.takeUntilShare(),
  );

  scenarioIdentity$ = this.selectScenarioIdentity();

  isScenarioCohesive$ = this.results$.pipe(
    map((results: Array<CoherenceResult>) =>
      !results ? true : results.every((result) => result.type !== 'error'),
    ),
  );

  editButtonVisible$ = this.mode$.pipe(
    take(1),
    mergeMap((mode) =>
      this.selectedScenario$.pipe(
        filter((scenario) => !!scenario && scenario.loaded),
        take(1),
        map((scenario) => mode === 'read' && scenario.status !== 'succeeded'),
      ),
    ),
  );

  /* Users */
  users$ = this._userFacade.dataMap$.pipe(this.takeUntilShare());

  /* Current User */
  currentUser$ = this._userFacade.clientUser$.pipe(this.takeUntilShare());

  isClientExpert$ = this._userFacade.clientUserPrefs$.pipe(
    filter((userPrefs) => !!userPrefs),
    map(
      (userPrefs) =>
        (userPrefs || {}).prosumerRole === PROSUMER_USER_ROLES.expert,
    ),
    take(1),
  );

  hasMobilityFeature$ = this._userFacade.clientUserPrefsFeatures$.pipe(
    filter((userPrefs) => !!userPrefs),
    map((userPrefs) => (userPrefs || {}).mobility === 1),
    take(1),
  );

  hasNettingFeature$ = this._userFacade.clientUserPrefsFeatures$.pipe(
    filter((userPrefs) => !!userPrefs),
    map((userPrefs) => (userPrefs || {}).netting === 1),
    take(1),
  );

  isMobility = false;
  isMobility$ = this._userFacade.clientUserPrefs$.pipe(
    filter((userPrefs) => !!userPrefs),
    map((userPrefs) => (userPrefs || {}).prosumerFeatures || undefined),
    map((prosumerFeatures) =>
      prosumerFeatures
        ? (JSON.parse(prosumerFeatures as string) || {}).mobility === 1
        : false,
    ),
    take(1),
  );

  private wizardStepCriteria = new BehaviorSubject<WizardStepCriteria>({
    removeMobility: false,
    removeNetting: false,
    removeFrequencyControl: false,
    removeTopology: false,
  });

  isViewOnly = () =>
    combineLatest([
      this.selectedProject$,
      this.currentUser$,
      this.selectedScenario$,
    ]).pipe(
      map(
        ([project, user, scenario]) =>
          !this._permissionChecker.isUserPermitted(project, user, scenario)[
            'CAN_UPDATE'
          ] ||
          ![
            EXECUTION_STATUS.DRAFT,
            EXECUTION_STATUS.FAILED,
            EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
          ].includes(scenario?.status),
      ),
    );

  readonly selectedTab$ = this.keeper.selectActiveTab();

  constructor(
    changeDetector: ChangeDetectorRef,
    formBuilder: UntypedFormBuilder,
    private _location: Location,
    private _logger: LoggerService,
    private _route: ActivatedRoute,
    private _scenarioFacade: ScenarioFacadeService,
    private _caseFacade: CaseFacadeService,
    private _projectFacade: ProjectFacadeService,
    private _permissionChecker: PermissionCheckerService,
    private _userFacade: UserFacadeService,
    private _conflictCheckerService: ConflictCheckerService,
    private keeper: ActiveKeeperService,
    private _router: Router,
    private _scenarioQuery: ScenarioGenericQuery,
  ) {
    super(null, changeDetector, formBuilder);
  }

  getLastStepIdx() {
    const NON_STEP_CONTROLS = ['id', 'projectId', 'caseId'];
    const stepControls =
      Object.keys(this.form.value || {}).filter(
        (key) => !NON_STEP_CONTROLS.includes(key),
      ) || [];
    return stepControls.length; // + 1 for the simulation step
  }

  defineForm() {
    return {
      // Identifiers
      id: undefined,
      projectId: undefined,
      caseId: undefined,
      // Wizard Model
      general: undefined,
      time: undefined,
      topology: undefined,
      loads: undefined,
      equipments: undefined,
      commodities: undefined,
      mobility: undefined,
      frequencyControl: undefined,
      netting: undefined,
      regulations: undefined,
      optimizationCockpit: undefined,
    };
  }

  ngOnInit() {
    this.routeMode$
      .pipe(filter((mode) => mode === 'create'))
      .subscribe((mode) => {
        this._scenarioFacade.selectId(undefined);
        this.changeMode(mode);
      });
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.getProjectData$.pipe(untilDestroyed(this)).subscribe();
    this.getCaseData$.pipe(untilDestroyed(this)).subscribe();
    this.getScenarioData$.pipe(untilDestroyed(this)).subscribe();
    this._prefillWACC$.pipe(untilDestroyed(this)).subscribe();
    this.isMultiNode$.pipe(untilDestroyed(this)).subscribe((isMultiNode) => {
      // If case is singleNode, patch the topology form with defaul node
      if (!isMultiNode) {
        this.form.get('topology').patchValue(
          {
            nodes: [{ name: 'Single Node', value: '00001' }],
          } as TopologyForm,
          { emitEvent: false },
        );
      }
    });
    this.isMobility$.pipe(untilDestroyed(this)).subscribe((isMobility) => {
      this.isMobility = isMobility;
      // If case is no mobility, patch the mobility form with empty
      if (!isMobility) {
        this.form.get('mobility').patchValue(
          {
            routes: {},
            vehicles: [],
            stationVehicleAssoc: [],
          } as MobilityForm,
          { emitEvent: false },
        );
      }
    });

    combineLatest([this.selectedScenario$, this.routeMode$])
      .pipe(
        filter(
          ([scenarioData]) =>
            !!scenarioData &&
            scenarioData.loaded &&
            !scenarioData.loading &&
            !scenarioData.updating &&
            !scenarioData.error,
        ),
        tap(([scenarioData]) => {
          this._logger.debug('Scenario Data', scenarioData);
          this.updateFormValue(scenarioData);
        }),
        this.takeUntilShare(),
      )
      .subscribe(([scenarioData, mode]) => {
        if (
          mode !== 'update' ||
          [
            EXECUTION_STATUS.DRAFT,
            EXECUTION_STATUS.FAILED,
            EXECUTION_STATUS.IMPOSSIBLE_SIMULATION,
          ].includes(scenarioData.status)
        ) {
          this.changeMode(mode);
        }
      });

    combineLatest([
      this.isMultiNode$, // For Topology step (if applicable)
      this.isClientExpert$, // For Expert exclusive steps (if applicable)
      this.isMobility$, // For Mobility step (if applicable)
      this.hasNettingFeature$, // For Netting step (if applicable)
    ])
      .pipe(
        take(1),
        map((stepConditions) => ({
          lastStepIndex: stepConditions.reduce(
            (acc, step) => (!step ? acc + 1 : acc),
            0,
          ),
          stepConditions,
        })),
      )
      .subscribe(
        ({
          lastStepIndex,
          stepConditions: [isMulti, isClient, isMobility, hasNetting],
        }) => {
          this.wizardStepCriteria.next({
            ...this.wizardStepCriteria.value,
            removeTopology: !isMulti,
            removeFrequencyControl: !isClient,
            removeMobility: !isMobility,
            removeNetting: !hasNetting,
          });

          this.lastStepIndex -= lastStepIndex;

          this.subscribeToActiveScenarioTab();
        },
      );
  }

  handleConflictAndReload() {
    const formValue = this.form.getRawValue() || {};
    return this.selectedScenarioQuery$.pipe(take(1)).pipe(
      mergeMap((scenario) =>
        this._conflictCheckerService.checkConflictAndConfirmReload(scenario),
      ),
      tap((hasConflict) => {
        // reload scenario if scenario is outdated
        if (hasConflict) {
          this._scenarioFacade
            .get(
              formValue['id'],
              {
                projectId: formValue['projectId'],
                caseId: formValue['caseId'],
              },
              true,
            )
            .pipe(take(1));
        }
      }),
    );
  }

  // Implement abstract method mapToFormData(data: T)
  mapToFormData(data: Scenario) {
    return {
      // Identifiers
      id: data.id,
      projectId: data.projectId,
      caseId: data.caseId,
      // Wizard Models
      general: data.general,
      time: data.time,
      topology: data.topology,
      equipments: data.equipments,
      loads: data.loads,
      commodities: data.commodities,
      mobility: data.mobility,
      frequencyControl: data.frequencyControl,
      netting: data.netting,
      regulations: data.regulations,
      optimizationCockpit: data.optimizationCockpit,
    };
  }

  onPrevious() {
    if (this.pastFirstPage()) {
      this.goPrevious();
    } else {
      this._location.back();
    }
  }

  private pastFirstPage(): boolean {
    return this.getSelectedStepIndex() > 0;
  }

  onNext(data: unknown) {
    if (this.isLastStep()) {
      this.onSimulate(data as { valid: boolean });
    } else {
      this.goNext();
    }
  }

  onSimulate(data: { valid: boolean }) {
    this._logger.debug(
      `onSimulate() - Form is ${data.valid ? 'valid' : 'invalid'}`,
    );
    if (!data.valid) {
      return;
    }
    const formValue = this.form.getRawValue();
    this.handleConflictAndReload().subscribe(() => {
      this._scenarioFacade.launch(formValue);
    });
  }

  canChangeSteps(mode: PageMode): boolean {
    return this.getModesAllowedToChangeSteps().includes(mode);
  }

  private getModesAllowedToChangeSteps(): PageMode[] {
    return ['read', 'update'];
  }

  private selectScenarioIdentity(): Observable<{
    projectId: string;
    caseId: string;
    scenarioId: string;
  }> {
    return this.selectTruthyScenario().pipe(
      map((scenario) => ({
        projectId: scenario.projectId,
        caseId: scenario.caseId,
        scenarioId: scenario.id,
      })),
    );
  }

  private selectTruthyScenario(): Observable<Scenario> {
    return this._scenarioFacade.selectedData$.pipe(
      filter((scenario) => !!scenario),
    );
  }

  private subscribeToActiveScenarioTab() {
    this.keeper
      .selectActive()
      .pipe(
        untilDestroyed(this),
        PipeUtils.filterOutUndefined,
        distinctUntilKeyChanged('selectedScenarioTab'),
        ScenarioPageExts.skipDefaultFirstStep,
        delay(this.timeToWaitForStepper),
      )
      .subscribe(({ selectedScenarioTab }) => {
        this.loadFormData.next(selectedScenarioTab);
        const scenarioSteps = ScenarioPageExts.getApplicableSteps(
          this.wizardStepCriteria.value,
        );
        const selectedStepIndex = ScenarioPageExts.getIndexOfStep(
          selectedScenarioTab,
          scenarioSteps,
        );
        this.stepper.selectedIndex = selectedStepIndex;
        this.changeSelectedStepIndex(selectedStepIndex);
        this.markAsPristine();
      });
  }

  handleChangeStep(change: StepperSelectionEvent): void {
    const tab = ScenarioPageExts.getSelectedStep(
      this.wizardStepCriteria.value,
      change.selectedIndex,
    );
    this.keeper.setActiveTab(tab);
    this.onChangeStep(change);
    this.updateUrlQueryParameter(tab);
  }

  private updateUrlQueryParameter(tab: string): void {
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: { scenarioTab: tab },
    });
  }

  handleButtonLabels(mode: PageMode) {
    switch (mode) {
      case 'update':
        if (this.getSelectedStepIndex() > this.firstStepIndex) {
          this.changePreviousLabel(BUTTON_LABELS.previous);
        } else {
          this.changePreviousLabel(BUTTON_LABELS.back);
        }
        this.changeNextLabel(
          this.getSelectedStepIndex() === this.lastStepIndex
            ? BUTTON_LABELS.simulate
            : BUTTON_LABELS.next,
        );
        return;
      default:
        this.changeNextLabel(BUTTON_LABELS.next);
    }
  }
}
