import {
  BaseComponent,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { isSimulationSuccessful } from 'prosumer-app/shared/utils';
import { ResultStore } from 'prosumer-app/stores';
import { Utils } from 'prosumer-core/utils';
import { Scenario } from 'prosumer-scenario/models';
import { BehaviorSubject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

const ALL_OPTION_VALUE = 'ALL';
const ALL_SCENARIO_VARIATION_OPTION = { name: 'All', value: ALL_OPTION_VALUE };

@Component({
  selector: 'prosumer-scenario-variation-option',
  templateUrl: './scenario-variation-option.component.html',
  styleUrls: ['./scenario-variation-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioVariationOptionComponent
  extends BaseComponent
  implements OnInit
{
  _scenarioVariationOptions: Array<FormFieldOption<string>> = [];
  _scenario: Scenario;
  scenarioVariationForm = this._formService.initForm({ scenarioVariation: '' });
  isVariationDropdownVisible = new BehaviorSubject<boolean>(false);
  selectedVariationId$ = new BehaviorSubject<string>(undefined);

  @Input() set scenarioVariationOptions(
    options: Array<FormFieldOption<string>>,
  ) {
    this._scenarioVariationOptions = options;
    this.scenarioVariationForm.controls.scenarioVariation.patchValue(
      this.getSelectedVariationId() ??
        Utils.resolveToEmptyObject(options[0]).value,
    );
  }

  get scenarioVariationOptions(): Array<FormFieldOption<string>> {
    return this._scenarioVariationOptions;
  }

  @Input() set scenario(data: Scenario) {
    this._scenario = data;
    this.addBaseCaseToVariationOptions();
    this.addAllToVariationOptions();
    this.setVariationDropdownVisibility();
  }

  get scenario(): Scenario {
    return this._scenario;
  }

  @Output() variationChange = new EventEmitter<void>();

  currentVariationID$ = this._resultStore.currentVariationID$.pipe(
    this.takeUntilShare(),
  );

  constructor(
    private _formService: FormService,
    private _resultStore: ResultStore,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSelectedVariation();
    this.subscribeToSelectedVariation();
  }

  onVariationChange(event: any) {
    this.variationChange.emit(event);
  }

  private addBaseCaseToVariationOptions() {
    if (
      isSimulationSuccessful({ status: this._scenario.status }) &&
      !this._scenarioVariationOptions.some(
        (option) => option.value === this._scenario.id,
      )
    ) {
      this._scenarioVariationOptions.unshift({
        name: this._scenario.name,
        value: this._scenario.id,
      });
      this.scenarioVariationForm.controls.scenarioVariation.patchValue(
        this.getSelectedVariationId() ??
          Utils.resolveToEmptyObject(this._scenarioVariationOptions[0]).value,
      );
    }
  }

  private addAllToVariationOptions() {
    if (
      this._scenarioVariationOptions.length > 1 &&
      !this._scenarioVariationOptions.some(
        (option) => option.value === ALL_OPTION_VALUE,
      )
    ) {
      this._scenarioVariationOptions.push(ALL_SCENARIO_VARIATION_OPTION);
    }
  }

  private setVariationDropdownVisibility() {
    this.isVariationDropdownVisible.next(!this.shouldHideVariationDropdown());
  }

  private shouldHideVariationDropdown(): boolean {
    return (
      this._scenarioVariationOptions.length === 1 &&
      this._scenarioVariationOptions[0].value === this._scenario.id
    );
  }

  private setSelectedVariation(): void {
    this.currentVariationID$.subscribe((currentVariationID) => {
      this.selectedVariationId$.next(currentVariationID);
    });
  }

  private getSelectedVariationId(): string {
    return this._scenarioVariationOptions.some(
      (option) => option.value === this.selectedVariationId$.getValue(),
    )
      ? this.selectedVariationId$.getValue()
      : undefined;
  }

  private subscribeToSelectedVariation(): void {
    this.selectedVariationId$.subscribe((variationId) => {
      if (!!variationId) {
        this.scenarioVariationForm.controls.scenarioVariation.patchValue(
          variationId,
        );
      }
    });
  }
}
