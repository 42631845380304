import {
  EnergyVector,
  Equipment,
  Library,
  LibraryLoads,
  Profile,
  RenewableEnergy,
} from 'prosumer-app/+scenario/models';
import {
  EnergyVectorCascaderService,
  NodeCascaderService,
} from 'prosumer-app/+scenario/services';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { BINARY_LOCATIONS, DER_TYPES } from 'prosumer-app/app.references';
import { Coerce } from 'prosumer-app/core/utils';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  ColumnDefinition,
  CustomValidators,
  doNothing,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import {
  convertToYearlyValues,
  getSelectedEnergyVector,
  mapTDBDataToProfile,
  NameValidator,
  ProfileFormHelperService,
  YearlyLoadsIntevalValidators,
} from 'prosumer-app/shared';
import { getTDBTechFilterFromData } from 'prosumer-app/shared/modules/tdb/mappers';
import { LibraryService, ScenarioBinStore } from 'prosumer-app/stores';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EquipmentFormDialogData } from '../equipment-dialog.model';

const MAX_LOADS = 8784;
const NON_LIBRARY_SOURCETYPES = ['custom', 'tdb'];

@Component({
  selector: 'prosumer-vre',
  templateUrl: './vre.component.html',
  styleUrls: ['./vre.component.scss'],
  providers: [
    ProfileFormHelperService,
    TitleCasePipe,
    EnergyVectorCascaderService,
    NodeCascaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class VREComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;

  @Input() nodeOptions: Array<FormFieldOption<string>> = [];
  @Input() outputEnergyVectorOptions: Array<EnergyVector> = [];

  get derTypes() {
    return DER_TYPES;
  }

  scenarioId: string;
  caseId: string;
  projectId: string;
  submitted$ = new BehaviorSubject<boolean>(false);
  nodeWarningShown$ = new BehaviorSubject<boolean>(false);
  evWarningShown$ = new BehaviorSubject<boolean>(false);
  binaryLoading$: Observable<boolean>;
  isFormLoading$ = new BehaviorSubject<boolean>(false);

  defaultValues = {
    yearlyCapacityLoss: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMinPower: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyMaxPower: !!!this.data
      ? {}
      : convertToYearlyValues(
          '100000.0',
          this.data.startYear,
          this.data.endYear,
        ),
    yearlyBuildCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyIndivisibleCost: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMCharge: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyFOAndMInstall: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyTechnicalLife: !!!this.data
      ? {}
      : convertToYearlyValues('20', this.data.startYear, this.data.endYear),
    yearlyDepreciationTime: !!!this.data
      ? {}
      : convertToYearlyValues('0', this.data.startYear, this.data.endYear),
    yearlyFootprint: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfFinancing: !!!this.data
      ? {}
      : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
    yearlyCostOfDecommissioning: null,
    // !!!this.data
    // ? {}
    // : convertToYearlyValues(null, this.data.startYear, this.data.endYear),
  };

  yearlyLoadMessage: YearlyLoadMessageConfig = {
    loadTypeLabel: 'Scenario.labels.technical.profile',
    loadTypeTooltip: 'wizard_renewable.wizard_renewable_profile',
    loadsDataPlaceholder: 'Paste profile here',
    loadsDataLabelCustom: 'Scenario.labels.technical.profileKw',
    loadsDataTooltip: 'wizard_loads.wizard_loards_load_profile',
    loadsDataRequired: 'Scenario.messages.loads.required',
  };

  vreForm: UntypedFormGroup = this.formBuilder.group({
    name: '',
    type: 'vre',
    nodes: [],
    scenarioVariation: 'basecase',
    profileType: [{ value: 'custom', disabled: true }],
    sourceType: 'library',
    outputEnergyVector: '',
    loadName: '',
    data: [{ value: '' }, CustomValidators.textAreaLines(MAX_LOADS)],
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    yearlyCapacityLoss: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyMinPower: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyMaxPower: [
      !!!this.data
        ? {}
        : convertToYearlyValues(
            '100000.0',
            this.data.startYear,
            this.data.endYear,
          ),
      Validators.required,
    ],
    yearlyBuildCost: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyIndivisibleCost: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyTechnicalLife: [
      !!!this.data
        ? {}
        : convertToYearlyValues('20', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyDepreciationTime: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      !!!this.data
        ? {}
        : convertToYearlyValues('0.0', this.data.startYear, this.data.endYear),
      Validators.required,
    ],
    yearlyFootprint: [this.defaultValues.yearlyFootprint, Validators.required],
    yearlyCostOfFinancing: [
      this.defaultValues.yearlyCostOfFinancing,
      Validators.required,
    ],
    yearlyCostOfDecommissioning: [
      this.defaultValues.yearlyCostOfDecommissioning,
    ],
    id: '',
    library: [{ value: '' }, Validators.required],
    startYear: !!!this.data ? 0 : this.data.startYear,
    endYear: !!!this.data ? 0 : this.data.endYear,
    profiles: [],
    operatingCostProfiles: [null],
    tdbTechnologyFilter: [null],
  });

  nameCtrl: UntypedFormControl = this.vreForm.get('name') as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.vreForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');
  scenarioVariationOptions: Array<FormFieldOption<any>> = [];
  profileOptions: Array<FormFieldOption<ProfileType>> = [];
  library$: Observable<Array<Library>>;
  viewInitialized: boolean; // Used for change issues in energy vector
  isMultiNode: boolean;
  activeIndex = 0;
  selectedLoads: Array<string> = [];

  columnsDef: ColumnDefinition = {
    selection: {
      type: 'selection',
      flex: '50px',
    },
    id: {
      name: 'ID',
      flex: '20',
      sortable: true,
    },
    description: {
      name: 'Description',
      flex: 'calc(35% - 50px)',
      sortable: true,
    },
    buildCost: {
      name: 'Build Cost [€/kW]',
      flex: '15',
      sortable: true,
      alignment: 'flex-end',
    },
    technicalLife: {
      name: 'Technical Life [year]',
      flex: '15',
      sortable: true,
      alignment: 'flex-end',
    },
    capacityLoss: {
      name: 'Capacity Loss [-/year]',
      flex: '15',
      sortable: true,
      alignment: 'flex-end',
    },
  };

  outputEnergyVectorOptions$ = new BehaviorSubject<EnergyVector[]>([]);

  get messages() {
    return {
      loading: 'Scenario.messages.library.loading',
      noRecords: this.vreForm?.controls?.outputEnergyVector?.value
        ? 'Scenario.messages.library.noLibraryForEnergyVector'
        : 'Scenario.messages.library.noEnergyVectorSelected',
      noResults: 'Scenario.messages.library.noLibraryForEnergyVector',
      error: 'Scenario.messages.library.error',
    };
  }

  formType: string = this.vreForm.get('type').value;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: EquipmentFormDialogData<RenewableEnergy>,
    public profileFormHelperService: ProfileFormHelperService,
    public libraryService: LibraryService,
    public scenarioBinStore: ScenarioBinStore,
    public formBuilder: UntypedFormBuilder,
    private _changeDetector: ChangeDetectorRef,
    private _formService: FormService,
    private _logger: LoggerService,
    private _cascaderService: EnergyVectorCascaderService,
    private _nodeCascaderService: NodeCascaderService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.initScenarioEntity();
    this.initVreOptions();
    this.initValidators();
    this.initSourceTypeValueChange();
    this.initLibraryFilters();
    this.initLibraryValueChange();
    this.initObservables();
    this.initOutputEnergyVectorValueChange();
    this.initNodeValueChange();
    this.initPatchValues();
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    this.addProfilesCtrlValidator();
  }

  initScenarioEntity(): void {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  initVreOptions(): void {
    this.isMultiNode = this.data.isMultiNode;

    if (this.data.profileOptions) {
      this.data.profileOptions.forEach((option) =>
        option ? this.profileOptions.push(option) : doNothing,
      );
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }
  }

  initValidators(): void {
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());

    this.vreForm.controls.name.setValidators([NameValidator.validWithCore()]);

    // Apply same value validator for existing asset and forced investment
    this.vreForm.controls.existingAsset.setValidators([
      Validators.required,
      CustomValidators.sameValue(this.vreForm.controls.forcedInvestment),
    ]);
    this.vreForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.vreForm.controls.existingAsset.updateValueAndValidity(),
      );
  }

  initPatchValues(): void {
    this.vreForm.patchValue({
      ...this.data.equipment,
      data: undefined,
    });

    if (
      this.shouldLoadProfile() &&
      this.data.equipment.profiles !== undefined
    ) {
      this.vreForm.controls.profiles.patchValue(
        this.data.equipment.profiles.map((profile) => ({
          ...profile,
          loadProfile: profile.loadProfile || [],
        })),
      );
      if (
        !!!this.data.equipment.profiles[0].loadProfile ||
        this.data.equipment.profiles[0].loadProfile.length === 0
      ) {
        this.loadBinaryData(this.data.equipment.profiles[0]);
      }
    }
  }

  initSourceTypeValueChange(): void {
    // Whenever the profile type change, reset the value of library field
    this.vreForm.controls.sourceType.valueChanges
      .pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.sourceType
            : 'library',
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((sourceType) => {
        if (NON_LIBRARY_SOURCETYPES.includes(sourceType)) {
          this.vreForm.controls.library.clearValidators();
          this.vreForm.controls.library.patchValue(undefined);
        } else {
          this.vreForm.controls.library.setValidators(Validators.required);
          this.vreForm.controls.library.patchValue(
            (this.data.equipment || ({} as any)).library || undefined,
          );
        }
      });
  }

  initLibraryFilters(): void {
    // Filters the library list based on selected energy vector
    this.library$ = combineLatest([
      this.vreForm.controls.outputEnergyVector.valueChanges.pipe(
        startWith(
          this.data && this.data.equipment
            ? this.data.equipment.outputEnergyVector
            : undefined,
        ),
        getSelectedEnergyVector(
          ((this.data || ({} as any)).equipments || ({} as any))
            .outputEnergyVector,
          of(this.outputEnergyVectorOptions),
        ),
      ),
    ]).pipe(
      tap(() => {
        if (this.viewInitialized) {
          this.vreForm.controls.library.patchValue(undefined);
          this._changeDetector.markForCheck();
        }
      }),
      switchMap(([outputEnergyVector]) =>
        this.libraryService.getLibraryList$('vre', outputEnergyVector),
      ),
      takeUntil(this.componentDestroyed$),
      shareReplay(1),
    );
  }

  setFormValues(startYear: number, endYear: number, library: Library): void {
    this.patchControlWithValue(
      this.vreForm.controls.yearlyCapacityLoss,
      library.capacityLoss
        ? convertToYearlyValues(library.capacityLoss, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyCapacityLoss,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyMinPower,
      library.minPower
        ? convertToYearlyValues(library.minPower, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyMinPower,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyMaxPower,
      library.maxPower
        ? convertToYearlyValues(library.maxPower, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyMaxPower,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyBuildCost,
      library.buildCost
        ? convertToYearlyValues(library.buildCost, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyBuildCost,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyIndivisibleCost,
      library.indivisibleCost
        ? convertToYearlyValues(library.indivisibleCost, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyIndivisibleCost,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyFOAndMCharge,
      library.fOAndMCharge
        ? convertToYearlyValues(library.fOAndMCharge, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyFOAndMCharge,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyFOAndMInstall,
      library.fOAndMPerInstall
        ? convertToYearlyValues(library.fOAndMPerInstall, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyFOAndMInstall,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyTechnicalLife,
      library.technicalLife
        ? convertToYearlyValues(library.technicalLife, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyTechnicalLife,
    );
    this.patchControlWithValue(
      this.vreForm.controls.yearlyDepreciationTime,
      library.depreciationTime
        ? convertToYearlyValues(library.depreciationTime, startYear, endYear)
        : undefined,
      this.defaultValues.yearlyDepreciationTime,
    );
  }

  initLibraryValueChange(): void {
    // Patches the fields based on the library selected
    this.vreForm.controls.library.valueChanges
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((library: Library) => {
        this._logger.debug(library);

        if (!library) {
          return;
        }
        // advanced-input
        const startYear = !!this.vreForm.controls.startYear
          ? this.vreForm.controls.startYear.value
          : 0;
        const endYear = !!this.vreForm.controls.endYear
          ? this.vreForm.controls.endYear.value
          : 0;
        this.setFormValues(startYear, endYear, library);
      });
  }

  initOutputEnergyVectorValueChange(): void {
    const revertVectorValue = (
      currentProfileOptions,
      currentEnergyVector,
      currentLibrary,
    ) => {
      if (!!currentProfileOptions && currentProfileOptions.length === 0) {
        doNothing();
      } else {
        this.vreForm.controls.outputEnergyVector.patchValue(
          currentEnergyVector,
        );
        this.vreForm.controls.library.patchValue(currentLibrary);
      }
    };

    this.vreForm
      .get('outputEnergyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.evWarningShown$.value && this.vreForm.dirty) {
          return;
        }
        const currentEnergyVector = this.vreForm.value['outputEnergyVector'];
        const currentLibrary = this.vreForm.value['library'];
        const currentProfileOptions = this.outputEnergyVectorOptions$.value;

        let associatedTableVectors = [];
        const operatingCostProfileCtrlVal =
          this.vreForm.controls.operatingCostProfiles.value;
        if (
          !!operatingCostProfileCtrlVal &&
          !!operatingCostProfileCtrlVal.operatingCostProfiles
        ) {
          associatedTableVectors =
            operatingCostProfileCtrlVal.operatingCostProfiles.map(
              (profile) => profile.energyVectorId,
            );
        }

        const isOPAffected = this._cascaderService.isOperatingCostAffected(
          [currentEnergyVector],
          associatedTableVectors,
        );
        const isNettingAffected = this._cascaderService.isNettingAffected(
          this.vreForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );
        this._cascaderService
          .showCascadingWarningDialog([isOPAffected, isNettingAffected])
          .subscribe((isOk) => {
            if (isOk) {
              this.outputEnergyVectorOptions$.next(
                this.outputEnergyVectorOptions.filter(
                  (options) => options.value === value,
                ),
              );
              if (isNettingAffected) {
                this.evWarningShown$.next(true);
              }
            } else {
              revertVectorValue(
                currentProfileOptions,
                currentEnergyVector,
                currentLibrary,
              );
            }
          });
      });
  }

  initNodeValueChange(): void {
    this.vreForm
      .get('nodes')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.nodeWarningShown$.value && this.vreForm.dirty) {
          return;
        }

        const isNettingAffected = this._nodeCascaderService.isNettingAffected(
          this.vreForm.value['id'],
          value.nodes,
          this.data.currentNetting,
        );

        if (isNettingAffected) {
          this.nodeWarningShown$.next(true);
        }
        this._nodeCascaderService
          .showCascadingWarningDialog([isNettingAffected])
          .subscribe((isOk) => {
            // TODO: implement revert option
          });
      });
  }

  initObservables(): void {
    this.binaryLoading$ = this.scenarioBinStore.loading$;
  }

  onConfirm(): Equipment {
    this.submit();
    this.profileFormHelperService.setSubmitted();
    this.submitted$.next(true);
    const vreFormValue = this.vreForm.getRawValue();
    if (vreFormValue) {
      return {
        ...vreFormValue,
        operatingCostProfiles: vreFormValue?.operatingCostProfiles
          ? vreFormValue.operatingCostProfiles['operatingCostProfiles']
          : null,
        nodes: vreFormValue['nodes']
          ? vreFormValue['nodes']['nodes'] || vreFormValue['nodes'] || []
          : vreFormValue['nodes'],
      } as Equipment;
    }
  }

  /**
   * Updates the library value when selecting a library from the table
   *
   * @param libraryId - the library id selected
   */
  onSelect(libraryId: string): void {
    this.library$.pipe(take(1)).subscribe((library) => {
      if (!!library && library.length > 0) {
        this.vreForm.controls.library.patchValue(
          library.find(({ id }) => id === libraryId),
        );
        this.vreForm.markAsDirty();
      }
    });
  }

  loadBinaryData(selectedProfile: Profile): void {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = ((loads || {}) as LibraryLoads).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
        }
      });
  }

  loadBinaryData$(selectedProfile: Profile): Observable<unknown> {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this.scenarioBinStore
        .get(
          this.projectId,
          this.caseId,
          this.scenarioId,
          selectedProfile.location,
          selectedProfile.originalLocalId || selectedProfile.localId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }): void {
    this.activeIndex = value.index;
    if (
      this.shouldLoadProfile() &&
      this.profileFormHelperService.forBinDownload(
        this.data.equipment.profiles,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  submit(): void {
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }

  shouldLoadProfile(): boolean {
    const isLoadableMode = this.data.mode !== 'add';
    return isLoadableMode;
  }

  handleTdbProfileData(data: Record<string, unknown>): void {
    const profile = mapTDBDataToProfile(
      [this.data.startYear, this.data.endYear],
      BINARY_LOCATIONS.DER,
      data.values as number[],
    );
    this.vreForm.controls.profiles.patchValue([profile]);
  }

  handleTechnologyFiltersLoading(isLoading: boolean): void {
    this.isFormLoading$.next(isLoading);
  }

  handleTdbTechnologyData(data: Library): void {
    const startYear = Coerce.toNumber(this.vreForm.controls.startYear.value, 0);
    const endYear = Coerce.toNumber(this.vreForm.controls.endYear.value, 0);
    this.setFormValues(startYear, endYear, { ...data });
    this.patchTDBTechFilters({ ...data });
  }

  private patchTDBTechFilters(data: Record<string, unknown>): void {
    this.patchControlWithValue(
      this.vreForm.controls.tdbTechnologyFilter,
      getTDBTechFilterFromData(data),
    );
  }

  get extraQueryParamsTDB(): Record<string, string> {
    return {
      startYear: String(this.data.startYear),
      endYear: String(this.data.endYear),
    };
  }

  private addProfilesCtrlValidator() {
    this.vreForm.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(false, true),
    ]);
    this.vreForm.controls.profiles.updateValueAndValidity();
  }
}
