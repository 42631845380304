import isEqual from 'lodash/isEqual';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { SystemVisualizationService } from 'prosumer-app/shared/modules/system-visualization/services/';
import { buildEntityId } from 'prosumer-app/shared/utils';
import { ResultStore } from 'prosumer-app/stores';
import { ProsumerRoutePathService } from 'prosumer-core/services/prosumer-route-path';
import { PageStore } from 'prosumer-libs/eyes-core';
import {
  BaseComponent,
  FormFieldOption,
  FormService,
} from 'prosumer-libs/eyes-shared';
import { RESULTS_TABS_BAG, ResultsTabs } from 'prosumer-scenario/models';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  map,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';

import { CaseFacadeService } from '../../../+case/state';
import { ProjectFacadeService } from '../../../+project/state';
import { ScenarioFacadeService } from '../../../+scenario/state';

@Component({
  selector: 'prosumer-simulation-results',
  templateUrl: './simulation-results.component.html',
  styleUrls: ['./simulation-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SimulationResultsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  disabled$: Observable<boolean>;
  error$: Observable<boolean>;
  loading$: Observable<boolean>;
  projectId: string;
  caseId: string;
  scenarioId: string;
  isOutputSplit: boolean;
  hideTopology = false;
  scenarioVariations: Array<{ name: string; value: string }>;
  selectedScenarioVariationId$ = new BehaviorSubject<string>('');
  scenarioVariationOptions = new BehaviorSubject<FormFieldOption<string>[]>([]);
  routeParams$ = this._route.params.pipe(take(1), this.takeUntilShare());

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  scenarioVariationForm = this._formService.initForm({ scenarioVariation: '' });

  projectIdParam$ = this._scenarioFacade.selectedData$.pipe(
    filter((params) => !!params && !!params.projectId),
    map((params) => params.projectId),
    tap((projectId) => {
      this._projectFacade.selectId(projectId);
      this.projectId = projectId;
    }),
    this.takeUntilShare(),
  );

  caseIdParam$ = this._scenarioFacade.selectedData$.pipe(
    filter((params) => !!params && !!params.caseId),
    map((params) => params.caseId),
    tap((caseId) => {
      this._caseFacade.selectId(caseId);
      this.caseId = caseId;
    }),
    this.takeUntilShare(),
  );

  scenarioIdParam$ = this._scenarioFacade.selectedData$.pipe(
    filter((params) => !!params && !!params.id),
    map((params) => params.id),
    tap((scenarioId) => this._scenarioFacade.selectId(scenarioId)),
    tap((scenario) => this.keeper.setActive({ scenario })),
    this.takeUntilShare(),
  );

  selectedProject$ = this._projectFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  selectedCase$ = this._caseFacade.selectedData$.pipe(this.takeUntilShare());

  selectedScenario$ = this._scenarioFacade.selectedData$.pipe(
    this.takeUntilShare(),
  );

  getScenarioData$ = this.selectedScenario$.pipe(
    take(1),
    filter((scenario) => !!!scenario),
    withLatestFrom(
      this.projectIdParam$,
      this.caseIdParam$,
      this.scenarioIdParam$,
    ),
    tap(([, projectId, caseId, scenarioId]) =>
      this._scenarioFacade.get(scenarioId, { projectId, caseId }),
    ),
    this.takeUntilShare(),
  );

  getScenarioId$ = this.selectedScenario$.pipe(
    filter(
      (scenario) => !!scenario && !!scenario.projectId && !!scenario.caseId,
    ),
    this.takeUntilShare(),
    tap((scenario) => (this.scenarioId = scenario.id)),
  );

  getCaseData$ = this.selectedCase$.pipe(
    take(1),
    filter((_case) => !!!_case),
    withLatestFrom(this.projectIdParam$, this.caseIdParam$),
    tap(([, projectId, caseId]) => this._caseFacade.get(caseId, { projectId })),
    this.takeUntilShare(),
  );

  getProjectData$ = this.selectedProject$.pipe(
    take(1),
    filter((project) => !!!project),
    withLatestFrom(this.projectIdParam$),
    tap(([, projectId]) => this._projectFacade.get(projectId)),
    this.takeUntilShare(),
  );

  systemVisualization$ = this._resultStore.systemVisualization$.pipe(
    withLatestFrom(this.selectedScenario$),
    map(([viz, scenario]) => ({
      ...viz,
      scenarioId: scenario.id,
      variationId: this.scenarioVariationForm.controls.scenarioVariation.value,
    })),
  );

  dimensions$ = this._pageFacade.dimensions$.pipe(
    filter((value) => !!value),
    map((value) => ({
      width: value.width <= 0 ? window.innerWidth - 164 : value.width - 100,
      height: value.height <= 0 ? window.innerHeight - 264 : value.height - 200,
    })),
    this.takeUntilShare(),
  );

  isOutputSplit$ = this._resultStore.isOutputSplit$.pipe(
    this.takeUntilShare(),
    tap((isSplit) => (this.isOutputSplit = isSplit)),
  );

  succeededScenarioVariations$ =
    this._scenarioFacade.selectedScenarioSucceededVariations$.pipe(
      this.takeUntilShare(),
    );

  systemVisualizationTabVisible = false;

  entityUrl = (id: string) => {
    if (id === this.scenarioId) {
      return id;
    }
    return buildEntityId(this.scenarioId, id);
  };

  constructor(
    public _resultStore: ResultStore,
    private _projectFacade: ProjectFacadeService,
    private _caseFacade: CaseFacadeService,
    private _scenarioFacade: ScenarioFacadeService,
    private _route: ActivatedRoute,
    private _pageFacade: PageStore,
    private _systemVisualizationService: SystemVisualizationService,
    private _perception: ResultsPerceptionService,
    private _formService: FormService,
    private _router: Router,
    private _routePath: ProsumerRoutePathService,
    private keeper: ActiveKeeperService,
  ) {
    super();
  }

  ngOnInit() {
    this.getProjectData$.subscribe();
    this.getCaseData$.subscribe();
    this.getScenarioData$.subscribe();
    this.isOutputSplit$.subscribe();
    this.getScenarioId$.subscribe();
    this.succeededScenarioVariations$.subscribe((options) => {
      if (options.length > 0) {
        this.scenarioVariationOptions.next(options);
      }
    });

    this.selectedScenario$
      .pipe(
        filter(
          (scenario) =>
            !!scenario && !!scenario.run && !!scenario.run.systemFile,
        ),
      )
      .subscribe(() => {
        this.systemVisualizationTabVisible = true;
      });
    this.loading$ = this._resultStore.loading$.pipe(this.takeUntilShare());
    this.error$ = this._resultStore.error$.pipe(this.takeUntilShare());
    this.disabled$ = combineLatest([this.loading$, this.error$]).pipe(
      switchMap((data) => of(data[0] || data[1])),
      this.takeUntilShare(),
    );
    this._resultStore.isMultiNode$
      .pipe(
        map((isMultiNode) => (isMultiNode !== undefined ? isMultiNode : true)),
        this.takeUntilShare(),
      )
      .subscribe((isMultiNode) => {
        this.hideTopology = !isMultiNode;
      });

    this._perception
      .getPerceptionMapStream(this.caseId)
      .pipe(
        skip(1),
        this.takeUntilShare(),
        withLatestFrom(this.selectedCase$, this.loading$),
        filter(
          ([updatedPerception, selected, loading]) =>
            !!updatedPerception || selected.updating || !loading,
        ),
      )
      .subscribe(([updatedPerception, selected]) => {
        if (!isEqual(updatedPerception, selected.colorScheme)) {
          const updatedCase = { ...selected, colorScheme: updatedPerception };
          if (!!updatedCase && !!updatedCase.id && updatedCase.projectId) {
            this._caseFacade.updateChartColors(updatedCase);
          }
        }
      });

    this._resultStore.currentVariationID$
      .pipe(
        filter((variation) => !!variation),
        take(1),
      )
      .subscribe((currentVariationID) =>
        this.selectedScenarioVariationId$.next(currentVariationID),
      );
  }

  onTabSelectionChange(change: MatTabChangeEvent) {
    if (change.index !== 1) {
      this._systemVisualizationService.cleanUp();
    }
    this.handleTabActions(change.index);
    this.keeper.setActiveResult({ tab: RESULTS_TABS_BAG[change.index] });
  }

  private handleTabActions(tab: ResultsTabs): void {
    if (
      tab === ResultsTabs.main ||
      (tab !== ResultsTabs.systemVisualization && !this.isOutputSplit)
    ) {
      return;
    }
    this._scenarioFacade.selectedData$
      .pipe(take(1))
      .subscribe(({ projectId, caseId, id: scenarioId }) => {
        const tabsActions = {
          [ResultsTabs.main]: this._resultStore.getMainResults,
          [ResultsTabs.systemVisualization]:
            this._resultStore.getSystemVisualizationResults,
          [ResultsTabs.equipment]: this._resultStore.getEquipmentResults,
          [ResultsTabs.topology]: this._resultStore.getTopologyResults,
          [ResultsTabs.energyBalance]:
            this._resultStore.getEnergyBalanceResults,
          [ResultsTabs.cashflow]: this._resultStore.getCashFlowResults,
          [ResultsTabs.co2Emissions]: this._resultStore.getCO2EmissionsResults,
          [ResultsTabs.dispatch]: this._resultStore.getDispatchResults,
          [ResultsTabs.flows]: this._resultStore.getFlowsResults,
        };
        tabsActions[tab].bind(this._resultStore)(
          projectId,
          caseId,
          scenarioId,
          this.selectedScenarioVariationId$.getValue(),
        );
      });
  }

  onVariationChange(value: string) {
    if ('ALL' === value) {
      this.navigateToComparePage(
        this.entityUrl(this.scenarioVariationOptions.value[0].value),
        this.buildScenarioVariationParams(),
      );
      return;
    } else if (!!this.scenarioId && this.scenarioId === value) {
      this.selectedScenarioVariationId$.next(undefined);
      this.keeper.setActiveResultVariation(undefined);
    } else {
      this.selectedScenarioVariationId$.next(value);
      this.keeper.setActiveResultVariation(value);
    }

    this.fetchResultsForVariation(this.selectedScenarioVariationId$.getValue());
  }

  private buildScenarioVariationParams(): string {
    return this.scenarioVariationOptions.value
      .filter((variation) => variation.value !== 'ALL')
      .map((variation) => this.entityUrl(variation.value))
      .join(',');
  }

  private navigateToComparePage(reference: string, selected: string) {
    this._router.navigate(this.buildCompareVariationsUrlFragments(), {
      queryParams: this.buildCompareVariationsParams(reference, selected),
    });
  }

  private buildCompareVariationsUrlFragments(): string[] {
    return this._routePath.compare(
      this.projectId,
      this.caseId,
      this.scenarioId,
    );
  }

  private buildCompareVariationsParams(
    reference: string,
    selected: string,
  ): Params {
    return {
      reference,
      selected,
    };
  }

  private fetchResultsForVariation(variationId: string) {
    this._resultStore.clearResults();
    this.tabGroup.selectedIndex = 0;
    this._resultStore.getMainResults(
      this.projectId,
      this.caseId,
      this.scenarioId,
      variationId,
    );
  }

  ngOnDestroy(): void {
    this._resultStore.currentVariationID$
      .pipe(take(1))
      .subscribe((currentVariationID) => {
        if (
          !!currentVariationID &&
          this.scenarioVariationOptions.value
            .map((options) => options.value)
            .indexOf(currentVariationID) !== 0
        ) {
          this._resultStore.clearResults();
        }
      });
  }
}
