import { CascadeConfirmationComponent } from 'prosumer-app/shared/components/cascade-confirmation/cascade-confirmation.component';
import { DetailEntity } from 'prosumer-app/stores/scenario-detail/scenario-detail.state';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { CascadableEntity } from './cascade-controller.types';

@Injectable({
  providedIn: 'root',
})
export class CascadeController {
  constructor(private readonly dialog: MatDialog) {}

  confirmIfApplicable(target: CascadableEntity): Observable<unknown> {
    return this.dialog
      .open(CascadeConfirmationComponent, this.buildDialogConfig(target))
      .afterClosed();
  }

  private buildDialogConfig(target: DetailEntity): MatDialogConfig {
    return { data: target, maxHeight: '480px' };
  }
}
