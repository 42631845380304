import { Directive, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EGCEmissionStore } from 'prosumer-app/stores/egc-emission';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[prosumerEgcEmissionsGetter]',
  standalone: false,
})
export class EgcEmissionsGetterDirective implements OnInit {
  constructor(
    private readonly egcEmissions: EGCEmissionStore,
    private readonly notifs: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.egcEmissions
      .improvedGetAll()
      .pipe(take(1))
      .subscribe({ error: () => this.notifs.showError(this.getErrorMsg()) });
  }

  private getErrorMsg(): string {
    return 'Failed to get the EGC Emissions for this scenario';
  }
}
