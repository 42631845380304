import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  ReferencesBuilder,
  TABLE_REF_KEYS,
} from 'prosumer-app/services/references-builder';
import { SparklineTableColumnDefinition } from 'prosumer-app/shared/components/sparkline-table/sparkline-table.model';
import { ScenarioDetailType } from 'prosumer-app/stores';
import { VehiclesDispatchStore } from 'prosumer-app/stores/vehicles-dispatch';
import { VehiclesDispatch } from 'prosumer-scenario/models';
import { switchMap, take } from 'rxjs/operators';
import { MobilityFormService } from '../mobility-form.service';
import {
  VehiclesDispatchFormDialogComponent,
  VehiclesDispatchFormDialogData,
} from '../vehicles-dispatch-form-dialog';

@Component({
  selector: 'prosumer-vehicles-dispatch-form',
  templateUrl: './vehicles-dispatch-form.component.html',
  styleUrls: ['./vehicles-dispatch-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class VehiclesDispatchFormComponent {
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() isViewOnly: boolean;
  @Output() vehicleDeleteEvent = new EventEmitter();

  tableMetaData: SparklineTableColumnDefinition = {
    vehicleName: {
      name: 'Vehicle Name',
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_vehicle_name',
    },
    vehicleId: {
      name: 'Vehicle Technology',
      type: 'reference',
      referenceKey: TABLE_REF_KEYS.equipments,
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_vehicle_technology',
      cellTooltipReferenceKey: 'vehicleVariationAssociation',
    },
    routeIds: {
      name: 'Routes',
      type: 'referenceList',
      referenceKey: TABLE_REF_KEYS.routes,
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_route',
    },
    startYear: {
      name: 'Start Year',
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_start_year',
    },
    endYear: {
      name: 'End Year',
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_end_year',
    },
    numberOfVehicles: {
      name: 'Number of Vehicles',
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_number_of_vehicles',
    },
    v2g: {
      name: 'V2G',
      type: 'reference',
      referenceKey: TABLE_REF_KEYS.booleans,
      toolTip: 'wizard_mobility.wizard_mobility_dispatch_V2G',
    },
    actions: {
      name: 'Actions',
      type: 'action',
      sortable: true,
    },
  };

  readonly vehicleDispatchList$ = this.service.selectActiveVehicleDispatch();
  readonly references$ = this.references.selectRefs();

  constructor(
    private dialog: DialogService,
    private readonly service: MobilityFormService,
    private readonly references: ReferencesBuilder,
    private readonly dispatches: VehiclesDispatchStore,
  ) {}

  generateVehiclesDispatchData(): Omit<VehiclesDispatchFormDialogData, 'id'> {
    return {
      width: '90%',
      mode: 'add',
      disableClose: true,
      scenarioStartYear: this.startYear,
      scenarioEndYear: this.endYear,
      currentData: undefined,
      vehicleName: '',
      vehicleId: '',
      routeIds: [],
      startYear: this.startYear,
      endYear: this.endYear,
      numberOfVehicles: 1,
      v2g: false,
      ...this.service.prepOptionsForDispatchDialog(),
    };
  }

  onAddVehiclesDispatch(): void {
    this.dialog
      .openDialog(
        VehiclesDispatchFormDialogComponent,
        this.generateVehiclesDispatchData(),
      )
      .subscribe();
  }

  onEditVehiclesDispatch(data: VehiclesDispatch) {
    this.dispatches
      .getSingle(ScenarioDetailType.vehiclesDispatch, data.id)
      .pipe(
        take(1),
        switchMap((dispatch) =>
          this.dialog.openDialog(VehiclesDispatchFormDialogComponent, {
            ...this.generateVehiclesDispatchData(),
            ...dispatch,
            currentData: data,
            mode: 'edit',
            vehiclesDispathEditData: dispatch,
            isViewOnly: this.isViewOnly,
          } as VehiclesDispatch),
        ),
      )
      .subscribe();
  }

  onDeleteVehiclesDispatch(vehicle: VehiclesDispatch) {
    this.vehicleDeleteEvent.emit(vehicle);
  }
}
