/* eslint-disable @typescript-eslint/naming-convention */
import {
  BaseTableComponent,
  ColumnDefinition,
  FormService,
  rowAnimation,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'prosumer-line-investment',
  templateUrl: './line-investment.component.html',
  styleUrls: ['./line-investment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rowAnimation],
  standalone: false,
})
export class LineInvestmentComponent
  extends BaseTableComponent<any>
  implements OnInit
{
  noRecordMessage = 'There are no records found.';
  filterForm = this._formService.initForm({
    origin: '',
    destination: '',
    fluid: '',
  });

  originNodeOptions$ =
    this.resultStore.getLineInvestmentFilterOptions$('origin_node');
  destNodeOptions$ =
    this.resultStore.getLineInvestmentFilterOptions$('destination_node');
  fluidOptions$ = this.resultStore.getLineInvestmentFilterOptions$('fluid');

  loadingList$ = this.resultStore.loading$.pipe(this.takeUntilShare());

  error$ = this.resultStore.error$.pipe(this.takeUntilShare());

  dataSource$ = combineLatest([
    this.filterForm.controls.origin.valueChanges.pipe(startWith([])),
    this.filterForm.controls.destination.valueChanges.pipe(startWith([])),
    this.filterForm.controls.fluid.valueChanges.pipe(startWith([])),
  ]).pipe(
    switchMap(([origin, destination, fluid]) =>
      this.resultStore.getLineInvestmentByParams$(origin, destination, fluid),
    ),
    this.takeUntilShare(),
  );

  dataSourceLength$ = this.dataSource$.pipe(
    filter((data) => !!data),
    map((data) => data.length),
    this.takeUntilShare(),
  );

  constructor(
    public resultStore: ResultStore,
    private _formService: FormService,
  ) {
    super(null, null);
  }

  ngOnInit() {
    this.originNodeOptions$ =
      this.resultStore.getLineInvestmentFilterOptions$('origin_node');
    this.destNodeOptions$ =
      this.resultStore.getLineInvestmentFilterOptions$('destination_node');
    this.fluidOptions$ =
      this.resultStore.getLineInvestmentFilterOptions$('fluid');
    this.dataSource$.subscribe((data) => this.refreshData(data));
  }

  defineColumns(): ColumnDefinition {
    return {
      name: {
        headerTooltip: 'output_topology.output_topology_name',
        name: 'Name',
      },
      origin_node: {
        headerTooltip: 'output_topology.output_topology_origin_node',
        name: 'Origin Node',
      },
      destination_node: {
        headerTooltip: 'output_topology.output_topology_destination_node',
        name: 'Destination Node',
      },
      fluid: {
        headerTooltip: 'output_topology.output_topology_energy_vector',
        name: 'Energy Vector',
      },
      size_kw: {
        headerTooltip: 'output_topology.output_topology_size_kw',
        name: 'Size [kW]',
        rightAlign: true,
        decimalsOnly: true,
        decimals: 1,
      },
      init_invest_cost: {
        headerTooltip: 'output_topology.output_topology_inv_cost',
        name: 'Initial Investment Cost [k€]',
        rightAlign: true,
        metric: true,
        decimals: 1,
      },
      init_oper_cost: {
        headerTooltip: 'output_topology.output_topology_om_cost',
        name: 'O&M Cost First Year [k€]',
        rightAlign: true,
        metric: true,
        decimals: 1,
      },
      total_cost: {
        headerTooltip: 'output_topology.output_topology_total_cost',
        name: 'Total Discounted Cost [k€]',
        rightAlign: true,
        metric: true,
        decimals: 1,
      },
      yearly_flow_ab: {
        headerTooltip: 'output_topology.output_topology_flow_o_to_d',
        name: 'Annual Energy Flow (Origin To Destination) [kWh]',
        rightAlign: true,
        decimalsOnly: true,
        decimals: 0,
      },
      yearly_flow_ba: {
        headerTooltip: 'output_topology.output_topology_flow_d_to_o',
        headerTooltipPosition: 'left',
        name: 'Annual Energy Flow (Destination To Origin) [kWh]',
        rightAlign: true,
        decimalsOnly: true,
        decimals: 0,
      },
    } as any; // Temp
  }

  getFilteredData(data: any, formControl: UntypedFormControl) {
    formControl.patchValue(data.map((elem) => elem['name']));
  }
}
