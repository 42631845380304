import { Directive, OnInit } from '@angular/core';
import { MarketLimitStore } from 'prosumer-app/stores/market-limit';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[prosumerMarketLimitsGetter]',
  standalone: false,
})
export class MarketLimitsGetterDirective implements OnInit {
  constructor(private readonly marketLimits: MarketLimitStore) {}

  ngOnInit(): void {
    this.marketLimits.improvedGetAll().pipe(take(1)).subscribe();
  }
}
