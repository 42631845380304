import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'prosumer-dialog-div',
  templateUrl: './dialog-div.component.html',
  styleUrls: ['./dialog-div.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DialogDivComponent {
  @Input() title: string;
  @Input() okLabel: string;
  @Input() loading = false;
  @Input() disabled = false;
  @Output() ok = new EventEmitter();
  @Output() cancel = new EventEmitter();

  onOk(): void {
    this.ok.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }
}
