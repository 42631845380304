import { EnergyVectorReq } from 'prosumer-app/+scenario/models';
import { EnergyVector } from 'prosumer-app/+scenario/models/general.model';
import { EnergyVectorInfoService } from 'prosumer-app/+scenario/services/energy-vector-info';
import {
  BaseComponent,
  CustomValidators,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  generateUuid,
} from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { NameValidator } from 'prosumer-app/shared/validators';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import { Observable } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EnergyVectorFormDialogData } from './energy-vector-dialog.model';

const MAX_LOADS = 8784;

@Component({
  selector: 'prosumer-energy-vector',
  templateUrl: './energy-vector-dialog.component.html',
  styleUrls: ['./energy-vector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EnergyVectorDialogComponent
  extends BaseComponent
  implements OnInit
{
  energyVectorForm: UntypedFormGroup = this._formService.initForm({
    name: ['', NameValidator.validWithCore()],
    value: this.generateId(),
    custom: true,
    type: '',
  });
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.energyVectors');

  energyVectorTypeOptions: Array<FormFieldOption<string>> = [];
  readonly loading$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EnergyVectorFormDialogData,
    public dialogRef: MatDialogRef<EnergyVectorDialogComponent>,
    private _formService: FormService,
    public _notifications: NotificationsService,
    private query: EnergyVectorQuery,
    private evInfoService: EnergyVectorInfoService,
  ) {
    super();
    this.loading$ = this.query.selectLoading();
  }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    if (this.data.energyVectorTypeOptions) {
      this.data.energyVectorTypeOptions.forEach((option) =>
        this.energyVectorTypeOptions.push(option),
      );
    }
    this.initValidators();
    this.energyVectorForm.patchValue({
      ...this.data.energyVector,
    });
  }

  initValidators(): void {
    this.energyVectorForm.controls.name.setAsyncValidators(
      CustomValidators.dataExist(
        this.data.energyVectors$,
        'name',
        this.data.energyVector,
      ),
    );
  }

  onConfirm(): void {
    if (!this.energyVectorForm.valid) {
      return;
    }
    const data = this.mapDataToEnergyVectorReq(this.energyVectorForm);
    this.evInfoService.createEnergyVector(data).subscribe({
      next: (res) => {
        const customEnergyVector = this.mapResToEnergyVector(res);
        this.dialogRef.close({ ...customEnergyVector });
      },
      error: (error: HttpErrorResponse) => {
        this._notifications.showError(error.error?.error ?? error.message);
      },
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private generateId(): string {
    return generateUuid().slice(-5);
  }

  mapDataToEnergyVectorReq(form: UntypedFormGroup): EnergyVectorReq {
    return {
      name: form.get('name').value,
      energyVectorId: form.get('value').value,
      isCustom: true,
      vectorType: form.get('type').value,
    };
  }

  mapResToEnergyVector(res: any): EnergyVector {
    return {
      name: res.name,
      value: res.energyVectorId,
      custom: res.isCustom,
      type: res.vectorType,
    };
  }
}
