import { Case } from 'prosumer-app/+case';
import { SupportLinkService } from 'prosumer-app/shared';
import { PipeUtils } from 'prosumer-core/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ResultsPerceptionDialogComponent,
  ResultsPerceptionDialogData,
} from '@prosumer/results/containers/results-perception-dialog';

import { ResultsPerceptionService } from '../case-results-perception';

@Component({
  selector: 'prosumer-results-perception-trigger',
  templateUrl: './results-perception-trigger.component.html',
  styleUrls: ['./results-perception-trigger.component.scss'],
  standalone: false,
})
export class ResultsPerceptionTriggerComponent implements OnInit {
  caseObject$ = new BehaviorSubject<Case>(undefined);
  isLinkShowing$: Observable<boolean>;
  @Input() set caseObject(obj: Case) {
    this.caseObject$.next(obj);
  }
  constructor(
    private dialog: MatDialog,
    private perceptionService: ResultsPerceptionService,
    public supportLinkService: SupportLinkService,
  ) {}

  ngOnInit(): void {
    this.isLinkShowing$ = this.supportLinkService.isLinkShowing$;
    this.caseObject$
      .pipe(
        PipeUtils.filterOutUndefined,
        filter((caseObj) => !caseObj.loading),
        take(1),
      )
      .subscribe((caseObj) => {
        this.perceptionService.initializePerception(caseObj);
      });
  }

  onTrigger(): void {
    this.dialog.open(ResultsPerceptionDialogComponent, {
      data: {
        caseObject: this.caseObject$.value,
      } as ResultsPerceptionDialogData,
      disableClose: true,
    });
  }
}
