import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';

export enum EmissionsDirection {
  production = 'production',
  consumption = 'consumption',
}

@UntilDestroy()
@Component({
  selector: 'prosumer-emissions-direction',
  templateUrl: './emissions-direction.component.html',
  styleUrls: ['./emissions-direction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmissionsDirectionComponent {
  @Output() readonly directionChange = new EventEmitter<EmissionsDirection>();

  readonly directions = Object.values(EmissionsDirection);
  readonly selected = new UntypedFormControl(EmissionsDirection.production);
  readonly iconBag: Record<EmissionsDirection, string> = {
    [EmissionsDirection.production]: 'factory',
    [EmissionsDirection.consumption]: 'recycling',
  };

  constructor() {
    this.subscribeToSelectedForPropagation();
  }

  private subscribeToSelectedForPropagation(): void {
    this.selected.valueChanges
      .pipe(startWith(this.selected.value), untilDestroyed(this))
      .subscribe((value) => this.directionChange.emit(value));
  }
}
