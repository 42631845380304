import { StationVehicleAssoc } from 'prosumer-app/+scenario/models';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { provideUpserter } from 'prosumer-app/stores';
import { MobilityStationStore } from 'prosumer-app/stores/mobility-station';
import { Coerce } from 'prosumer-core/utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dictionary } from '@ngrx/entity';

import { VehicleStationFormDialogData } from './vehicle-station-form-dialog.model';

@Component({
  selector: 'prosumer-vehicle-station-form-dialog',
  templateUrl: './vehicle-station-form-dialog.component.html',
  styleUrls: ['./vehicle-station-form-dialog.component.scss'],
  providers: [provideUpserter(MobilityStationStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class VehicleStationFormDialogComponent
  extends BaseComponent
  implements OnInit
{
  vehicleStationForm = this.formbuilder.group({
    id: [undefined],
    vehicleId: ['', [Validators.required]],
    stationId: ['', [Validators.required]],
    vehicleNames: [[], [Validators.required]],
    stationNodes: [[], [Validators.required]],
  });

  vehicleOptions = [];
  vehicleNameOptions = [];
  stationNodeOptions = [];
  stationOptions = [];
  formattedStationOptions = [];
  uniqueAssocList = [];
  submitted$ = new BehaviorSubject<boolean>(false);
  vehicleNameErrorMessage$ = new BehaviorSubject<string>('');
  stationNodeErrorMessage$ = new BehaviorSubject<string>('');
  hashedAssocList = [];
  isViewOnly: boolean;

  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap(
      'Scenario.messages.mobility.vehicleStation',
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VehicleStationFormDialogData,
    public dialogRef: MatDialogRef<VehicleStationFormDialogComponent>,
    private formbuilder: UntypedFormBuilder,
    private _formService: FormService,
    private readonly detector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setValidator();
    this.initOptions();

    this.isViewOnly = this.data.isViewOnly || false;
  }

  initOptions() {
    this.skipValidationForEditingAssoc();

    if (this.data.vehicleNameOptions) {
      this.vehicleStationForm.controls.vehicleId.valueChanges.subscribe(
        (vehicleId) => {
          this.getVehicleNamesAndFilterToUnique(vehicleId);
        },
      );
    }

    if (this.data.vehicles) {
      const availableDispatchIds = this.getDispatchEquipmentIds();
      const filteredVehicles = this.data.vehicles.filter((veh) =>
        availableDispatchIds.includes(veh.value),
      );
      filteredVehicles.forEach((option) => this.vehicleOptions.push(option));
    }
    if (this.data.stationNodeOptions) {
      this.vehicleStationForm.controls.stationId.valueChanges.subscribe(
        (stationId) => {
          const selectedStation = this.data.stations.filter(
            (station) => stationId === station.value,
          );

          this.vehicleStationForm.controls.stationNodes.setValue([]);
          const nodes: Array<string> =
            selectedStation.length > 0 ? selectedStation[0]['nodes'] : [];
          let selectedNodes = [];
          selectedNodes = this.data.stationNodeOptions.filter((node) =>
            nodes.includes(node.value),
          );
          this.stationNodeOptions = selectedNodes;
        },
      );
    }

    if (this.data.stations) {
      this.formatStationOptions();
      this.formattedStationOptions.forEach((option) =>
        this.stationOptions.push(option),
      );
    }

    if (this.data.id) {
      this.vehicleStationForm.controls.id.patchValue(this.data.id);
    }
    if (this.data.vehicleId) {
      this.vehicleStationForm.controls.vehicleId.patchValue(
        this.data.vehicleId,
      );
    }
    if (this.data.stationId) {
      this.vehicleStationForm.controls.stationId.patchValue(
        this.data.stationId,
      );
    }
    if (this.data.vehicleNames) {
      this.vehicleStationForm.controls.vehicleNames.patchValue({
        generics: this.data.vehicleNames,
      });
    }
    if (this.data.stationNodes) {
      this.vehicleStationForm.controls.stationNodes.patchValue({
        generics: this.data.stationNodes,
      });
    }

    if (this.data.currentAssocList) {
      const assocList = this.flattenAndGenerateUniqueAssocList(
        this.data.currentAssocList,
      );
      this.uniqueAssocList = [...assocList];
      this.hashedAssocList = this.createHashAssocList(this.uniqueAssocList);
    }
  }

  skipValidationForEditingAssoc() {
    if (this.data.mode === 'edit') {
      this.data.currentAssocList = this.data.currentAssocList.filter(
        (assoc) => assoc.id !== this.data.id,
      );
    }
  }

  createHashAssocList(assocList): Array<string> {
    const hashList = [];
    assocList.forEach((assoc) => {
      const sortedVehicleNames =
        assoc.vehicleNames !== undefined
          ? assoc.vehicleNames.slice().sort()
          : [];
      const sortedStationNodes =
        assoc.stationNodes !== undefined
          ? assoc.stationNodes.slice().sort()
          : [];
      const entry = [
        assoc.vehicleId,
        assoc.stationId,
        sortedVehicleNames.join(),
        sortedStationNodes.join(),
      ];
      hashList.push(entry.join(';'));
    });
    return hashList;
  }

  generateUniqueAssocList(assocList): StationVehicleAssoc[] {
    const uniqueList = [];
    assocList.forEach((assoc) => {
      assoc.vehicleNames.forEach((vehicleName) => {
        assoc.stationNodes.forEach((node) => {
          uniqueList.push({
            vehicleId: assoc.vehicleId,
            stationId: assoc.stationId,
            vehicleNames: [vehicleName],
            stationNodes: [node],
          });
        });
      });
    });
    return uniqueList;
  }

  flattenAssocList(assocList): StationVehicleAssoc[] {
    const flattenAssocList = [];
    assocList.forEach((assoc) => {
      const tempVehicleNames = assoc.vehicleNames.includes('ALL')
        ? this.data.vehicleNameOptions
            .filter(
              (vehicleName) => assoc.vehicleId === vehicleName['vehicleId'],
            )
            .map((vehicleName) => vehicleName.name)
        : this.data.vehicleNameOptions
            .filter((vehicleName) =>
              assoc.vehicleNames.includes(vehicleName.value),
            )
            .map((vehicleName) => vehicleName.name);

      const tempStationNodes = assoc.stationNodes.includes('ALL')
        ? this.data.stations
            .filter((station) => assoc.stationId === station.value)
            .map((station) => station['nodes'])
        : this.data.stationNodeOptions
            .filter((node) => assoc.stationNodes.includes(node.value))
            .map((node) => node.value);

      const currentAssoc = {
        vehicleId: assoc.vehicleId,
        stationId: assoc.stationId,
        vehicleNames: tempVehicleNames,
        stationNodes:
          tempStationNodes[0] instanceof Array
            ? tempStationNodes[0]
            : tempStationNodes,
      };

      flattenAssocList.push(currentAssoc);
    });

    return flattenAssocList;
  }

  flattenAndGenerateUniqueAssocList(assocList): StationVehicleAssoc[] {
    return this.generateUniqueAssocList(this.flattenAssocList(assocList));
  }

  formatStationOptions() {
    this.formattedStationOptions = this.data.stations;
    this.formattedStationOptions.forEach((station) => {
      if (station.nodes.includes('ALL')) {
        station.nodes = this.data.stationNodeOptions.map((node) => node.value);
      }
    });
  }

  combinationValidator() {
    const { stationNodes, vehicleNames, vehicleId, stationId } =
      this.vehicleStationForm.controls;
    if (
      vehicleId.value === '' ||
      stationId.value === '' ||
      !stationNodes.value.generics ||
      !vehicleNames.value.generics
    ) {
      return;
    }

    const currentInput = this.flattenAndGenerateUniqueAssocList(
      new Array({
        vehicleId: vehicleId.value,
        stationId: stationId.value,
        vehicleNames: vehicleNames.value.generics,
        stationNodes: stationNodes.value.generics,
      }),
    );

    let hasErrors = false;

    this.createHashAssocList(currentInput).forEach((assoc) => {
      if (this.hashedAssocList.includes(assoc)) {
        hasErrors = true;
      }
    });

    if (hasErrors) {
      vehicleId.setErrors({ invalid: true });
      stationId.setErrors({ invalid: true });
      this.vehicleNameErrorMessage$.next(
        this.errorMessages.vehicleName !== undefined
          ? this.errorMessages.vehicleName.invalid
          : '',
      ); // error message to manually send to filterchip
      this.stationNodeErrorMessage$.next(
        this.errorMessages.stationNode !== undefined
          ? this.errorMessages.stationNode.invalid
          : '',
      ); // error message to manually send to filterchip
    } else {
      vehicleId.setErrors(null);
      stationId.setErrors(null);
      this.vehicleNameErrorMessage$.next(null);
      this.stationNodeErrorMessage$.next(null);
    }
  }

  getVehicleNamesAndFilterToUnique(vehicleId) {
    const filteredVehicleNames =
      this.data.vehicleNameOptions !== undefined
        ? this.data.vehicleNameOptions.filter(
            (vehicleName) => vehicleId === vehicleName['vehicleId'],
          )
        : [];
    const uniqueVehicles: Dictionary<string> = {};
    filteredVehicleNames.forEach((vehicle) => {
      if (
        !(vehicle.name in uniqueVehicles) ||
        this.data.vehicleNames.includes(vehicle.value)
      ) {
        uniqueVehicles[vehicle.name] = vehicle.value;
      }
    });
    const newVehicleNames = [];
    Object.values(uniqueVehicles).forEach((uniqueVehicle) => {
      filteredVehicleNames
        .filter((vehicle) => vehicle.value === uniqueVehicle)
        .map((element) => {
          newVehicleNames.push(element);
        });
    });
    this.vehicleNameOptions = newVehicleNames;
  }

  getFormValues() {
    const formValues = this.vehicleStationForm.getRawValue();
    return {
      ...formValues,
      vehicleNames: formValues['vehicleNames']['generics'],
      stationNodes: formValues['stationNodes']['generics'],
    };
  }

  onSaveAttempt(): void {
    this.patchStationNodesForSingleNode();
    this.combinationValidator();
    this.submitted$.next(true);
    this.detector.detectChanges();
  }

  onClose() {
    this.dialogRef.close();
  }

  setValidator() {
    combineLatest([
      this.vehicleStationForm.get('vehicleNames').valueChanges,
      this.vehicleStationForm.get('stationNodes').valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.combinationValidator();
        this.vehicleStationForm.controls.vehicleId.clearAsyncValidators();
        this.vehicleStationForm.controls.stationId.clearAsyncValidators();
        this.vehicleStationForm.controls.vehicleNames.clearAsyncValidators();
        this.vehicleStationForm.controls.stationNodes.clearAsyncValidators();
      });
  }

  getDispatchEquipmentIds(): Array<string> {
    const dispatchEquipmentIds = [];
    if (this.data && this.data.vehicleNameOptions) {
      const vehicleDispatchList = this.data.vehicleNameOptions;
      vehicleDispatchList.forEach((v) => {
        dispatchEquipmentIds.push(v['vehicleId']);
      });
    }
    const equipmentIds = [...new Set(dispatchEquipmentIds)];
    return equipmentIds;
  }

  private patchStationNodesForSingleNode(): void {
    if (!this.data.isMultiNode) {
      const generics = this.getDefaultGenericsValue();
      this.vehicleStationForm.controls.stationNodes.patchValue({ generics });
    }
  }

  private getDefaultGenericsValue(): string[] {
    return Coerce.toArray(this.data.stationNodeOptions)
      .map((option) => option.value)
      .splice(0, 1);
  }
}
