import { OperatingCost, OperatingCostDialogData } from 'prosumer-app/+scenario';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import {
  BaseFormComponent,
  ColumnDefinition,
  FormFieldOption,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { UntypedFormBuilder, NgControl } from '@angular/forms';

import { ManagedDataService } from 'prosumer-app/shared/services/managed-data';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { OperationalCostStore } from 'prosumer-app/stores/op-costs';
import { OperatingCostDialogComponent } from '../operating-cost-dialog';

@Component({
  selector: 'prosumer-operating-cost-table',
  templateUrl: './operating-cost-table.component.html',
  styleUrls: ['./operating-cost-table.component.scss'],
  providers: [ManagedDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OperatingCostTableComponent
  extends BaseFormComponent
  implements OnInit
{
  @Input() startYear: number;
  @Input() endYear: number;
  @Input() formType: string;
  @Input() set inputEnergyVectorsOptions(value: FormFieldOption<string>[]) {
    this.inputEnergyVectorsOptions$.next(value);
  }
  @Input() set outputEnergyVectorsOptions(value: FormFieldOption<string>[]) {
    this.outputEnergyVectorsOptions$.next(value);
  }
  @Input() projectId: string;
  @Input() caseId: string;
  @Input() scenarioId: string;

  costs: OperatingCost[] = [];

  private inputEnergyVectorsOptions$ = new BehaviorSubject<
    FormFieldOption<string>[]
  >([]);
  private outputEnergyVectorsOptions$ = new BehaviorSubject<
    FormFieldOption<string>[]
  >([]);

  columnsDef: ColumnDefinition = {
    parameterType: {
      name: 'Parameter Type',
      sortable: true,
    },
    energyVectorName: {
      name: 'Energy Vector',
      sortable: true,
    },
    actions: {
      name: 'Actions',
      type: 'action',
    },
  };

  get disableNewButton(): boolean {
    return [!this.hasEnergyVectors, this.mode !== 'edit'].some(Boolean);
  }

  private get hasEnergyVectors(): boolean {
    return [
      this.outputEnergyVectorsOptions$.value.length,
      this.inputEnergyVectorsOptions$.value.length,
    ].some(Boolean);
  }

  get dialogData(): OperatingCostDialogData {
    return {
      mode: 'add',
      width: '60%',
      disableClose: true,
      startYear: this.startYear,
      endYear: this.endYear,
      formType: this.formType,
      inputEnergyVectorsOptions: this.inputEnergyVectorsOptions$.value,
      outputEnergyVectorsOptions: this.outputEnergyVectorsOptions$.value,
      operatingCost: {
        parameterType: '',
        energyVectorId: '',
      },
      scenarioIdentity: {
        projectId: this.projectId,
        caseId: this.caseId,
        scenarioId: this.scenarioId,
      },
    };
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private dialog: DialogService,
    private readonly costStore: OperationalCostStore,
    private readonly notifs: NotificationsService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  writeValue(): void {
    // do nothing
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  defineForm() {
    return {
      operatingCostProfiles: [],
    };
  }

  initForm(operatingCost: OperatingCost[]): void {
    if (operatingCost) {
      this.controls.operatingCostProfiles.patchValue(operatingCost, {
        emitEvent: false,
      });
      const listData = operatingCost.map((data) => {
        const inputEnergyVectorName =
          this.inputEnergyVectorsOptions$?.value.filter(
            (i) => i.value === data.energyVectorId,
          )[0]?.name;
        const outputEnergyVectorName =
          this.outputEnergyVectorsOptions$?.value.filter(
            (i) => i.value === data.energyVectorId,
          )[0]?.name;
        return {
          ...data,
          energyVectorName: inputEnergyVectorName || outputEnergyVectorName,
        };
      });
    }
  }

  addNew() {
    this.dialog
      .openDialog(OperatingCostDialogComponent, {
        ...this.dialogData,
      })
      .subscribe((value) => {});
  }

  edit(data: any) {
    if (!data) {
      return;
    }

    this.costStore
      .getOperationalCost(data.id, data.equipmentId)
      .pipe(
        switchMap((operatingCost) =>
          this.dialog.openDialog(OperatingCostDialogComponent, {
            ...this.dialogData,
            ...data,
            mode: 'edit',
            operatingCost,
          }),
        ),
        take(1),
      )
      .subscribe((value) => {});
  }

  delete({ id, equipmentId }) {
    this.costStore
      .deleteOperationalCost(id, equipmentId)
      .pipe(take(1))
      .subscribe({
        error: (error) => {
          this.notifs.showError(error.error?.error ?? error.message);
        },
      });
  }
}
