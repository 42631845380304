import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';

import {
  COMPARE_EQUIPMENT_RESULTS_VALUE_OPTIONS,
  ENGIE_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  contains,
  fadeInAnimation,
  getKeys,
} from 'prosumer-app/libs/eyes-shared';
import {
  CompareEnergyBalanceResultItem,
  Scenario,
} from 'prosumer-scenario/models';
import { BarDatum } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';

const DEFAULT_BAR_WIDTH = 8;
const DEFAULT_OFFSET = 74.6;
const DEFAULT_HEIGHT_OFFSET = 100;
const DEFAULT_GROUP_PADDING = 5;
const DEFAULT_BAR_PADDING = 5;
const MINIMUM_WIDTH = 250;

/**
 * Component used for equipment comparison
 */
@Component({
  selector: 'prosumer-compare-energy-balance',
  templateUrl: './compare-energy-balance.component.html',
  styleUrls: ['./compare-energy-balance.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompareEnergyBalanceComponent
  extends BaseComponent
  implements OnInit
{
  groupPadding = DEFAULT_GROUP_PADDING;
  barPadding = DEFAULT_BAR_PADDING;
  xAxisLabel = 'Equipment';
  unit = 'kWh';
  consumptionYears = '';
  productionYears = '';

  equipmentValueOptions = COMPARE_EQUIPMENT_RESULTS_VALUE_OPTIONS;
  consumptionData = [];
  productionData = [];
  tooltipData = [];

  @Input() energyBalanceResults: Array<CompareEnergyBalanceResultItem>;
  @Input() scenarios: Array<Scenario>;

  @Input() chartScheme = ENGIE_DEFAULT_SCHEME;
  @Input() customColors = [];

  // For chartjs colors
  @Input() caseId: string;
  colors$: Observable<PerceptionMap>;

  // Node options in the input chips to filter the equipment results
  @Input() nodeOptions: Array<any>;

  // Type options in the input chips to filter the equipment results
  @Input() yearOptions: Array<any>;

  // Energy vector options in the input chips to filter the equipment results in the charts
  @Input() energyVectorOptions: Array<any>;

  // Value options in the button toggle to show what equipment result value to show in the charts
  @Input() valueOptions: Array<any> = getKeys(this.equipmentValueOptions).map(
    (key) => ({
      key,
      value: this.equipmentValueOptions[key].label,
      context: this.equipmentValueOptions[key].context,
    }),
  );

  nodeFilters$ = new BehaviorSubject(null);
  yearFilters$ = new BehaviorSubject(null);
  energyVectorFilters$ = new BehaviorSubject(null);

  /**
   * Filters the equipment results whenever the value toggle, node, type, and energy vector filters change;
   * Also calculates the chart height on change
   */
  consumptionData$ = combineLatest([
    this.nodeFilters$,
    this.yearFilters$,
    this.energyVectorFilters$,
  ]).pipe(
    debounceTime(400),
    distinctUntilChanged(),
    switchMap(([nodes, years, energyVectors]) =>
      of(this.filterConsumption(nodes, years, energyVectors)),
    ),
    this.takeUntilShare(),
  );

  productionData$ = combineLatest([
    this.nodeFilters$,
    this.yearFilters$,
    this.energyVectorFilters$,
  ]).pipe(
    debounceTime(400),
    distinctUntilChanged(),
    switchMap(([nodes, years, energyVectors]) =>
      of(this.filterProduction(nodes, years, energyVectors)),
    ),
    this.takeUntilShare(),
  );

  showConsumptionChart$ = this.consumptionData$.pipe(
    filter((data) => !!data && data.data.length > 0),
    this.takeUntilShare(),
  );

  showProductionChart$ = this.productionData$.pipe(
    filter((data) => !!data && data.data.length > 0),
    this.takeUntilShare(),
  );

  constructor(private perception: ResultsPerceptionService) {
    super();
  }

  ngOnInit(): void {
    this.colors$ = this.perception?.getPerceptionMapStream(this.caseId);
  }

  filterProduction(nodes, years, energyVectors) {
    const results = this.filterEquipmentResults(this.energyBalanceResults, {
      nodes,
      years,
      energyVectors,
    });
    this.productionYears = [...new Set(results.map((r) => r.year))]
      .sort()
      .join(',');
    this.productionData = this.mapToChartData(
      this.scenarios,
      results,
      'production',
    );
    const chartName = 'Production ' + this.productionYears;
    const chartJsData = this.mapToChartJsChart(
      results,
      this.productionData,
      'production',
      chartName,
    );
    return chartJsData;
  }

  filterConsumption(nodes, years, energyVectors) {
    const results = this.filterEquipmentResults(this.energyBalanceResults, {
      nodes,
      years,
      energyVectors,
    });
    this.consumptionYears = [...new Set(results.map((r) => r.year))]
      .sort()
      .join(',');
    this.consumptionData = this.mapToChartData(
      this.scenarios,
      results,
      'consumption',
    );
    const chartName = 'Consumption ' + this.productionYears;
    const chartJsData = this.mapToChartJsChart(
      results,
      this.consumptionData,
      'consumption',
      chartName,
    );
    return chartJsData;
  }

  /**
   * Maps the equipment results, scenarios, and values to the chart-compatible data
   *
   * @param scenarios - the list of scenarios for comparison
   * @param equipmentResults - the flattened equipment results
   * @param valueField - the field of the result to be mapped as the value
   */
  mapToChartData(
    scenarios: Array<Scenario>,
    equipmentResults: Array<CompareEnergyBalanceResultItem>,
    type: string,
  ) {
    const equipmentNames = Array.from(
      new Set(
        equipmentResults
          .filter((item) => item.type === type)
          .map((equipment) => equipment.equipmentName),
      ),
    );
    const res = equipmentNames.map((name) => ({
      name,
      series: scenarios.map((scenario) => ({
        name: scenario.name,
        value: equipmentResults
          .filter(
            (result) =>
              result.equipmentName === name &&
              result.name === scenario.name &&
              result.type === type,
          )
          .reduce((total, next) => total + next['value'], 0),
      })),
    }));
    return res.filter((value) =>
      value.series.some((seriesItem) => seriesItem.value !== 0),
    );
  }

  mapToChartJsChart(
    equipmentResults: Array<CompareEnergyBalanceResultItem>,
    ngRxChartData: Array<any>,
    type: string,
    chartName: string,
  ) {
    const equipmentNames = Array.from(
      new Set(
        equipmentResults
          .filter((item) => item.type === type)
          .map((equipment) => equipment.equipmentName),
      ),
    );
    const chartData = this.buildChartJsData(ngRxChartData);

    return {
      name: chartName,
      axisTicks: equipmentNames,
      data: chartData,
      xAxisName: 'Equipment',
      yAxisName: 'kWh',
    };
  }

  buildChartJsData(ngRxChartData: Array<any>): BarDatum[] {
    const dataPerScenario = {};
    ngRxChartData.forEach((element) => {
      element.series.forEach((value) => {
        const equimentValues = {};
        equimentValues[element.name] = value.value;
        dataPerScenario[value.name] = {
          ...dataPerScenario[value.name],
          ...equimentValues,
        };
      });
    });

    const chartJsData = [];
    Object.entries(dataPerScenario).forEach(([key, value]) =>
      chartJsData.push({
        name: key,
        group: key,
        values: value,
      }),
    );
    return chartJsData;
  }

  /**
   * Filters the equipment results using the parameters
   *
   * @param results - the flattened equipment results
   * @param params - the filters for the results such as nodes, types, and energy vectors
   */
  filterEquipmentResults(
    results: Array<CompareEnergyBalanceResultItem> = this.energyBalanceResults,
    params?: {
      nodes?: Array<string>;
      years?: Array<string>;
      energyVectors?: Array<string>;
    },
  ) {
    return results.filter(
      (filteredResult) =>
        (!!params && !!params.nodes && params.nodes.length > 0
          ? contains(params.nodes, filteredResult.node)
          : true) &&
        (!!params && !!params.years && params.years.length > 0
          ? contains(params.years, filteredResult.year)
          : true) &&
        (!!params && !!params.energyVectors && params.energyVectors.length > 0
          ? contains(params.energyVectors, filteredResult.energyVector)
          : true),
    );
  }

  /**
   * Calculates the height of the chart to show the bars properly
   *
   * @param scenarioLength - the length of the scenario
   * @param resultsLength - the length of the equipment results
   * @param barWidth - a constant expected bar width
   * @param offset - a fixed constant to add in the calculated width
   * @param groupPadding - a constant used to add space between groups
   * @param barPadding - a constant used to add space between bars
   */
  calculateWidth(
    scenarioLength: number,
    resultsLength: number,
    barWidth = DEFAULT_BAR_WIDTH,
    offset = DEFAULT_OFFSET,
    groupPadding = DEFAULT_GROUP_PADDING,
    barPadding = DEFAULT_BAR_PADDING,
  ) {
    const width =
      ((barWidth + barPadding) * scenarioLength + groupPadding) *
        resultsLength +
      offset;
    return width > MINIMUM_WIDTH ? width : MINIMUM_WIDTH;
  }

  onFilterNode(value: Array<string>) {
    this.nodeFilters$.next(value);
  }

  onFilterYear(value: Array<string>) {
    this.yearFilters$.next(value);
  }

  onFilterEnergyVector(value: Array<string>) {
    this.energyVectorFilters$.next(value);
  }

  getConsumptionSeriesMembers(seriesModel: any) {
    this.consumptionData.forEach((seriesObject) => {
      if (seriesObject['name'] === seriesModel.value.series) {
        this.tooltipData = seriesObject['series'];
        return;
      }
    });
  }

  getProductionSeriesMembers(seriesModel: any) {
    this.productionData.forEach((seriesObject) => {
      if (seriesObject['name'] === seriesModel.value.series) {
        this.tooltipData = seriesObject['series'];
        return;
      }
    });
  }
}
