import { Component, OnInit } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { randomIntFromInterval } from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject } from 'rxjs';
import { ChartJSChart } from '../chartjs.base';
import { CustomLegend } from '../custom-legend/custom-legend.model';
import { PieMeta } from './pie-chartjs.model';

@Component({
  selector: 'prosumer-pie-chartjs',
  templateUrl: './pie-chartjs.component.html',
  styleUrls: ['./pie-chartjs.component.scss'],
  standalone: false,
})
export class PieChartjsComponent
  extends ChartJSChart<PieMeta>
  implements OnInit
{
  private legendDataSubject = new BehaviorSubject<CustomLegend>(undefined);

  get legendDetails() {
    return this.legendDataSubject.getValue();
  }

  ngOnInit(): void {
    this.isCustomLegend = true;
  }

  initializeCustomLegend(pieChart): void {
    this.legendDataSubject.next({
      legendItems:
        pieChart.options.plugins.legend.labels.generateLabels(pieChart),
      canvasId: pieChart.canvas.id,
    });
  }

  getDemoData(): PieMeta {
    const SLICES = ['Slice 1', 'Slice 2', 'Slice 3'];
    return {
      slices: SLICES.map((name) => ({
        name,
        value: randomIntFromInterval(100, 1000),
      })),
    };
  }
  getDefaultID(): string {
    return 'pieChartJS';
  }
  getChartType(): ChartType {
    return 'pie';
  }
  mapToChartJSData(data: PieMeta): ChartData {
    return {
      labels: this.extractNames(data),
      datasets: [
        {
          data: this.extractValues(data),
          backgroundColor: this.extractColors(data),
          hoverOffset: 4,
        },
      ],
    };
  }
  getAdditionalChartOptions(data: PieMeta): ChartOptions {
    return {};
  }

  private extractNames(datqa: PieMeta): string[] {
    return datqa.slices.map((slice) => slice.name);
  }

  private extractValues(data: PieMeta): number[] {
    return data.slices.map((slice) => slice.value);
  }

  private extractColors(data: PieMeta): string[] {
    return this.extractNames(data).map((_, idx) =>
      super.resolveColorByIdx(idx),
    );
  }

  onLegendDataToggle({ event, index }) {
    if (!event.currentTarget.classList.contains('hidden-chart-legend')) {
      this.chartInstance.tooltip.setActiveElements([
        { datasetIndex: 0, index },
      ]);
    } else {
      this.chartInstance.tooltip.setActiveElements([]);
    }
    this.chartInstance.toggleDataVisibility(index);
    this.chartInstance.update();
  }

  onMouseEnter({ event, index }) {
    if (!event.currentTarget.classList.contains('hidden-chart-legend')) {
      this.chartInstance.tooltip.setActiveElements([
        { datasetIndex: 0, index },
      ]);
      this.chartInstance.update();
    }
  }

  onMouseLeave() {
    this.chartInstance.tooltip.setActiveElements([]);
    this.chartInstance.update();
  }
}
