import { PipeUtils } from 'prosumer-core/utils/utils';
import { StackedAreaData } from 'prosumer-shared/modules/chartjs/stacked-area-chartjs';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception/results-perception.model';
import {
  ResultsVisualizationType,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer/results-visualizer.model';

@Component({
  selector: 'prosumer-bar-and-area-results',
  templateUrl: './bar-and-area-results.component.html',
  styleUrls: ['./bar-and-area-results.component.scss'],
  standalone: false,
})
export class BarAndAreaResultsComponent implements OnInit {
  @Input() colorMap: PerceptionMap;
  @Input() type: ResultsVisualizationType;
  @Input() yAxisLabel: string;
  @Input() chartName: string;
  @Input() xAxisLabel = 'Years';
  @Input() scenarioName = 'scenario';
  @Input() resultsName = 'results';

  @Input() set incremental(incremental: VisualizerData[]) {
    this.incrementalSubject.next(incremental || []);
  }
  @Input() set cumulative(cumulative: VisualizerData[]) {
    this.cumulativeSubject.next(cumulative || []);
  }
  @Output() legendSelect = new EventEmitter<string>();

  private incrementalSubject = new BehaviorSubject<VisualizerData[]>([]);
  private cumulativeSubject = new BehaviorSubject<VisualizerData[]>([]);

  incremental$: Observable<VisualizerData[]>;
  cumulative$: Observable<VisualizerData[]>;
  chartJSData$: Observable<StackedAreaData>;
  barChartJSData$: Observable<StackedBarMeta>;

  constructor() {}
  ngOnInit(): void {
    this.incremental$ = this.incrementalSubject.asObservable();
    this.cumulative$ = this.cumulativeSubject.asObservable();
    this.chartJSData$ = this.getChartJSDataStream();
    this.barChartJSData$ = this.getStackedBarChartJSStream();
  }

  private getChartJSDataStream(): Observable<StackedAreaData> {
    return this.cumulative$.pipe(
      PipeUtils.filterOutEmpty,
      map((data) => this.mapToChartJSData(data)),
    );
  }

  private getStackedBarChartJSStream(): Observable<StackedBarMeta> {
    return this.incremental$.pipe(
      PipeUtils.filterOutEmpty,
      map((data) => this.mapToStackedBarChartJS(data)),
    );
  }

  private mapToChartJSData(data: VisualizerData[]): StackedAreaData {
    const years = data[0].series.map((siri) => siri.name);
    return {
      xAxisTicks: years,
      stacks: data.map((datum) => ({
        label: datum.name,
        points: datum.series.map((siri) => siri.value),
      })),
      yAxisLabel: this.yAxisLabel,
      xAxisLabel: this.xAxisLabel,
      name: this.chartName,
    };
  }

  private mapToStackedBarChartJS(data: VisualizerData[]): StackedBarMeta {
    return {
      name: this.chartName,
      axisTicks: data[0].series.map((d) => d.name),
      data: data.map((d) => ({
        name: d.name,
        values: d.series.reduce((acc, curr) => {
          acc[curr.name] = curr.value;
          return acc;
        }, {}),
      })),
      yAxisName: this.yAxisLabel,
      xAxisName: this.xAxisLabel,
    };
  }
}
