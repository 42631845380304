import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EnergyGridConnectionInfo,
  EnergyGridConnectionQuery,
} from 'prosumer-app/stores/energy-grid-connection';

@UntilDestroy()
@Directive({
  selector: '[prosumerEgcLister]',
  standalone: false,
})
export class EgcListerDirective implements OnInit {
  @Output() egcsChange = new EventEmitter<EnergyGridConnectionInfo[]>();

  constructor(private readonly egcs: EnergyGridConnectionQuery) {}

  ngOnInit(): void {
    this.subscribeToActiveEgcsForPropagation();
  }

  private subscribeToActiveEgcsForPropagation(): void {
    this.egcs
      .selectActiveEGCs()
      .pipe(untilDestroyed(this))
      .subscribe((egcs) => this.egcsChange.emit(egcs));
  }
}
