import { Utils } from 'prosumer-app/core/utils';
import {
  Duplicable,
  DuplicateDetailData,
  ScenarioDetailType,
} from 'prosumer-app/stores/scenario-detail';
import {
  ScenarioVariationQuery,
  ScenarioVariationStore,
} from 'prosumer-app/stores/scenario-variation';
import { NameValidator } from 'prosumer-shared/validators';
import { DuplicationValidators } from 'prosumer-shared/validators/duplication';
import { Observable, of } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-details-duplicate-dialog',
  templateUrl: './scenario-details-duplicate-dialog.component.html',
  styleUrls: ['./scenario-details-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ScenarioDetailsDuplicateDialogComponent implements OnInit {
  @Input() titleType: string;
  @Input() loading = false;
  @Output() duplicate = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();

  duplicateScenarioDetailForm: UntypedFormGroup = this.initForm();
  scenarioVariationOptions$: Observable<Array<any>> = of([]);
  readonly variationLoading$: Observable<boolean> =
    this.variationQuery.selectLoading();
  readonly isInvalid$: Observable<boolean> = this.selectIsInvalid();
  // readonly errorMessages: FormFieldErrorMessageMap = {
  //   name: {
  //     invalid: this.translate.instant(
  //       'Scenario.messages.duplicate.name.invalid',
  //     ),
  //     required: this.translate.instant(
  //       'Scenario.messages.duplicate.name.required',
  //     ),
  //     maxLength: this.translate.instant(
  //       'Scenario.messages.duplicate.name.maxLength',
  //     ),
  //     whiteSpaces: this.translate.instant(
  //       'Scenario.messages.duplicate.name.whiteSpaces',
  //     ),
  //     invalidCharacter: this.translate.instant(
  //       'Scenario.messages.duplicate.name.invalidCharacter',
  //     ),
  //   },
  // };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DuplicateDetailData,
    private formBuilder: UntypedFormBuilder,
    private variationQuery: ScenarioVariationQuery,
    private variationStore: ScenarioVariationStore,
    // private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.initOptions();
    this.setNameAndVariationValidator();
    this.subscribeToFormValidityForManualErrorSetting();
    this.subscribeToFormFirstChangeToShowErrorFirstTime();
  }

  onSubmit(): void {
    this.duplicate.emit(this.duplicateScenarioDetailForm.getRawValue());
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private subscribeToFormValidityForManualErrorSetting(): void {
    this.selectFormValue()
      .pipe(
        untilDestroyed(this),
        filter((value) =>
          [
            !DuplicationValidators.isValid(value, this.getExistingFromData()),
            !this.duplicateScenarioDetailForm.controls.name.errors,
          ].every(Boolean),
        ),
      )
      .subscribe(() => {
        this.duplicateScenarioDetailForm.controls.name.setErrors({
          invalid: true,
        });
      });
  }

  private selectIsInvalid(): Observable<boolean> {
    return this.selectFormValue().pipe(
      map(() => this.duplicateScenarioDetailForm.invalid),
    );
  }

  private selectFormValue(): Observable<Duplicable> {
    return this.duplicateScenarioDetailForm.valueChanges.pipe(
      startWith(this.duplicateScenarioDetailForm.value),
    );
  }

  private initOptions() {
    this.variationStore
      .getVariations({ dataType: ScenarioDetailType.variation })
      .pipe(take(1))
      .subscribe();
    this.scenarioVariationOptions$ = this.variationQuery
      .selectActiveVariation()
      .pipe(untilDestroyed(this));
  }

  private initForm(): UntypedFormGroup {
    return this.formBuilder.group({
      name: [
        this.buildSuggestedName(),
        [Validators.required, NameValidator.validWithCore()],
      ],
      scenarioVariation: ['basecase'],
    });
  }

  private buildSuggestedName(): string {
    return `${this.getOriginName()} copy`;
  }

  private getOriginName(): string {
    return Utils.resolveToEmptyObject(this.data.origin).name;
  }

  private setNameAndVariationValidator(): void {
    this.duplicateScenarioDetailForm.setValidators(
      this.createNameAndVarValidator(),
    );
  }

  private createNameAndVarValidator(): ValidatorFn {
    return DuplicationValidators.uniqueNameAndVariation(
      this.getExistingFromData(),
    );
  }

  private getExistingFromData(): Duplicable[] {
    return Utils.resolveToEmptyArray(this.data.existing);
  }

  private subscribeToFormFirstChangeToShowErrorFirstTime() {
    this.duplicateScenarioDetailForm.valueChanges
      .pipe(take(1))
      .subscribe((_) => {
        this.duplicateScenarioDetailForm.get('name').markAsTouched();
      });
  }
}
