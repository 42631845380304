import { Component } from '@angular/core';
import { EmissionsResultService } from '@prosumer/results/components/emissions-results';
import { EmissionsData } from '@prosumer/results/models';
import { ComparisonController } from '../base';
import { COMP_VIZ_SERVICE } from '../comparison-visualizer';
import { EmissionComparisonService } from './emission-comparison.service';

@Component({
  selector: 'prosumer-emission-comparison',
  templateUrl: './emission-comparison.component.html',
  styleUrls: ['./emission-comparison.component.scss'],
  providers: [
    { provide: COMP_VIZ_SERVICE, useClass: EmissionComparisonService },
    EmissionsResultService,
  ],
  standalone: false,
})
export class EmissionComparisonComponent extends ComparisonController<EmissionsData> {}
