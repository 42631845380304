import { RouterStore } from 'prosumer-libs/eyes-core';
import { BaseComponent } from 'prosumer-libs/eyes-shared';
import { Project } from 'prosumer-project/models';
import { ProjectFacadeService } from 'prosumer-project/state';
import { filter, map, take } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'prosumer-project-create-page',
  templateUrl: './project-create-page.component.html',
  styleUrls: ['./project-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectCreatePageComponent extends BaseComponent {
  mode$ = this._route.data.pipe(
    take(1),
    filter((data) => data !== undefined),
    map((data) => data.mode),
    this.takeUntilShare(),
  );

  creating$ = this._projectFacade.creating$.pipe(this.takeUntilShare());

  constructor(
    private _route: ActivatedRoute,
    private _projectFacade: ProjectFacadeService,
    private _routerStore: RouterStore,
  ) {
    super();
  }

  onCreate(project: Project): void {
    this._projectFacade.create(project);
  }

  onCancel(): void {
    this._routerStore.back();
  }
}
