import moment from 'moment';
import {
  MONTH_FORMAT,
  MONTH_FULL_FORMAT,
  MONTHLY_DAYS,
} from 'prosumer-app/app.references';
import {
  createArrayFromNumber,
  getKeys,
  toTitleCase,
} from 'prosumer-app/libs/eyes-shared';
import { PipeUtils } from 'prosumer-core/utils';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable } from 'rxjs';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { StorageDispatchComparison } from '../adapters/storage-dispatch.adapter';
import {
  CompareDispatchChartData,
  CompareDispatchData,
} from '../compare-dispatch.model';
import { DaysInMonth } from './monthly.model';

@Component({
  selector: 'prosumer-compare-dispatch-monthly',
  templateUrl: './monthly.component.html',
  styleUrls: ['./monthly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompareDispatchMonthlyComponent
  extends StorageDispatchComparison
  implements OnInit
{
  chartDataSubject = new BehaviorSubject<VisualizerData[]>([]);
  @Input() calculated = false;
  @Input() customColors: any;

  _year: string;
  @Input() set year(year: string) {
    this._year = year;
  }

  monthlyData: CompareDispatchData;
  daysInMonth: Array<DaysInMonth>;
  chartData: Array<any>;
  @Input() set data(monthlyData: CompareDispatchData) {
    this.monthlyData = monthlyData;
    this.daysInMonth = this.generateDaysInMonth(this._year);
    if (!!this.monthlyData && !!this.daysInMonth) {
      this.chartData = this.mapToChartData(this.monthlyData);
      this.chartDataSubject.next(this.chartData);
    }
  }

  constructor(perception: ResultsPerceptionService) {
    super(perception);
  }

  ngOnInit(): void {
    this.initializeChartDataAndColors();
  }

  getLegendNames(data: VisualizerData[]): string[] {
    return data === undefined ? [] : data[0].series.map((siri) => siri.name);
  }

  /**
   * Maps the compare monthly data to a chart compatible data
   *
   * @param monthlyData - the monthly data
   */
  mapToChartData(
    monthlyData: CompareDispatchData,
    daysInMonth = this.daysInMonth,
  ): Array<CompareDispatchChartData> {
    let startHour = 0;
    return daysInMonth.map((month) => {
      const endHour = startHour + month.value * 24;
      const monthData = {
        name: month.name,
        series: monthlyData.dataList.map((data) => ({
          name: data.equipment,
          value: data.values
            .slice(startHour, endHour)
            .reduce((prev, next) => prev + next, 0),
        })),
      };
      startHour = endHour;
      return monthData;
    });
  }

  /**
   * Generates days in months array
   *
   * @param year - the year used for calculating the leap-year
   * @param calculated - true if leap-year will be calculated
   */
  generateDaysInMonth(
    year: string = this.year,
    calculated = this.calculated,
  ): Array<DaysInMonth> {
    if (calculated && !!year) {
      return createArrayFromNumber(12).map((_, index) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const month = moment({
          y: +coerceNumberProperty(year),
          M: index,
          d: 1,
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        });
        return {
          index,
          name: month.format(MONTH_FULL_FORMAT),
          short: month.format(MONTH_FORMAT),
          value: month.daysInMonth(),
        };
      });
    }
    return getKeys(MONTHLY_DAYS).map((key, index) => ({
      index,
      name: toTitleCase(key),
      short: toTitleCase(key).substring(0, 3),
      value: MONTHLY_DAYS[key],
    }));
  }

  formatTick(tick: string): string {
    return tick;
  }
  getChartName(): string {
    return `Monthly Dispatch (${this.monthlyData.name})`;
  }
  getNgxChartsDataStream(): Observable<VisualizerData[]> {
    return this.chartDataSubject.asObservable().pipe(PipeUtils.filterOutEmpty);
  }
  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return { ...data, xAxisName: 'Months', yAxisName: 'kWh' };
  }
}
