import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MetricPrefix } from 'prosumer-shared/pipes';

@Component({
  selector: 'prosumer-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValueComponent {
  @Input() value = 0;
  @Input() unit = '';
  @Input() prefix: MetricPrefix;

  shouldShowUnit(): boolean {
    return ![this.isValueUndefined(), this.isValueNull()].some(Boolean);
  }

  private isValueUndefined(): boolean {
    return this.value === undefined;
  }

  private isValueNull(): boolean {
    return this.value === null;
  }
}
