import { ComparisonData } from 'prosumer-app/comparison/base/comparison-models';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VisualizerComponent } from '@prosumer/results/components/results-visualizer';

import { ComparisonVisualizerService } from './comparison-visualizer.service';
import { COMP_VIZ_SERVICE } from './comparison-visualizer.tokens';

@Component({
  selector: 'prosumer-comparison-visualizer',
  templateUrl: './comparison-visualizer.component.html',
  styleUrls: ['./comparison-visualizer.component.scss'],
  standalone: false,
})
export class ComparisonVisualizerComponent<T extends ComparisonData>
  extends VisualizerComponent
  implements OnInit
{
  allYears$: Observable<number[]>;
  isOverall$ = new BehaviorSubject<boolean>(false);
  activeYearControl = new UntypedFormControl();

  constructor(
    @Inject(COMP_VIZ_SERVICE) private service: ComparisonVisualizerService<T>,
    private translate: TranslateService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.allYears$ = this.service.getAllYearsStream();

    this.subscribeToOverallSelection();
    this.subscribeToActiveYearForDataFiltering();
    this.subscribeToAllYearsForActiveYearControlPatching();
  }

  private subscribeToOverallSelection(): void {
    this.service.isOverallSubject$
      .pipe(distinctUntilChanged(), takeUntil(this.justDestroyed$))
      .subscribe((isOverall) => this.isOverall$.next(isOverall));
  }

  private subscribeToActiveYearForDataFiltering(): void {
    this.activeYearControl.valueChanges
      .pipe(takeUntil(this.justDestroyed$))
      .subscribe((year) => this.service.setActiveYear(year));
  }

  private subscribeToAllYearsForActiveYearControlPatching(): void {
    combineLatest([this.allYears$, this.isOverall$])
      .pipe(takeUntil(this.justDestroyed$))
      .subscribe(([years, isOverall]) => {
        if (isOverall) {
          this.activeYearControl.patchValue(
            this.translate.instant('Result.labels.all'),
          );
          this.activeYearControl.disable();
        } else {
          this.activeYearControl.enable();
          this.activeYearControl.patchValue(years[0]);
        }
      });
  }
}
