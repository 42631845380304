import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CaseApiService } from '../+case/services/case-api.service';
import { CaseFacadeService } from '../+case/state/case-facade.service';
import { ProjectApiService } from './services/project-api.service';
import {
  ProjectEffects,
  ProjectFacadeService,
  projectFeature,
  projectReducer,
} from './state';

@NgModule({
  imports: [
    StoreModule.forFeature(projectFeature, projectReducer),
    EffectsModule.forFeature([ProjectEffects]),
  ],
  providers: [
    ProjectApiService,
    ProjectFacadeService,
    CaseApiService,
    CaseFacadeService,
  ],
})
export class ProjectRootModule {}
