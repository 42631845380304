import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SeriesTooltip } from './series-tooltip.model';

@Component({
  selector: 'prosumer-series-tooltip',
  templateUrl: './series-tooltip.component.html',
  styleUrls: ['./series-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SeriesTooltipComponent {
  constructor() {}

  @Input() currentSeriesData: SeriesTooltip;
  @Input() seriesDataList: Array<SeriesTooltip>;
  @Input() unit: string;
  @Input() format = '1.2-2';
  @Input() hideTotal: boolean;

  @Input() titleFormat = (obj: any) => (obj || {}).value;

  calculateTotal(seriesData: Array<any>): number {
    if (!seriesData) {
      return 0;
    }
    return seriesData
      .map((series) => series.value)
      .reduce((previousValue, currentValue) => previousValue + currentValue);
  }
}
