import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  JsonKeyToLabelPipe,
  MetricPipe,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'prosumer-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe, MetricPipe],
  standalone: false,
})
export class CostsComponent extends BaseComponent implements OnInit {
  constructor(
    private _resultStore: ResultStore,
    private _logger: LoggerService,
    private _jsonKeyToLabelPipe: JsonKeyToLabelPipe,
    private _decimalPipe: CurrencyPipe,
    private _metric: MetricPipe,
  ) {
    super();
  }

  @Input() view = [275, 150];

  @Input() colorSchemeTotalDiscount = {
    domain: ['#005288'],
  };

  @Input() colorSchemeCapex = {
    domain: ['#D57700'],
  };

  @Input() colorSchemeOpex = {
    domain: ['#007873'],
  };

  @Input() colorSchemeCo2Emissions = {
    domain: ['#785222'],
  };

  results: any;
  totalDiscount: any;
  opex: any;
  capex: any;
  co2Emissions: any;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CHART_ID = 'costs';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CO2_EMISSIONS_UNIT = 'kgCO2';
  @Input() labelFormatting = (obj: any) =>
    this._jsonKeyToLabelPipe.transform(obj.data.name);
  @Input() valueFormatting = (obj: any) =>
    `${this._metric.transform(obj.value, 0)} kEUR`;
  @Input() co2EmissionsFormatting = (obj: any) =>
    this._decimalPipe.transform(obj.value, '', '', '1.0-0') +
    ` ${this.CO2_EMISSIONS_UNIT}`;
  ngOnInit() {
    const cache$ = this._resultStore.chartData$(this.CHART_ID);
    combineLatest([
      this._resultStore.getCosts$.pipe(take(1)),
      cache$.pipe(take(1)),
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([fresh, cached]) => {
        this.results = cached || fresh;
        if (this.results) {
          this.totalDiscount = this.findFigure('total_cost', this.results);
          this.capex = this.findFigure('global_capex', this.results);
          this.opex = this.findFigure('final_opex', this.results);
          this.co2Emissions = this.findFigure(
            'final_co2_emissions',
            this.results,
          );
        }
        if (!cached) {
          this._resultStore.updateChartDataMapping(this.CHART_ID, fresh);
        }
      });
  }
  onSelect = (event) => {
    this._logger.info(event);
  };

  findFigure = (id: string, results: Array<any>) => [
    results.find((result) => result.id === id),
  ];
}
