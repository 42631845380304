import { PipeUtils, Utils } from 'prosumer-core/utils';
import { Observable } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';
import { VisualizerData } from '@prosumer/results/components/results-visualizer';

import { TypedSizingAndCostVisualizerData } from './main-output-results.model';
import { MainOutputResultsService } from './main-output-results.service';

@Component({
  selector: 'prosumer-main-output-results',
  templateUrl: './main-output-results.component.html',
  styleUrls: ['./main-output-results.component.scss'],
  providers: [MainOutputResultsService],
  standalone: false,
})
export class MainOutputResultsComponent implements OnInit {
  colors$: Observable<PerceptionMap>;

  @Input() caseId: string;
  @Input() scenarioName: string;

  constructor(
    public results: MainOutputResultsService,
    private perception: ResultsPerceptionService,
  ) {}

  ngOnInit(): void {
    this.colors$ = this.perception.getPerceptionMapStream(this.caseId);

    this.results.getMainOutput();
    this.registerDiscountedCostNamesToPerception();
    this.resgiterSizingAndCostsNamesToPerception();
    this.registerYearlyTotalEmissionsNamesToPerception();
  }

  private registerDiscountedCostNamesToPerception(): void {
    this.startRegistrationToPerception(
      this.results.discountedCost$,
      this.extractDiscountedNames.bind(this),
    );
  }

  private extractDiscountedNames(data: VisualizerData[]): string[] {
    return data.map((d) => d.name);
  }

  private resgiterSizingAndCostsNamesToPerception(): void {
    this.startRegistrationToPerception(
      this.results.sizingAndCostData$,
      this.extractSAndCNames.bind(this),
    );
  }

  private extractSAndCNames(
    data: TypedSizingAndCostVisualizerData[],
  ): string[] {
    const sAndCNames = Object.values(data[0].value).reduce(
      (acc, curr) => [...acc, ...curr.map((c) => c?.name)],
      [],
    );
    return Utils.removeDuplicates(sAndCNames);
  }

  private registerYearlyTotalEmissionsNamesToPerception(): void {
    this.startRegistrationToPerception(
      this.results.yearlyTotalEmissions$,
      this.extractYearlyNames.bind(this),
    );
  }

  private extractYearlyNames(data: VisualizerData[]): string[] {
    return data[0].series.map((siri) => siri.name);
  }

  private startRegistrationToPerception(
    source$: Observable<unknown[]>,
    extractFn: (d: unknown[]) => string[],
  ): void {
    source$
      .pipe(this.takeFirstTruthyEmission.bind(this))
      .subscribe((data: unknown[]) =>
        this.registerToPerception(extractFn(data)),
      );
  }

  private takeFirstTruthyEmission($: Observable<unknown>): Observable<unknown> {
    return $.pipe(debounceTime(100), PipeUtils.filterOutEmpty, take(1));
  }

  private registerToPerception(names: string[]): void {
    this.perception.registerLegendNames(this.caseId, names);
  }
}
