import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnergyVectorQuery } from 'prosumer-app/stores/energy-vector';
import {
  OperationalCostInfo,
  OperationalCostQuery,
} from 'prosumer-app/stores/op-costs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[prosumerOpCostsLister]',
  standalone: false,
})
export class OpCostsListerDirective implements OnInit {
  @Output() opCostsChange = new EventEmitter<OperationalCostInfo[]>();

  constructor(
    private readonly operationalCosts: OperationalCostQuery,
    private readonly vectors: EnergyVectorQuery,
  ) {}

  ngOnInit(): void {
    this.subscribeToActiveCostsForPropagation();
  }

  private subscribeToActiveCostsForPropagation(): void {
    this.operationalCosts
      .selectActiveOnes()
      .pipe(
        map((opCosts) => this.injectVectorNames(opCosts)),
        untilDestroyed(this),
      )
      .subscribe((opCosts) => this.opCostsChange.emit(opCosts));
  }

  private injectVectorNames(
    opCosts: OperationalCostInfo[],
  ): OperationalCostInfo[] {
    return opCosts.map((opCost) => this.injectVectorName(opCost));
  }

  private injectVectorName(opCost: OperationalCostInfo): OperationalCostInfo {
    return {
      ...opCost,
      energyVectorName: this.vectors.translate(opCost.energyVectorId),
    };
  }
}
