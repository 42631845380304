import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomOptionsFormDialogData } from 'prosumer-app/+scenario';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
} from 'prosumer-app/libs/eyes-shared';
import { provideUpserter } from 'prosumer-app/stores';
import { OptimizationCustomOptionStore } from 'prosumer-app/stores/custom-option';

@Component({
  selector: 'prosumer-custom-options-form-dialog',
  templateUrl: './custom-options-form-dialog.component.html',
  styleUrls: ['./custom-options-form-dialog.component.scss'],
  providers: [provideUpserter(OptimizationCustomOptionStore)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomOptionsFormDialogComponent
  extends BaseComponent
  implements OnInit
{
  isViewOnly: boolean;

  customOptionsForm = this.formBuilder.group({
    name: ['', Validators.required],
    value: ['', Validators.required],
  });

  errormessages: FormFieldErrorMessageMap = {
    customOptionsName: {
      required: this._translate.instant(
        'Scenario.messages.customOptionsName.required',
      ),
    },
    customOptionsValue: {
      required: this._translate.instant(
        'Scenario.messages.customOptionsValue.required',
      ),
    },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomOptionsFormDialogData,
    public dialogRef: MatDialogRef<CustomOptionsFormDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private _translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.data) {
      this.isViewOnly = this.data.isViewOnly;
      this.customOptionsForm.controls.name.patchValue(this.data.name);
      this.customOptionsForm.controls.value.patchValue(this.data.value);
    }
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
