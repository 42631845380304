import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Node } from 'prosumer-app/+scenario/models';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import {
  EGCEmission,
  EGCEmissionQuery,
  EGCEmissionStore,
  EmissionDuplicateDialogData,
  EmissionDuplicateParams,
} from 'prosumer-app/stores/egc-emission';
import { EnergyGridConnectionInfo } from 'prosumer-app/stores/energy-grid-connection';
import { SCOPE_OPTIONS } from 'prosumer-scenario/components/commodities-form/commodities-form.types';
import { DetailEntityDuplicator } from 'prosumer-scenario/directives/detail-duplicator';
import { DuplicationValidators } from 'prosumer-shared/validators/duplication';
import { Observable } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { EgcDuplicateDialogComponent } from '../commodities-form/egc-duplicate-dialog';

import { EmissionsDuplicateDialogService } from './emissions-duplicate-dialog.service';

interface Form {
  readonly marketName: string;
  readonly node: string;
  readonly scope: string;
}

@UntilDestroy()
@Component({
  selector: 'prosumer-emissions-duplicate-dialog',
  templateUrl: './emissions-duplicate-dialog.component.html',
  styleUrls: ['./emissions-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmissionsDuplicateDialogComponent extends DetailEntityDuplicator<EGCEmission> {
  readonly duplicableForm = new UntypedFormGroup({
    marketName: new UntypedFormControl(this.data.origin.connectedEgcId),
    node: new UntypedFormControl(this.data.origin.node),
    scope: new UntypedFormControl(this.data.origin.scope),
  });

  readonly markets$ = this.selectMarkets();
  readonly nodes$ = this.selectNodeOptions();
  readonly scopes: FormFieldOption<string>[] = SCOPE_OPTIONS;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: EmissionDuplicateDialogData,
    readonly dialog: MatDialogRef<EgcDuplicateDialogComponent>,
    readonly store: EGCEmissionStore,
    readonly query: EGCEmissionQuery,
    readonly notifs: NotificationsService,
    private readonly service: EmissionsDuplicateDialogService,
  ) {
    super(data, store, query, dialog, notifs);
    this.subscribeToFormValidityForErrorSetting();
  }

  onOk(): void {
    if (this.doInputsCreateAUniqueCombination()) {
      this.onDuplicate(this.getFormInParams());
    }
  }

  private subscribeToFormValidityForErrorSetting(): void {
    this.duplicableForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setErrorsBasedOnValidity();
      });
  }

  private setErrorsBasedOnValidity(): void {
    if (this.doInputsCreateAUniqueCombination()) {
      this.setErrorsForAllControls(null);
    } else {
      this.setErrorsForAllControls({ invalid: true });
    }
  }

  private doInputsCreateAUniqueCombination(): boolean {
    return DuplicationValidators.isEGCEmissionUnique(
      this.duplicableForm.value,
      this.data.existing,
    );
  }

  private setErrorsForAllControls(errors: unknown): void {
    Object.values(this.duplicableForm.controls).forEach((control) => {
      control.setErrors(errors);
    });
  }

  private getFormInParams(): EmissionDuplicateParams {
    const { marketName, node, scope } = this.duplicableForm.value as Form;
    return { connectedEgcId: marketName, nodeId: node, scope };
  }

  private selectMarkets(): Observable<EnergyGridConnectionInfo[]> {
    return this.service.selectMarketOptions();
  }

  private selectNodeOptions(): Observable<Node[]> {
    return this.selectMarketChanges().pipe(
      withLatestFrom(this.service.selectNodeOptions()),
      map(([market, nodes]) => this.service.onlyNodesOfMarket(nodes, market)),
    );
  }

  private selectMarketChanges(): Observable<string> {
    const marketName = this.duplicableForm.get('marketName');
    return marketName.valueChanges.pipe(startWith(marketName.value));
  }
}
