import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerceptionMap } from '@prosumer/results/components/case-results-perception';
import {
  ComparePaybackResult,
  PaybackResultItem,
} from 'prosumer-app/+scenario/models';
import {
  BaseComponent,
  MetricPipe,
  toObj,
} from 'prosumer-app/libs/eyes-shared';
import {
  MultipleLinesData,
  SingleLine,
} from 'prosumer-shared/modules/chartjs/line-chartjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'prosumer-compare-payback',
  templateUrl: './compare-payback.component.html',
  styleUrls: ['./compare-payback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ComparePaybackComponent extends BaseComponent implements OnInit {
  private paybackResultsSubject = new BehaviorSubject<ComparePaybackResult[]>(
    [],
  );

  constructor(
    private elementRef: ElementRef,
    private _metricPipe: MetricPipe,
    private translate: TranslateService,
  ) {
    super();
  }
  @Input() paybackResults: Array<ComparePaybackResult>;
  @Input() colors: PerceptionMap;
  @Input() id: string;

  comparePaybackResults: Array<ComparePaybackResult>;
  referenceDict: any;
  mergedYears: Array<string> = [];
  tempPayback = 0; // storage for the cumulative payback result

  chartData$: Observable<MultipleLinesData>;

  ngOnInit() {
    const referencePayback: ComparePaybackResult = this.paybackResults.filter(
      (result) => result.id === result.referenceId,
    )[0];
    this.referenceDict = toObj(referencePayback.series, 'name');
    this.comparePaybackResults = this.paybackResults.filter(
      (result) =>
        result.id !== referencePayback.id ||
        result.name !== referencePayback.name,
    );

    this.mergeYears();
    this.compare();
    this.transform();

    this.paybackResultsSubject.next(this.comparePaybackResults);
    this.chartData$ = this.getChartDataStream();
  }

  private getChartDataStream(): Observable<MultipleLinesData> {
    return this.paybackResultsSubject.pipe(
      map((results) => this.mapResultsToChartJSData(results)),
    );
  }

  private mapResultsToChartJSData(
    results: ComparePaybackResult[],
  ): MultipleLinesData {
    return {
      lines: results.map((result) => this.mapResultToSingleLine(result)),
      name: `Comparison of Payback`,
      yAxisName: 'Difference of expenditures [k€]',
      xAxisName: this.translate.instant('Result.labels.years'),
    };
  }

  private mapResultToSingleLine(result: ComparePaybackResult): SingleLine {
    const { name, series } = result;
    return {
      name,
      values: series.map((siri) => ({ name: siri.name, value: siri.value })),
    };
  }

  /* Collect the years for each payback series. */
  mergeYears() {
    const years = this.paybackResults
      .map((payback) => payback.series.map((year) => Number(year.name)))
      .reduce((prev, next) => prev.concat(next), []);
    const uniqueYears = [...new Set(years)]; // remove repeating entries in years array
    this.mergedYears = uniqueYears.map(String);
  }

  /* Compute the difference of reference and non reference payback series.
     Use the referenceDict as the minuend.
  */
  compare() {
    this.comparePaybackResults = this.comparePaybackResults
      .map((results) => {
        this.tempPayback = 0;
        return {
          name: results.name,
          series: results.series.map((payback) => ({
            ...payback,
            value: this.compute(payback),
          })),
        };
      })
      .map((result) => ({
        ...result,
        series: result.series.map((payback) => ({
          ...payback,
          value: payback.value ? payback.value / -1 : payback.value,
        })),
      })) as Array<ComparePaybackResult>;
  }

  compute(paybackItem: PaybackResultItem) {
    const currentPayback = this.referenceDict[paybackItem.name]
      ? this.tempPayback +
        (paybackItem.value - this.referenceDict[paybackItem.name].value)
      : this.tempPayback + paybackItem.value;
    // store the previous payback as the result should be cumulative
    this.tempPayback = currentPayback;
    return currentPayback;
  }

  /* Transform each series so that all payback results would have the same range of years for each payback.
     Assign 0 values to years that do not initially belong to the payback series
   */
  transform() {
    // create the template for the merged years
    const transformMergedYears = this.mergedYears.map((year) => ({
      name: year,
      value: 0,
    })) as Array<PaybackResultItem>;

    const comparePaybackResultsTemp = []; // store in temp the new transformed payback result

    for (const payback of this.comparePaybackResults) {
      const transformMergedYearsTemp = [];
      transformMergedYearsTemp.push(...transformMergedYears); // deep copy the template for merged years

      for (const series of payback.series) {
        // for each match years, replace the entry of transformMergedYearsTemp
        if (this.mergedYears.indexOf(series.name) !== -1) {
          transformMergedYearsTemp[this.mergedYears.indexOf(series.name)] = {
            name: series.name,
            value: parseFloat(
              this._metricPipe.transform(series.value, 1).replace(/,/g, ''),
            ), // make sure to transform to kilo value
          };
        }
      }

      // create the new payback result
      const formNewPaybackResult = {
        name: payback.name,
        series: transformMergedYearsTemp,
      };

      // collect the  new payback result
      comparePaybackResultsTemp.push(formNewPaybackResult);
    }

    // assign the new payback result collection
    this.comparePaybackResults = comparePaybackResultsTemp;
  }
}
