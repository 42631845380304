import { Coerce } from 'prosumer-core/utils';
import { fadeInAnimation, PageMode } from 'prosumer-libs/eyes-shared';
import { Project } from 'prosumer-project/models';
import { NameValidator } from 'prosumer-shared/validators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'prosumer-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectFormComponent {
  @Input() loading: boolean;

  @Input() set saving(saving: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    saving
      ? this.projectForm.disable({ emitEvent: false })
      : this.projectForm.enable({ emitEvent: false });
    this._saving = saving;
  }

  get saving(): boolean {
    return this._saving;
  }

  @Input() canEdit: boolean;
  @Input() owner: string;
  @Input() mode: PageMode;
  @Input() set data(data: any) {
    this._data = data;
    if (data && (this.mode === 'update' || this.mode === 'read')) {
      this.projectForm.patchValue(data);
    }
  }

  @Output() save = new EventEmitter<Project>();
  @Output() cancel = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();

  projectForm = this._formBuilder.group({
    id: '',
    name: ['', NameValidator.validWithCore()],
    description: '',
    createdDate: '',
    updatedDate: '',
    userId: '',
  });

  _saving: boolean;
  _data: Project;

  constructor(private _formBuilder: UntypedFormBuilder) {}

  onSubmit(): void {
    if (this.projectForm.valid) {
      this.save.emit(this.projectForm.value);
    }
  }

  onCancel() {
    if (this.mode === 'update') {
      this.projectForm.reset(this._data);
    }
    this.cancel.emit();
  }

  onEdit() {
    this.edit.emit();
  }

  getErrors(control: FormControl): string[] {
    return Object.keys(Coerce.toObject(control.errors));
  }
}
