import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { MarketLimitStore } from 'prosumer-app/stores/market-limit';
import { EnergyGridLimit } from 'prosumer-scenario/models';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { EntityUpsertionTrigger } from '../../directives/entity-upsertion-trigger';

@Component({
  selector: 'prosumer-market-limit-upsert-trigger',
  templateUrl: './market-limit-upsert-trigger.component.html',
  styleUrls: ['./market-limit-upsert-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MarketLimitUpsertTriggerComponent extends EntityUpsertionTrigger<EnergyGridLimit> {
  constructor(
    private readonly store: MarketLimitStore,
    readonly notifs: NotificationsService,
  ) {
    super();
  }

  create(data: EnergyGridLimit): Observable<unknown> {
    return this.store
      .create(data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  edit(id: string, data: EnergyGridLimit): Observable<unknown> {
    return this.store
      .edit(id, data)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    this.notifs.showError(error.error.error);
    return throwError(() => error);
  }
}
