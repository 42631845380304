import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { take } from 'rxjs/operators';

import { OperatingCostDialogMode } from 'prosumer-app/+scenario';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { EquipmentQuery } from 'prosumer-app/stores/equipment';
import {
  CreateOpCostBody,
  OperationalCostUpserter,
} from 'prosumer-app/stores/op-costs';

@Component({
  selector: 'prosumer-opcosts-upsert-trigger',
  templateUrl: './opcosts-upsert-trigger.component.html',
  styleUrls: ['./opcosts-upsert-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OpcostsUpsertTriggerComponent {
  @Input() id: string;
  @Input() data: CreateOpCostBody = {};
  @Input() valid = false;
  @Input() mode: OperatingCostDialogMode = 'add';
  @Output() ok = new EventEmitter();
  @Output() errorResponse = new EventEmitter();
  @Input() disabled = false;

  readonly upserting$ = this.upserter.loading$;

  constructor(
    private readonly upserter: OperationalCostUpserter,
    private readonly equipments: EquipmentQuery,
    private readonly notifs: NotificationsService,
  ) {}

  onClick(): void {
    this.upsertFnMap[this.mode]();
  }

  private get upsertFnMap(): Record<OperatingCostDialogMode, () => void> {
    return {
      add: () => this.createIfValid(),
      edit: () => this.editIfValid(),
    };
  }

  private createIfValid(): void {
    if (this.valid) {
      this.upserter
        .create(this.equipments.getActiveId(), this.data)
        .pipe(take(1))
        .subscribe({
          next: () => this.onSuccess(),
          error: (error) => this.onError(error),
        });
    }
  }

  private editIfValid(): void {
    if (this.valid) {
      this.upserter
        .edit(this.equipments.getActiveId(), this.id, this.data)
        .pipe(take(1))
        .subscribe({
          next: () => this.onSuccess(),
          error: (error) => this.onError(error),
        });
    }
  }

  private onSuccess(): void {
    this.ok.emit();
  }

  private onError(error: HttpErrorResponse): void {
    this.errorResponse.emit(error);
  }
}
