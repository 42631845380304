import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
} from '@angular/forms';
import { TaxSubsidyInterval } from 'prosumer-app/+scenario/models';
import { DialogService } from 'prosumer-app/libs/eyes-core';
import { BaseFormComponent } from 'prosumer-app/libs/eyes-shared';
import { take } from 'rxjs/operators';
import { TaxSubsidyIntervalDialogComponent } from '../tax-subsidy-interval-dialog';
import { TaxSubsidyIntervalDialogModel } from '../tax-subsidy-interval-dialog/tax-subsidy-interval-dialog.model';

@Component({
  selector: 'prosumer-tax-subsidy-interval',
  templateUrl: './tax-subsidy-interval.component.html',
  styleUrls: ['./tax-subsidy-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TaxSubsidyIntervalComponent
  extends BaseFormComponent
  implements AfterViewInit
{
  _startYear: number;
  @Input() set startYear(value: number) {
    this._startYear = value;
  }
  get startYear(): number {
    return this._startYear;
  }

  _endYear: number;
  @Input() set endYear(value: number) {
    this._endYear = value;
  }
  get endYear(): number {
    return this._endYear;
  }

  get intervalsFormArray(): UntypedFormArray {
    return this.form.controls.intervals as UntypedFormArray;
  }

  @Output() hasInvalidInterval = new EventEmitter<boolean>();

  selected = new UntypedFormControl(0); // The control representing the selected or active tab
  invalidIntervals: Set<number> = new Set();

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private _dialogService: DialogService,
  ) {
    super(ngControl, changeDetector, formBuilder);
  }

  defineForm() {
    return {
      intervals: this.formBuilder.array([]),
    };
  }

  writeValue(taxAndSubsidies: Array<TaxSubsidyInterval>): void {
    if (!!taxAndSubsidies) {
      this.initForm(taxAndSubsidies);
    } else {
      this.addInterval(null, this.startYear, this.endYear);
    }
  }

  setDisabledState(disabled: boolean) {}

  ngAfterViewInit() {
    this.initIntervalsChangeHandler();
  }

  initIntervalsChangeHandler(): void {
    this.intervalsFormArray.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => {
        const intervals = this.intervalsFormArray.getRawValue();
        this.markValidity(intervals);
        this.onChange(intervals);
      });
  }

  markValidity(intervals: Array<TaxSubsidyInterval>) {
    if (!intervals) {
      return;
    }

    intervals.forEach((interval, index) => {
      if (
        !interval.generationTariffSubsidy &&
        !interval.autoConsumptionTax &&
        !interval.feedInTariffSubsidy
      ) {
        this.invalidIntervals.add(index);
      } else {
        this.invalidIntervals.delete(index);
      }
    });

    this.hasInvalidInterval.emit(!!this.invalidIntervals.size);
  }

  initForm(intervals?: Array<TaxSubsidyInterval>, selected: number = 0): void {
    this.clearIntervals();
    intervals.forEach((interval) => this.addInterval(interval));
    this.selected.patchValue(selected);
  }

  clearIntervals(): void {
    while (this.intervalsFormArray.length) {
      this.intervalsFormArray.removeAt(0);
    }
  }

  addInterval(
    interval: TaxSubsidyInterval,
    startYear?: number,
    endYear?: number,
  ) {
    this.intervalsFormArray.push(
      this.createFormGroup(interval, startYear, endYear),
    );
  }

  createFormGroup(
    interval: TaxSubsidyInterval,
    startYear?: number,
    endYear?: number,
  ): UntypedFormGroup {
    const formGroup = this.formBuilder.group({
      startYear: this.formBuilder.control(startYear || null),
      endYear: this.formBuilder.control(endYear || null),
      generationTariffSubsidy: this.formBuilder.control(undefined),
      autoConsumptionTax: this.formBuilder.control(undefined),
      feedInTariffSubsidy: this.formBuilder.control(undefined),
    });

    if (!!interval) {
      formGroup.patchValue(
        {
          ...interval,
        },
        { emitEvent: false },
      );
    }

    return formGroup;
  }

  onEditInterval(): void {
    this._dialogService
      .openDialog(TaxSubsidyIntervalDialogComponent, {
        startYear: this.startYear,
        endYear: this.endYear,
        intervals: this.intervalsFormArray.value,
        width: 800,
        disableClose: true,
      } as TaxSubsidyIntervalDialogModel)
      .pipe<Array<TaxSubsidyInterval>>(take(1))
      .subscribe((intervals) => {
        if (!!intervals) {
          const activeIndex = this.calculateActiveIndex(
            this.intervalsFormArray.value,
            intervals,
          );
          this.initForm(intervals, activeIndex);
        }
        this.markForCheck();
      });
  }

  calculateActiveIndex(
    prevIntervals: Array<TaxSubsidyInterval>,
    nextIntervals: Array<TaxSubsidyInterval>,
  ) {
    let activeIndex = nextIntervals.length - 1;

    if (nextIntervals.length > prevIntervals.length) {
      const previous = prevIntervals.map((interval) => interval.startYear);
      nextIntervals
        .map((interval) => interval.startYear)
        .some((year, idx) => {
          if (previous.indexOf(year) === -1) {
            activeIndex = idx;
            return true;
          }
        });
    }

    return activeIndex;
  }

  onChangeSelectedTab(index: number) {
    this.selected.patchValue(index);
  }

  isInvalid(index: number) {
    return this.invalidIntervals.has(index);
  }
}
