import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ChartOrientation } from 'prosumer-shared/modules/chartjs/chartjs.model';
import { PieMeta } from 'prosumer-shared/modules/chartjs/pie-chartjs/pie-chartjs.model';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

import {
  ReducedResultNameValues,
  ReducedSliceData,
} from '../../bar-and-area-results';
import { PerceptionMap } from '../../case-results-perception';
import { ResultNameValue } from '../../results-visualizer';
import { MainOutputResultsService } from '../main-output-results.service';

@Component({
  selector: 'prosumer-sizing-and-costs-results',
  templateUrl: './sizing-and-costs-results.component.html',
  styleUrls: ['./sizing-and-costs-results.component.scss'],
  providers: [CurrencyPipe],
  standalone: false,
})
export class SizingAndCostsResultsComponent {
  options = ['firstYear', 'lastYear', 'total'];
  sliceControl: UntypedFormControl = new UntypedFormControl(this.options[0]);

  sizing$: Observable<StackedBarMeta>;
  opex$: Observable<PieMeta>;
  capex$: Observable<PieMeta>;

  @Input() colors: PerceptionMap;
  @Input() scenarioName: string;

  constructor(private results: MainOutputResultsService) {
    this.sizing$ = this.getSizingStream();
    this.opex$ = this.getOpexStream();
    this.capex$ = this.getCapexStream();
  }

  private getDataStream(): Observable<ReducedSliceData> {
    return this.results.sizingAndCostData$.pipe(
      debounceTime(100),
      map((data) => this.reduceToDict(data)),
    );
  }

  private getActiveSliceStream(): Observable<ReducedResultNameValues> {
    return combineLatest([
      this.getDataStream(),
      this.getSliceControlValueChanges(),
    ]).pipe(map(([data, slice]) => data[slice] as ReducedResultNameValues));
  }

  private getSliceControlValueChanges(): Observable<string> {
    return this.sliceControl.valueChanges.pipe(
      startWith(this.sliceControl.value),
    );
  }

  private getSizingStream(): Observable<StackedBarMeta> {
    return this.getActiveSliceChartStream('sizing').pipe(
      map((data) => this.mapToStackedBarChartJS(data)),
    );
  }

  private getOpexStream(): Observable<PieMeta> {
    return this.getActiveSliceChartStream('opex').pipe(
      map((slices) => ({
        slices: this.mapToValueConvertedSlices(slices),
        name: 'OPEX (kEUR)',
      })),
    );
  }

  private getCapexStream(): Observable<PieMeta> {
    return this.getActiveSliceChartStream('capex').pipe(
      map((slices) => ({
        slices: this.mapToValueConvertedSlices(slices),
        name: 'CAPEX (kEUR)',
      })),
    );
  }

  private getActiveSliceChartStream(
    chartKey: string,
  ): Observable<ResultNameValue[]> {
    return this.getActiveSliceStream().pipe(
      filter((data) => !!data),
      map((data) => data[chartKey]),
    );
  }

  private mapToValueConvertedSlices(
    slices: ResultNameValue[],
  ): { name: string; value: number }[] {
    return slices.map(({ name, value }) => ({ name, value: value / 1000 }));
  }

  private mapToStackedBarChartJS(data: ResultNameValue[]): StackedBarMeta {
    return {
      axisTicks: data.map((d) => d.name),
      data: data.map((d) => ({ name: d.name, values: { [d.name]: d.value } })),
      orientation: ChartOrientation.HORIZONTAL,
      name: 'Sizing (kW)',
    };
  }

  private reduceToDict(data: unknown[]): ReducedSliceData {
    return data.reduce((acc, curr) => {
      acc[curr['name']] = curr['value'];
      return acc;
    }, {}) as ReducedSliceData;
  }
}
