/* eslint-disable @typescript-eslint/naming-convention */
import { CoherenceResultMapper } from 'prosumer-app/+scenario/services';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { MessageStore } from 'prosumer-app/stores';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SCENARIO_TYPE } from '../../../app.references';
import {
  MESSAGES_LOGS_COLUMN_DEF_V_1_0_TRANSLATED,
  MESSAGES_LOGS_FILTERS_V_1_0_TRANSLATED,
} from '../messages-log-table/messages-log-table.tokens';
import { MessageDialogData } from './messages-dialog.model';

@Component({
  selector: 'prosumer-messages-dialog',
  templateUrl: './messages-dialog.component.html',
  styleUrls: ['./messages-dialog.component.scss'],
  standalone: false,
})
export class MessagesDialogComponent extends BaseComponent implements OnInit {
  loading$ = this.messageStore.loading$;
  messages$ = this.messageStore.messages$;
  version$ = this.messageStore.version$.pipe(
    tap((version) => this.adjustDialogWidth(version)),
  );
  isImportMessage$ = this.messageStore.isImportMessage$;
  importMessagesMapByStep$ = combineLatest([
    this.messages$,
    this.isImportMessage$,
  ]).pipe(
    map(([msgs, isImportMessage]) =>
      isImportMessage ? CoherenceResultMapper.mapToDisplayMessage(msgs) : [],
    ),
  );
  logDisabled: boolean;
  isClientExpert: boolean;

  filtersDataForTranslatedV1 = MESSAGES_LOGS_FILTERS_V_1_0_TRANSLATED;
  tableMetaDataForTranslatedV1 = MESSAGES_LOGS_COLUMN_DEF_V_1_0_TRANSLATED;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
    public dialogRef: MatDialogRef<MessagesDialogComponent>,
    public messageStore: MessageStore,
  ) {
    super();
  }

  ngOnInit() {
    const scenario: any = this.data.data;
    this.logDisabled = this.data.data.logDisabled;
    this.isClientExpert = this.data.data.isClientExpert;
    this.identifyMessageAction(scenario);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onDownloadLog(): void {
    this.dialogRef.close(true);
  }

  identifyMessageAction(scenario: any): void {
    const source: string = scenario.source;
    if (
      scenario.scenarioType === SCENARIO_TYPE.CLONED &&
      source &&
      source === 'Excel Import'
    ) {
      this.messageStore.getExcelImportMessages(
        scenario.projectId,
        scenario.caseId,
        scenario.id,
      );
    } else if (source && source === 'Simulation') {
      this.messageStore.getMessages(
        scenario.projectId,
        scenario.caseId,
        scenario.id,
      );
    }
  }

  private adjustDialogWidth(version: string) {
    if (version === '1.1') {
      this.dialogRef.updateSize('1300px');
    } else {
      this.dialogRef.updateSize('1000px');
    }
  }
}

export type ScenarioSource = 'Excel Import' | 'Simulation';
