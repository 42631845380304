import {
  TDBCommoditiesFormSelect,
  TdbActiveFilters,
} from 'prosumer-app/+scenario/models';
import { TDBDataQuery, TDBDataStore } from 'prosumer-app/stores';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getReferenceStr } from 'prosumer-app/shared';

const DEFAULT_ENABLE_TDB_MODULE = false;

@UntilDestroy()
@Component({
  selector: 'prosumer-tdb-renewables',
  templateUrl: './tdb-renewables.component.html',
  styleUrls: ['./tdb-renewables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TdbRenewablesComponent implements OnInit, OnDestroy {
  @Output() profileDataFetched = new EventEmitter();
  @Input() yearsList: number[];
  @Input() profileFiltersSourceDetails: TdbActiveFilters | string | undefined;
  @Input() isTDBModuleOpen = DEFAULT_ENABLE_TDB_MODULE;
  @Input() filterAction = 'getRenewableFilters';
  @Input() profileAction = 'getRenewableProfile';

  readonly tdbRenewablesForm = this._initForm();

  tdbCommodity$ = new BehaviorSubject<string | undefined>(undefined);
  isTdbModuleEnabled = new BehaviorSubject<boolean>(this.isTDBModuleOpen);
  isTdbApiResponseLoading$: Observable<boolean>;
  tdbGeographyOptions$: Observable<TDBCommoditiesFormSelect[]>;
  tdbTechnologyOptions$: Observable<TDBCommoditiesFormSelect[]>;

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly tdbDataStore: TDBDataStore,
    private readonly tdbDataQuery: TDBDataQuery,
  ) {}

  ngOnDestroy(): void {
    this.tdbDataStore.update(this.tdbDataStore.initStoreValues());
  }

  ngOnInit() {
    this._initObservables();
    this._initSubs();
    this._formatReference();
  }

  toggleTDBModule(value: boolean) {
    this.isTdbModuleEnabled.next(value);
  }

  private _initForm() {
    return this._formBuilder.group({
      geography: [undefined, Validators.required],
      technology: [undefined, Validators.required],
    });
  }

  private _initObservables() {
    this.isTdbApiResponseLoading$ = this.tdbDataQuery
      .selectLoading()
      .pipe(untilDestroyed(this));
    this.tdbGeographyOptions$ = this.getFilters('geography');
    this.tdbTechnologyOptions$ = this.getFilters('technology');
  }

  private _initSubs() {
    this._subToToggleChange();
    this._subToCompletedFilters();
  }

  private _subToCompletedFilters(): void {
    this.tdbRenewablesForm.valueChanges
      .pipe(
        filter(() => this.tdbRenewablesForm.valid),
        // filter(() => this.checkCriteriaValidity()),
        tap((fitlers) => {
          // const criteria = this.buildParams(fitlers);
          this.tdbDataStore.updateActiveFilters(fitlers);
        }),
        mergeMap((filters) =>
          this.tdbDataStore.getTDBData(filters, this.profileAction),
        ),
      )
      .subscribe((value) => {
        this.profileDataFetched.emit(value);
      });
  }

  private _subToToggleChange() {
    this.isTdbModuleEnabled
      .asObservable()
      .pipe(
        untilDestroyed(this),
        filter((val) => !!val),
        takeUntil(this.tdbDataQuery.selectFilters()),
        mergeMap(() =>
          this.tdbDataStore.getTDBFilters(null, this.filterAction),
        ),
      )
      .subscribe((res) => this._checkErrorResToResetToggle(res));
  }

  private _checkErrorResToResetToggle(res) {
    if (!res.error) return;
    this.isTdbModuleEnabled.next(false);
  }

  private getFilters(key: string): Observable<TDBCommoditiesFormSelect[]> {
    return this.tdbDataQuery.selectFilters().pipe(
      untilDestroyed(this),
      map((filters) => filters[key] as string[]),
      map((filters) => this._mapToFormSelect(filters)),
    );
  }

  private _mapToFormSelect(data: string[]): TDBCommoditiesFormSelect[] {
    return data.map((option) => ({
      name: option,
      value: option,
    }));
  }

  private buildParams(formValues): Record<string, string | number> {
    const [startYear, endYear] = this.yearsList;
    const { geography, technology } = formValues;
    return { endYear, startYear, geography, technology };
  }

  private checkCriteriaValidity(): boolean {
    return ['geography', 'technology']
      .map((key) => !!this.tdbRenewablesForm.get(key).value)
      .every(Boolean);
  }

  private _formatReference() {
    this.profileFiltersSourceDetails = getReferenceStr(
      this.profileFiltersSourceDetails as TdbActiveFilters,
    );
  }
}
