import camelCase from 'lodash/camelCase';
import { CoherenceResultMapper } from 'prosumer-app/+scenario/services';
import { LaunchCompletion } from 'prosumer-app/+scenario/services/completion-strategies';
import {
  ScenarioCompletionService,
  ScenarioWizardStep,
} from 'prosumer-app/+scenario/services/scenario-completion';
import {
  fadeInAnimation,
  StepFormComponent,
} from 'prosumer-app/libs/eyes-shared';
import { Subject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { NgControl, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CoherenceResult, CoherenceStepValidation } from '../../models';

@UntilDestroy()
@Component({
  selector: 'prosumer-summary-form',
  templateUrl: './summary-form.component.html',
  styleUrls: ['./summary-form.component.scss'],
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SummaryFormComponent extends StepFormComponent implements OnInit {
  validating$ = new Subject<boolean>();
  _validating: boolean;
  _checkingConflicts$ = new Subject<boolean>();

  _checkingConflicts: boolean;
  @Input() set checkingConflicts(value: boolean) {
    this._checkingConflicts$.next(value);
    this._checkingConflicts = value;
  }
  get checkingConflicts(): boolean {
    return this._checkingConflicts;
  }
  @Input() set validating(validating: boolean) {
    this._validating = validating;
    this.validating$.next(validating);
    this.stateChanges.next();
  }
  get validating(): boolean {
    // added handling for undefined to show spinner when launch wizard is shwon at start
    return this._validating === undefined ? true : this._validating;
  }
  _error: any;
  @Input() set error(error: any) {
    this._error = error;
    this.stateChanges.next();
  }
  get error(): any {
    return this._error;
  }

  _coherenceResults: Array<CoherenceStepValidation>;
  @Input() set coherenceResults(results: Array<CoherenceStepValidation>) {
    this._coherenceResults = CoherenceResultMapper.mapToDisplayMessage(results);
    this.stateChanges.next();
  }
  get coherenceResults() {
    return this._coherenceResults || [];
  }

  @Input() hasCoherenceError: boolean;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private completion: ScenarioCompletionService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super(ngControl, changeDetector, formBuilder);
    this.subscribeToFormForCompletionTracking();
  }

  defineForm() {
    return {};
  }

  private subscribeToFormForCompletionTracking() {
    const strategy = new LaunchCompletion();
    this.validating$.pipe(untilDestroyed(this)).subscribe(() => {
      this.completion.setForLaunch(
        strategy.determineStatus(this._coherenceResults),
      );
    });
  }

  navigateToScenarioStep(validation: CoherenceResult) {
    const scenarioTab = camelCase(validation.scenarioTab);
    const wizardStep: ScenarioWizardStep = ScenarioWizardStep[scenarioTab];

    if (!!wizardStep) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { scenarioTab: wizardStep },
      });
    }
  }
}
