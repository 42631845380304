import moment from 'moment';
import { StorageDispatchAdapter } from 'prosumer-app/+scenario/containers/simulation-results/dispatch/adapters';
import { CompareDispatchResultItem } from 'prosumer-app/+scenario/models';
import {
  DAY_FORMAT,
  HOUR_FORMAT,
  MONTH_FORMAT,
  RESULTS_DISPATCH_DAY_FORMAT,
  RESULTS_DISPATCH_HOUR_FORMAT,
  RESULTS_DISPATCH_MONTH_FORMAT,
  YEAR_FORMAT,
} from 'prosumer-app/app.references';
import { FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import { PipeUtils } from 'prosumer-core/utils';
import { LineData } from 'prosumer-shared/modules/chartjs/line-chartjs/line-chartjs.model';
import { StackedBarMeta } from 'prosumer-shared/modules/chartjs/stacked-bar-chartjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ResultsPerceptionService } from '@prosumer/results/components/case-results-perception';
import {
  ResultNameValue,
  VisualizerData,
} from '@prosumer/results/components/results-visualizer';

import { CompareDispatchData } from '../compare-dispatch.model';

@Component({
  selector: 'prosumer-compare-raw-storage',
  templateUrl: './raw-storage.component.html',
  styleUrls: ['./raw-storage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompareRawStorageComponent
  extends StorageDispatchAdapter
  implements OnInit
{
  @Input() set data(raw: CompareDispatchData) {
    if (raw) {
      this.raw$.next(raw);
      this.options = this.getEquipmentOptions(raw.dataList);
      this.loadStorages();
    }
  }

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#D27658'],
  };

  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#58D258', '#D27658', '#CAC76B'],
  };

  @Input() customColors: any;
  view: any[];
  chartWidth$ = new BehaviorSubject(0);
  raw$ = new BehaviorSubject<CompareDispatchData>(undefined);
  equipmentOptions$ = new BehaviorSubject<Array<FormFieldOption<string>>>([]);
  storageSoc$ = new BehaviorSubject<any>(undefined);
  storageCharge$ = new BehaviorSubject<any>(undefined);
  lineData$: Observable<LineData>;
  options: string[];

  storageControl = new UntypedFormControl('');

  constructor(perception: ResultsPerceptionService) {
    super(perception);
  }
  ngOnInit() {
    this.storageControl.valueChanges
      .pipe()
      .subscribe(() => this.loadStorages());
    this.initializeChartDataAndColors();
    this.lineData$ = this.getLineDataStream();
  }

  formatTick(tick: string): string {
    return this.chartXAxisFormat(tick);
  }

  getChartName(): string {
    return `Storage Raw Dispatch - ${this.storageControl.value} (${this.raw$.value.name})`;
  }

  getNgxChartsDataStream(): Observable<ResultNameValue[]> {
    return this.storageCharge$;
  }

  getEquipmentOptions(raw: CompareDispatchResultItem[]) {
    let equipment = [];
    const options = [];
    if (raw && raw.length > 0) {
      raw.forEach((data) => equipment.push(data.equipment));
      equipment = [...new Set(equipment)];
      equipment.forEach((option) =>
        options.push({ name: option, value: option }),
      );
      this.equipmentOptions$.next(options);
      this.storageControl.setValue(options[0].value);
    }
    return equipment;
  }

  loadStorages() {
    if (this.raw$.value && this.raw$.value.dataList) {
      const socData = this.raw$.value.dataList.filter(
        (data) =>
          data.equipment === this.storageControl.value &&
          data.resultType === 'soc',
      )[0];
      const storage = this.raw$.value.dataList.filter(
        (data) =>
          data.equipment === this.storageControl.value &&
          data.resultType === 'storage_mvts',
      )[0];
      this.storageSoc$.next([
        {
          name: 'Storage Soc',
          series: !socData
            ? []
            : socData.values.map((value, index) => ({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                name: moment({
                  y: +coerceNumberProperty(socData.year),
                  M: 0,
                  d: 1,
                  h: 0,
                  m: 0,
                  s: 0,
                  ms: 0,
                })
                  .hour(index + (this.raw$.value.start - 1) * 24)
                  .toISOString(),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                moment: moment({
                  y: +coerceNumberProperty(socData.year),
                  M: 0,
                  d: 1,
                  h: 0,
                  m: 0,
                  s: 0,
                  ms: 0,
                }).hour(index + (this.raw$.value.start - 1) * 24),
                value,
              })),
        },
      ]);
      this.storageCharge$.next(
        !storage
          ? []
          : storage.values.map((value, index) => ({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              name: moment({
                y: +coerceNumberProperty(storage.year),
                M: 0,
                d: 1,
                h: 0,
                m: 0,
                s: 0,
                ms: 0,
              })
                .hour(index + (this.raw$.value.start - 1) * 24)
                .toISOString(),
              // eslint-disable-next-line @typescript-eslint/naming-convention
              moment: moment({
                y: +coerceNumberProperty(storage.year),
                M: 0,
                d: 1,
                h: 0,
                m: 0,
                s: 0,
                ms: 0,
              }).hour(index + (this.raw$.value.start - 1) * 24),
              value: value * -1,
            })),
      );
      if (storage) {
        this.updateView(storage.values.length);
      }
    }
  }

  private getLineDataStream(): Observable<LineData> {
    return this.storageSoc$?.pipe(
      PipeUtils.filterOutEmpty,
      map((data) =>
        this.mapNgxLineThingyToChartJSLineData(data as VisualizerData[]),
      ),
      takeUntil(this.componentDestroyed$),
    );
  }

  private mapNgxLineThingyToChartJSLineData(
    thingy: VisualizerData[],
  ): LineData {
    const soleValue = thingy[0];
    return {
      name: soleValue.name,
      points: soleValue.series.map((siri) => siri.value),
      ticks: [],
    };
  }

  /**
   * Gives predefined width and height depending on data.
   * This function helps the chart-container when data exceeds 7 days.
   *
   * @param len - storageCharge data length
   */
  updateView(len: number) {
    if (len > 168 && len < 481) {
      this.view = [5000, 350];
    }
    if (len > 480) {
      this.view = [8000, 350];
    }
  }

  /**
   * Function handling of output select from base chart
   *
   * @param selected - some data
   */
  onSelect(selected: string) {}

  /**
   * Returns a format string for Y-axis (Power [kWh])
   *
   * @param data - some param to be acceptable in typescript
   */
  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  /**
   * Function helper to define YDomain of custom chart
   *
   * @param min - min values of the results[]
   * @param max - max values of the results[]
   */
  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  /**
   * Function helper to define YDomain Line of custom chart
   *
   * @param min - min values of the results[]
   * @param max - max values of the results[]
   */
  yRightAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  /**
   * Storage SoC format - Y Right axis. Appending % sign.
   *
   * @param data - series data
   */
  yRightTickFormat(data) {
    return `${data}%`;
  }

  /**
   * Helper to interpret moment data in X-Axis to a more readable human info
   *
   * @param value - moment data
   */
  chartXAxisFormat(value: any) {
    return moment(value).format(
      `${RESULTS_DISPATCH_MONTH_FORMAT} ${RESULTS_DISPATCH_DAY_FORMAT} ${RESULTS_DISPATCH_HOUR_FORMAT}`,
    );
  }

  /**
   * Helper to interpret moment data in Tootltip to a more readable human info
   *
   * @param value - moment data
   */
  formatTooltipTitle(obj: any): string {
    return moment((obj || {}).name).format(
      `${MONTH_FORMAT} ${DAY_FORMAT}, ${YEAR_FORMAT} ${HOUR_FORMAT}`,
    );
  }

  injectAxisNames(data: StackedBarMeta): StackedBarMeta {
    return {
      ...data,
      xAxisName: 'Hours',
      yAxisName: 'Power [kW]',
      rightYAxisName: 'Storage SoC [%]',
    };
  }
}
