import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToText',
  standalone: false,
})
export class CamelToTextPipe implements PipeTransform {
  transform(camelCase: string): string {
    const ccSplit = camelCase.split(/(?=[A-Z])/).join(' ');
    return ccSplit;
  }
}
