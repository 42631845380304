import { Storage, Vehicle } from 'prosumer-app/+scenario/models';
import { EnergyVectorCascaderService } from 'prosumer-app/+scenario/services';
import { ProfileType } from 'prosumer-app/+scenario/types';
import { LoggerService } from 'prosumer-app/libs/eyes-core';
import {
  BaseComponent,
  CustomValidators,
  doNothing,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
} from 'prosumer-app/libs/eyes-shared';
import { convertToYearlyValues, NameValidator } from 'prosumer-app/shared';
import { LibraryService } from 'prosumer-app/stores';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EquipmentFormDialogData } from '../equipment-dialog.model';

@Component({
  selector: 'prosumer-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
  providers: [EnergyVectorCascaderService, TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class VehicleComponent extends BaseComponent implements OnInit {
  @ViewChild('submit', { read: ElementRef }) submitButton: ElementRef;
  submitted$ = new BehaviorSubject<boolean>(false);

  @Input() outputEnergyVectorOptions: Array<FormFieldOption<string>> = [];

  scenarioId: string;
  caseId: string;
  projectId: string;
  evWarningShown$ = new BehaviorSubject<boolean>(false);

  defaultValues: Vehicle = {
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    yearlyCapacityLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlySecondCapacityLoss: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyEfficiency2nd: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyRoundTripEfficiency: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxDoD: convertToYearlyValues(
      '1.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyAgingFactor: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyDissipationRate: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinPower: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxPower: convertToYearlyValues(
      '100000.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMinEnergy: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyMaxEnergy: convertToYearlyValues(
      '100000.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlySecondBuildCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyIndivisibleCost: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMCharge: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMInstall: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFOAndMChargeKWh: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyTechnicalLife: convertToYearlyValues(
      '20',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyDepreciationTime: convertToYearlyValues(
      '0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsKw: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsKwh: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyBuildEmissionsIndivisible: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
    yearlyFootprint: convertToYearlyValues(
      '0.0',
      this.data.startYear,
      this.data.endYear,
    ),
  };

  vehicleForm: UntypedFormGroup = this.formBuilder.group({
    id: '',
    name: '',
    energyVector: '',
    scenarioVariation: 'basecase',
    type: 'vehicle',
    sourceType: 'custom',
    yearlyCapacityLoss: [
      this.defaultValues.yearlyCapacityLoss,
      Validators.required,
    ],
    yearlySecondCapacityLoss: [
      this.defaultValues.yearlySecondCapacityLoss,
      Validators.required,
    ],
    yearlyEfficiency2nd: [
      this.defaultValues.yearlyEfficiency2nd,
      Validators.required,
    ],
    yearlyRoundTripEfficiency: [
      this.defaultValues.yearlyRoundTripEfficiency,
      Validators.required,
    ],
    yearlyMaxDoD: [this.defaultValues.yearlyMaxDoD, Validators.required],
    yearlyAgingFactor: [
      this.defaultValues.yearlyAgingFactor,
      Validators.required,
    ],
    yearlyDissipationRate: [
      this.defaultValues.yearlyDissipationRate,
      Validators.required,
    ],
    yearlyMinPower: [this.defaultValues.yearlyMinPower, Validators.required],
    yearlyMaxPower: [this.defaultValues.yearlyMaxPower, Validators.required],
    yearlyMinEnergy: [this.defaultValues.yearlyMinEnergy, Validators.required],
    yearlyMaxEnergy: [this.defaultValues.yearlyMaxEnergy, Validators.required],
    forcedInvestment: false,
    existingAsset: false,
    capacityExpansion: false,
    yearlyBuildCost: [this.defaultValues.yearlyBuildCost, Validators.required],
    yearlySecondBuildCost: [
      this.defaultValues.yearlySecondBuildCost,
      Validators.required,
    ],
    yearlyIndivisibleCost: [
      this.defaultValues.yearlyIndivisibleCost,
      Validators.required,
    ],
    yearlyFOAndMCharge: [
      this.defaultValues.yearlyFOAndMCharge,
      Validators.required,
    ],
    yearlyFOAndMInstall: [
      this.defaultValues.yearlyFOAndMInstall,
      Validators.required,
    ],
    yearlyFOAndMChargeKWh: [
      this.defaultValues.yearlyFOAndMChargeKWh,
      Validators.required,
    ],
    yearlyTechnicalLife: [
      this.defaultValues.yearlyTechnicalLife,
      Validators.required,
    ],
    yearlyDepreciationTime: [
      this.defaultValues.yearlyDepreciationTime,
      Validators.required,
    ],
    yearlyBuildEmissionsKw: [
      this.defaultValues.yearlyBuildEmissionsKw,
      Validators.required,
    ],
    yearlyBuildEmissionsKwh: [
      this.defaultValues.yearlyBuildEmissionsKwh,
      Validators.required,
    ],
    yearlyBuildEmissionsIndivisible: [
      this.defaultValues.yearlyBuildEmissionsIndivisible,
      Validators.required,
    ],
    yearlyFootprint: [this.defaultValues.yearlyFootprint],
    operatingCostProfiles: [null],
  });

  scenarioVariationOptions: Array<FormFieldOption<string>> = [];
  profileOptions: Array<FormFieldOption<ProfileType>> = [];

  nameCtrl: UntypedFormControl = this.vehicleForm.get(
    'name',
  ) as UntypedFormControl;
  scenarioVariationCtrl: UntypedFormControl = this.vehicleForm.get(
    'scenarioVariation',
  ) as UntypedFormControl;
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages.der');
  formType: string = this.vehicleForm.get('type').value;

  viewInitialized: boolean;

  energyVectorOptions$ = new BehaviorSubject<FormFieldOption<string>[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EquipmentFormDialogData<Storage>,
    private _changeDetector: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private _formService: FormService,
    private _logger: LoggerService,
    private _cascaderService: EnergyVectorCascaderService,
    public libraryService: LibraryService, // TODO: remove this if not needed
  ) {
    super();
  }

  ngOnInit(): void {
    this.initScenarioEntity();
    this.initOptions();
    this.initHandlers();
    this.initValidators();
    this.initInputOutputValueChanges();
    this.initForm();
  }

  initScenarioEntity() {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  initHandlers() {
    // Set sourceType to custom and disable it for now. Library to be added.
    this.vehicleForm.controls.sourceType.patchValue('custom');
    this.vehicleForm.controls.sourceType.disable();
  }

  initForm() {
    this.vehicleForm.patchValue({
      ...this.data.equipment,
      type: 'vehicle',
    });
  }

  initOptions() {
    if (this.data.profileOptions) {
      this.profileOptions = [...this.data.profileOptions];
    }

    if (this.data.scenarioVariationOptions) {
      this.scenarioVariationOptions.push(...this.data.scenarioVariationOptions);
    }
  }

  initValidators() {
    // Name Validators
    this.vehicleForm.controls.name.setValidators([
      NameValidator.validWithCore(),
    ]);
    const skipEquipment =
      this.data.mode === 'edit' ? this.data.equipment : null;
    this.nameCtrl.setAsyncValidators(
      CustomValidators.dataExist(this.data.equipment$, 'name', skipEquipment, {
        scenarioVariation: this.scenarioVariationCtrl,
      }),
    );
    this.scenarioVariationCtrl.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() => this.nameCtrl.updateValueAndValidity());

    // Existing Asset Validators (Apply same value validator for existing asset and forced investment)
    this.vehicleForm.controls.existingAsset.setValidators(
      CustomValidators.sameValue(this.vehicleForm.controls.forcedInvestment),
    );
    this.vehicleForm.controls.forcedInvestment.valueChanges
      .pipe(this.takeUntil())
      .subscribe(() =>
        this.vehicleForm.controls.existingAsset.updateValueAndValidity(),
      );
  }

  initInputOutputValueChanges() {
    const revertVectorValue = (currentProfileOptions, currentEnergyVector) => {
      if (!!currentProfileOptions && currentProfileOptions.length === 0) {
        doNothing();
      } else {
        this.vehicleForm.controls.energyVector.patchValue(currentEnergyVector);
      }
    };

    this.vehicleForm
      .get('energyVector')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        // show warning dialog only once
        if (this.evWarningShown$.value && this.vehicleForm.dirty) {
          return;
        }
        const currentEnergyVector = this.vehicleForm.value['energyVector'];
        const currentProfileOptions = this.energyVectorOptions$.value;

        let associatedTableVectors = [];
        const operatingCostProfileCtrlVal =
          this.vehicleForm.controls.operatingCostProfiles.value;
        if (
          !!operatingCostProfileCtrlVal &&
          !!operatingCostProfileCtrlVal.operatingCostProfiles
        ) {
          associatedTableVectors =
            operatingCostProfileCtrlVal.operatingCostProfiles.map(
              (profile) => profile.energyVectorId,
            );
        }

        const isOPAffected = this._cascaderService.isOperatingCostAffected(
          [currentEnergyVector],
          associatedTableVectors,
        );
        const isNettingAffected = this._cascaderService.isNettingAffected(
          this.vehicleForm.value['id'],
          [currentEnergyVector],
          this.data.currentNetting,
        );
        this._cascaderService
          .showCascadingWarningDialog([isOPAffected, isNettingAffected])
          .subscribe((isOk) => {
            if (isOk) {
              this.energyVectorOptions$.next(
                this.outputEnergyVectorOptions.filter(
                  (options) => options.value === value,
                ),
              );

              if (isNettingAffected) {
                this.evWarningShown$.next(true);
              }
            } else {
              revertVectorValue(currentProfileOptions, currentEnergyVector);
            }
          });
      });
  }

  onConfirm(): Vehicle {
    this.submit();
    this.submitted$.next(true);
    this._changeDetector.detectChanges();
    if (this.vehicleForm.valid) {
      const vehicleForm = this.vehicleForm.getRawValue();
      return {
        ...vehicleForm,
        operatingCostProfiles: vehicleForm?.operatingCostProfiles
          ? vehicleForm.operatingCostProfiles['operatingCostProfiles']
          : null,
      };
    }
  }

  submit(): void {
    if (!!this.submitButton && !!this.submitButton.nativeElement) {
      this.submitButton.nativeElement.click();
    }
  }
}
