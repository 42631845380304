import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { Result } from 'prosumer-scenario/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';

const DEFAULT_SUMMARY_MIN_SELECTION = 1;

@Component({
  selector: 'prosumer-compare-summary',
  templateUrl: './compare-summary.component.html',
  styleUrls: ['./compare-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompareSummaryComponent extends BaseComponent implements OnInit {
  @Input() referenceId: string;
  @Input() results: Array<Result>;
  @Input() minSelection = 1;
  @Input() maxSelection = 5;
  @Input() caseId: string;

  @Output() selectScenario = new EventEmitter();

  selection = new SelectionModel<string>(true, []);

  _selectable = new BehaviorSubject<boolean>(false);
  selectable$ = this._selectable.asObservable().pipe(this.takeUntilShare());

  @Input() set selectable(value: boolean) {
    this._selectable.next(value);
  }

  selectionChanged$ = this.selection.changed.pipe(this.takeUntilShare());

  maxSelectionReached$ = this.selectionChanged$.pipe(
    map((selection) => selection.source.selected.length === this.maxSelection),
    this.takeUntilShare(),
  );

  minSelectionReached$ = this.selectionChanged$.pipe(
    map((selection) => selection.source.selected.length === this.minSelection),
    this.takeUntilShare(),
  );

  colors$: Observable<PerceptionMap>;

  constructor(private perception: ResultsPerceptionService) {
    super();
  }

  ngOnInit() {
    this.selectable$.subscribe((selectable) => {
      if (selectable) {
        this.selection.clear();
        this.selection.select(
          ...this.results
            .map(this.getUniqueId)
            .slice(0, DEFAULT_SUMMARY_MIN_SELECTION + 1),
        );
      } else {
        this.selection.select(...this.results.map(this.getUniqueId));
      }
    });

    this.selectionChanged$.subscribe((selection) =>
      this.selectScenario.emit(
        selection.source.selected.map((id) => ({
          ...this.results.find((result) => this.getUniqueId(result) === id),
        })),
      ),
    );

    this.colors$ = this.perception.getPerceptionMapStream(this.caseId);
    this.registerLegendNames();
  }

  onSelectChip(id: string) {
    // istanbul ignore else
    if (
      !this.selection.isSelected(id) &&
      this.selection.selected.length === this.maxSelection
    ) {
      return;
    }

    // istanbul ignore else
    if (
      this.selection.isSelected(id) &&
      this.selection.selected.length === this.minSelection
    ) {
      return;
    }

    // istanbul ignore else
    if (this._selectable.value) {
      this.selection.toggle(id);
    }
  }

  private registerLegendNames(): void {
    this.colors$.pipe(take(1)).subscribe((currColors) => {
      const names = this.results.map((result) => result.name);
      this.perception.registerLegendNames(this.caseId, names);
    });
  }

  getUniqueId = (result: Result): string => result?.id + result?.name;
}
