import {
  generateShadedScheme,
  RESULTS_DEFAULT_SCHEME,
} from 'prosumer-app/app.references';
import {
  BaseComponent,
  contains,
  MetricPipe,
} from 'prosumer-app/libs/eyes-shared';
import { ResultStore } from 'prosumer-app/stores';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Color, ColorHelper, ScaleType } from '@swimlane/ngx-charts';

import {
  addBallotBox,
  removeBallotBox,
  updateLegendFilter,
} from '../../shared/helper';

export const _arrDaysAndMonths = () => ({
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
});

@Component({
  selector: 'prosumer-monthly-prod',
  templateUrl: './monthly-prod.component.html',
  styleUrls: ['./monthly-prod.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MonthlyProdComponent extends BaseComponent implements OnInit {
  monthlyData$: Observable<any>;
  data: Array<any>;
  tooltipData: Array<any> = [];
  firstYear: string;
  firstNode: string;
  firstEnergyVector: string;
  startingHour = 0;
  highContrastScheme = RESULTS_DEFAULT_SCHEME;
  chartTitle: string;
  defaultEnergyVector = 'electricity';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly CHART_ID = 'averageMonthly';

  legendFilter$ = new BehaviorSubject<Array<string>>([]);

  @Input() chartXAxisLabel = 'Month';
  @Input() chartYAxisLabel = 'kWh';
  @Input() chartXAxisFormatting = (label: string) =>
    this._titleCase.transform(label).substring(0, 3);
  @Input() chartTooltipTitleFormat = (value: any) =>
    `${this._titleCase.transform(value.series)} ${this.firstYear}`;

  constructor(
    private _resultStore: ResultStore,
    private _titleCase: TitleCasePipe,
    private _metric: MetricPipe,
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this._resultStore.getRawDispatchFilterOptions$('year').pipe(
        take(1),
        filter((yearOptions) => yearOptions && yearOptions.length > 0),
        map((yearOptions) => yearOptions[0].value),
        tap((year) => (this.firstYear = year)),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.getRawDispatchFilterOptions$('node').pipe(
        take(1),
        filter((nodeOptions) => nodeOptions && nodeOptions.length > 0),
        map((nodeOptions) => nodeOptions[0].value),
        tap((node) => (this.firstNode = node)),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.getRawDispatchFilterOptions$('energyVector').pipe(
        take(1),
        filter(
          (energyVectorOptions) =>
            energyVectorOptions && energyVectorOptions.length > 0,
        ),
        map((energyVectorOptions) => energyVectorOptions[0].value),
        takeUntil(this.componentDestroyed$),
      ),
      this._resultStore.chartData$(this.CHART_ID).pipe(take(1)),
      this.legendFilter$,
    ])
      .pipe(
        switchMap(([year, node, energyVector, cached, legendFilter]) =>
          this._resultStore
            .getRawDispatchByParams$(
              year,
              node,
              this.defaultEnergyVector,
              'production',
            )
            .pipe(
              tap(() => (this.startingHour = 0)),
              switchMap((data) => {
                if (!data || data.length === 0) {
                  return this._resultStore
                    .getRawDispatchByParams$(
                      year,
                      node,
                      energyVector,
                      'production',
                    )
                    .pipe(tap(() => (this.firstEnergyVector = energyVector)));
                }
                return of(data).pipe(
                  tap(
                    () => (this.firstEnergyVector = this.defaultEnergyVector),
                  ),
                );
              }),
              map((filteredData) => {
                if (cached) {
                  return cached.map((c) => ({
                    ...c,
                    series: c.series.map((s) =>
                      contains(legendFilter, removeBallotBox(s.name))
                        ? {
                            name: addBallotBox(
                              legendFilter,
                              removeBallotBox(s.name),
                            ),
                            value: 0,
                          }
                        : s,
                    ),
                  }));
                } else {
                  return Object.keys(_arrDaysAndMonths()).map((month) => {
                    const endingHour =
                      this.startingHour + _arrDaysAndMonths()[month] * 24 + 1;
                    // Initialize the bar section in the graph (der object)
                    const derOfTheMonth = {
                      name: month,
                      // Construct a bar piece for the stacked bar (der entry)
                      series: filteredData.map((derEntry) => ({
                        name: addBallotBox(legendFilter, derEntry.der),
                        // Get the sum value for that month based on starting hour and end
                        value: contains(legendFilter, derEntry.der)
                          ? 0
                          : Number(
                              derEntry.values
                                .slice(this.startingHour, endingHour)
                                .reduce((acc, hourlyVal) => acc + hourlyVal, 0)
                                .toFixed(1),
                            ),
                      })),
                    };
                    this.startingHour = endingHour + 1;
                    return derOfTheMonth;
                  });
                }
              }),
              takeUntil(this.componentDestroyed$),
              shareReplay(1),
              take(1),
              tap((chartData) => {
                if (!cached && legendFilter.length === 0) {
                  this._resultStore.updateChartDataMapping(
                    this.CHART_ID,
                    chartData,
                  );
                }
                this.highContrastScheme = generateShadedScheme(
                  chartData.length,
                );
              }),
              takeUntil(this.componentDestroyed$),
            ),
        ),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((data) => {
        this.data = data;
      });

    this.chartTitle = `Monthly Production (${this.firstEnergyVector} - ${this.firstNode} - ${this.firstYear})`;
  }

  onSelect(selected: string) {
    updateLegendFilter(this.legendFilter$, selected);
  }

  getSeriesMembers(seriesModel: any) {
    this.data.forEach((seriesObject) => {
      if (seriesObject['name'] === seriesModel.value.series) {
        this.tooltipData = seriesObject['series'].map((item) => ({
          series: item.name,
          value: item.value,
          name: seriesObject['name'],
          current: seriesModel.value.name === seriesObject['name'],
          color: new ColorHelper(
            this.highContrastScheme as Color,
            ScaleType.Ordinal,
            seriesObject['series'].map((d) => d.name),
          ).getColor(item.name),
        }));
      }
    });
  }
}
