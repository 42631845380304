import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DetailEntityDuplicator } from 'prosumer-app/+scenario/directives/detail-duplicator/detail-entity.duplicator';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { DuplicateDetailData } from 'prosumer-app/stores';
import { LoadQuery, LoadStore } from 'prosumer-app/stores/load';

@Component({
  selector: 'prosumer-load-duplicate-dialog',
  templateUrl: './load-duplicate-dialog.component.html',
  styleUrls: ['./load-duplicate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LoadDuplicateDialogComponent extends DetailEntityDuplicator {
  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: DuplicateDetailData,
    readonly dialogRef: MatDialogRef<LoadDuplicateDialogComponent>,
    readonly loads: LoadStore,
    readonly loadQuery: LoadQuery,
    readonly notifs: NotificationsService,
  ) {
    super(data, loads, loadQuery, dialogRef, notifs);
  }
}
