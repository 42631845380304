import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Node } from 'prosumer-app/+scenario';
import {
  BaseComponent,
  FormFieldErrorMessageMap,
  FormFieldOption,
  FormService,
  convertDataValuesToString,
  doNothing,
} from 'prosumer-app/libs/eyes-shared';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TaxSubsidyFormDialogData } from './tax-subsidy-form-dialog.model';

@Component({
  selector: 'prosumer-tax-subsidy-form-dialog',
  templateUrl: './tax-subsidy-form-dialog.component.html',
  styleUrls: ['./tax-subsidy-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TitleCasePipe],
  standalone: false,
})
export class TaxSubsidyFormDialogComponent
  extends BaseComponent
  implements OnInit
{
  taxSubsidyForm: UntypedFormGroup;
  isMultiNode: boolean;
  nettingOptions: Array<FormFieldOption<string>> = [];
  nodeOptions: Array<Node> = [];

  submitted$ = new BehaviorSubject<boolean>(false);
  errorMessages: FormFieldErrorMessageMap =
    this._formService.getErrorMessageMap('Scenario.messages');
  isViewOnly: boolean;

  get nettingCtrl() {
    return this.taxSubsidyForm.controls.netting;
  }
  get nodesCtrl() {
    return this.taxSubsidyForm.controls.nodes;
  }
  get taxSubsidyIntervalsCtrl() {
    return this.taxSubsidyForm.controls.taxSubsidyIntervals;
  }
  get startYearCtrl() {
    return this.taxSubsidyForm.controls.startYear;
  }
  get endYearCtrl() {
    return this.taxSubsidyForm.controls.endYear;
  }
  get isFormValid() {
    return [this.taxSubsidyForm.valid, !this._hasInvalidInterval].every(
      Boolean,
    );
  }
  get isFormPristine() {
    return this.taxSubsidyForm.pristine;
  }

  private _hasInvalidInterval = false;
  hasInvalidInterval(value: boolean) {
    this._hasInvalidInterval = value;
  }
  get invalidInterval() {
    return this._hasInvalidInterval;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaxSubsidyFormDialogData,
    public dialogRef: MatDialogRef<TaxSubsidyFormDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _formService: FormService,
  ) {
    super();
    this.taxSubsidyForm = this.initForm();
  }

  formatFormValues() {
    const values = this.taxSubsidyForm.getRawValue();
    const { netting, nodes, taxSubsidyIntervals } = values;
    return {
      ...(!!values.id && { id: values.id }),
      netting,
      nodes: this.isMultiNode ? nodes['nodes'] || nodes || [] : nodes,
      taxSubsidyIntervals: taxSubsidyIntervals
        ? this._cureTaxSubsInterval(taxSubsidyIntervals).map((interval) =>
            convertDataValuesToString(interval, ['startYear', 'endYear']),
          )
        : [],
    };
  }

  onSaveSuccess(): void {
    this.dialogRef.close();
  }

  onSaveAttempt() {
    this.submitted$.next(true);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  initData() {
    this.isMultiNode = this.data.isMultiNode;
    this.isViewOnly = this.data.isViewOnly;

    if (this.data.editData.id) {
      this.taxSubsidyForm.controls.id.patchValue(this.data.editData.id, {
        emitEvent: false,
      });
    }

    if (!this.isMultiNode || this.data.mode === 'edit') {
      this.nodesCtrl.patchValue(this.data.editData.nodes, { emitEvent: false });
    }

    if (this.data.mode === 'edit') {
      this.nettingCtrl.patchValue(this.data.editData.netting, {
        emitEvent: false,
      });
      this.taxSubsidyIntervalsCtrl.patchValue(
        this.data.editData.taxSubsidyIntervals,
        { emitEvent: false },
      );
    }

    combineLatest([
      this.taxSubsidyForm.get('netting').valueChanges,
      this.taxSubsidyForm.get('nodes').valueChanges,
      this.taxSubsidyForm.get('taxSubsidyIntervals').valueChanges,
    ])
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.nettingCtrl.clearAsyncValidators();
        this.nodesCtrl.clearAsyncValidators();
        this.taxSubsidyIntervalsCtrl.clearAsyncValidators();
      });
  }

  initForm() {
    return this._formBuilder.group({
      id: [''],
      netting: ['', Validators.required],
      nodes: [[]],
      taxSubsidyIntervals: [],
      startYear: this.data.startYear,
      endYear: this.data.endYear,
    });
  }

  initOptions() {
    if (this.data.nettingOptions) {
      this.data.nettingOptions.forEach((option) =>
        this.nettingOptions.push(option),
      );
    }

    if (this.data.nodeOptions) {
      this.data.nodeOptions.forEach((option) =>
        option ? this.nodeOptions.push(option) : doNothing,
      );
    }
  }

  ngOnInit(): void {
    this.initOptions();
    this.initData();
  }

  private _cureTaxSubsInterval(taxSubsidyIntervals: any): any[] {
    if (taxSubsidyIntervals && taxSubsidyIntervals.intervals)
      return taxSubsidyIntervals.intervals;
    return taxSubsidyIntervals;
  }
}
