import { Directive, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { VariationInfoService } from 'prosumer-app/+scenario/services/variation-info/variation-info.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  UpdateStatus,
  SavingStatus,
} from '../scenario-updater/scenario-updater.model';
import { PipeUtils, Coerce } from 'prosumer-app/core/utils';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
} from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { ScenarioVariationInfo } from 'prosumer-app/stores/scenario-variation/scenario-variation.state';

@UntilDestroy()
@Directive({
  selector: '[prosumerVariationUpdater]',
  standalone: false,
})
export class ScenarioVariationUpdaterDirective implements OnInit {
  @Output() statusChange = new EventEmitter<Partial<UpdateStatus>>();

  @Input() invalid = false;
  @Input() hasSkip = true;
  @Input() set nextValue(value: Partial<ScenarioVariationInfo>) {
    this._valueCache.next(value);
  }
  private readonly _valueCache = new BehaviorSubject<
    Partial<ScenarioVariationInfo>
  >(null);
  private readonly valueCache$ = this._valueCache.asObservable();

  constructor(private readonly variationInfo: VariationInfoService) {}

  ngOnInit(): void {
    this.subscribeToNewValueForScenarioUpdating();
  }

  private subscribeToNewValueForScenarioUpdating(): void {
    this.selectNewValue()
      .pipe(
        untilDestroyed(this),
        filter(() => !this.invalid),
      )
      .subscribe((value) => this.updateVariation(value));
  }

  private selectNewValue(): Observable<Partial<ScenarioVariationInfo>> {
    return this.valueCache$.pipe(
      PipeUtils.filterOutNullUndefined,
      debounceTime(900),
      skip(this.getSkipValue()),
      // here value is object, hence different every time
      // distinctUntilChanged((prev, curr) => prev == curr), // eslint-disable-line
    );
  }

  private updateVariation(value: Partial<ScenarioVariationInfo>) {
    this.emitUpdateStatus('loading');
    this.variationInfo.updateVariation(value.variationUuid, value).subscribe({
      next: this.handleUpdateSuccess.bind(this),
      error: this.handleUpdateError.bind(this),
    });
  }

  private emitUpdateStatus(status: SavingStatus, message: string = '') {
    this.statusChange.emit({ status, message });
  }

  private handleUpdateSuccess(): void {
    this.emitUpdateStatus('success');
  }
  private handleUpdateError(error: HttpErrorResponse): void {
    this.emitUpdateStatus('failed', this.getErrorMessage(error));
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    return Coerce.toObject(error.error).error ?? error.message;
  }

  private getSkipValue(): number {
    return +this.hasSkip;
  }
}
