import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  PerceptionMap,
  ResultsPerceptionService,
} from '@prosumer/results/components/case-results-perception';
import { ComparePaybackResult } from 'prosumer-app/+scenario/models';
import { BaseComponent } from 'prosumer-app/libs/eyes-shared';
import { CompareCashFlowResultItem } from 'prosumer-scenario/models';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'prosumer-cashflow-container',
  templateUrl: './cashflow-container.component.html',
  styleUrls: ['./cashflow-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CashflowContainerComponent
  extends BaseComponent
  implements OnInit
{
  @Input() cashFlowResults: Array<CompareCashFlowResultItem> = [];
  @Input() caseId: string;
  @Input() customColors = [];

  colors$: Observable<PerceptionMap>;

  constructor(private perception: ResultsPerceptionService) {
    super();
  }

  @Input() paybackResults: Array<ComparePaybackResult> = [];

  valueOptions: Array<any> = [
    {
      key: 'Payback',
      value: 'Payback',
    },
    {
      key: 'Cashflow',
      value: 'Cashflow',
    },
  ];

  valueToggleControl = new UntypedFormControl(this.valueOptions[0]['value']);

  valueToggle$ = this.valueToggleControl.valueChanges.pipe(
    startWith(this.valueToggleControl.value),
    this.takeUntilShare(),
  );

  ngOnInit(): void {
    this.colors$ = this.perception.getPerceptionMapStream(this.caseId);
  }
}
