import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { contrastColor } from 'prosumer-app/libs/contrast-color';
import { ColorPickerService } from 'ngx-color-picker';
import { Case } from 'prosumer-app/+case/models';
import { COLOR_PICKER_PRESET_COLORS } from 'prosumer-app/app.references';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  debounceTime,
  map,
  startWith,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { PerceptionMap } from './results-perception.model';
import { ResultsPerceptionService } from './results-perception.service';

@Component({
  selector: 'prosumer-results-perception',
  templateUrl: './case-results-perception.component.html',
  styleUrls: ['./case-results-perception.component.scss'],
  providers: [ColorPickerService],
  standalone: false,
})
export class CaseResultsPerceptionComponent implements OnInit {
  @Input() caseObject: Case;
  @Output() perceptionChange = new EventEmitter<PerceptionMap>();
  presetColors = COLOR_PICKER_PRESET_COLORS;
  presetLabel = 'Available Engie Colors';

  private contrastMap = new BehaviorSubject<PerceptionMap>({});
  private perceptionMap = new BehaviorSubject<PerceptionMap>({});
  private resultNames = new BehaviorSubject<string[]>([]);

  perceptionMap$: Observable<PerceptionMap>;
  contrastMap$: Observable<PerceptionMap>;
  resultNames$: Observable<string[]>;
  searchControl = new UntypedFormControl('');

  constructor(private service: ResultsPerceptionService) {}

  ngOnInit(): void {
    this.perceptionMap$ = this.perceptionMap.asObservable();
    this.contrastMap$ = this.contrastMap.asObservable();
    this.resultNames$ = this.getFilteredResultNamesStream();

    this.quicklySubscribeToServiceForPerceptionMapInit();
    this.quicklySubscribeToPerceptionMapForResultNamesInit();
    this.subscribeToPerceptionMapForOutputEmission();
  }

  onColorChange(name: string, color: string): void {
    this.perceptionMap.next({ ...this.perceptionMap.value, [name]: color });
    this.contrastMap.next({
      ...this.contrastMap.value,
      [name]: this.getContrastColor(color),
    });
  }

  private subscribeToPerceptionMapForOutputEmission(): void {
    this.perceptionMap
      .pipe(debounceTime(100))
      .subscribe((data) => this.perceptionChange.emit(data));
  }

  private getFilteredResultNamesStream(): Observable<string[]> {
    return this.searchControl.valueChanges.pipe(
      startWith(this.searchControl.value),
      withLatestFrom(this.resultNames),
      map(([query, names]) => this.filterNames(query, names)),
      map((names) => names.sort()),
    );
  }

  private filterNames(query: string, names: string[]): string[] {
    const queries = query.split(' ');
    return names.filter((name) =>
      queries
        .filter((q) => !!q)
        .every((q) => name.toLowerCase().includes(q.toLowerCase())),
    );
  }

  private quicklySubscribeToPerceptionMapForResultNamesInit(): void {
    this.perceptionMap
      .pipe(take(1))
      .subscribe((perMap) => this.resultNames.next(Object.keys(perMap)));
  }

  private quicklySubscribeToServiceForPerceptionMapInit(): void {
    this.service
      .getPerceptionMapStream(this.caseObject?.id)
      .pipe(take(1))
      .subscribe((perMap) => this.perceptionMap.next(perMap));
  }

  private getContrastColor(bgColor: string): string {
    return contrastColor({ bgColor, threshold: 100 });
  }
}
