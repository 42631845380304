import {
  LibraryLoads,
  OperatingCostDialogData,
  ParameterType,
  Profile,
} from 'prosumer-app/+scenario/models';
import { Utils } from 'prosumer-app/core/utils';
import { BaseComponent, FormFieldOption } from 'prosumer-app/libs/eyes-shared';
import {
  ProfileFormHelperService,
  YearlyLoadsIntevalValidators,
} from 'prosumer-app/shared';
import { NotificationsService } from 'prosumer-app/shared/services/notification';
import { ScenarioBinStore } from 'prosumer-app/stores';
import {
  CreateOpCostBody,
  OpCostForm,
  OperationalCostTranslator,
} from 'prosumer-app/stores/op-costs';
import { YearlyLoadMessageConfig } from 'prosumer-shared/components/yearly-loads/yearly-loads.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, startWith, take, takeUntil } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'prosumer-operating-cost-dialog',
  templateUrl: './operating-cost-dialog.component.html',
  styleUrls: ['./operating-cost-dialog.component.scss'],
  providers: [ProfileFormHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OperatingCostDialogComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  parameterType$: Observable<FormFieldOption<string>[]>;
  binaryLoading$: Observable<boolean>;
  operatingCostForm: UntypedFormGroup;
  scenarioId: string;
  caseId: string;
  projectId: string;
  formValue$: Observable<unknown>;
  currentValue$: Observable<CreateOpCostBody>;

  yearlyLoadMessage: YearlyLoadMessageConfig;

  get parameterTypeCtrl() {
    return this.operatingCostForm.get('parameterType');
  }
  get outputEnergyVectorCtrl() {
    return this.operatingCostForm.get('outputEnergyVector');
  }
  get inputEnergyVectorCtrl() {
    return this.operatingCostForm.get('inputEnergyVector');
  }
  get isFormValid() {
    return this.operatingCostForm.valid;
  }
  get isFormPrestine() {
    return this.operatingCostForm.pristine;
  }
  get parameterType() {
    const parameterTypeValue = this.data.operatingCost.parameterType;
    return !!parameterTypeValue ? parameterTypeValue : ParameterType.PDOC;
  }
  get energyVectorId() {
    return this.data.operatingCost.energyVectorId;
  }
  get outputEnergyVectorsOptions() {
    return this.data.outputEnergyVectorsOptions;
  }
  get inputEnergyVectorsOptions() {
    return this.data.inputEnergyVectorsOptions;
  }

  isPDOC$ = new BehaviorSubject<boolean>(true);
  activeIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OperatingCostDialogData,
    public binaryStore: ScenarioBinStore,
    public profileFormHelperService: ProfileFormHelperService,
    private dialogRef: MatDialogRef<OperatingCostDialogComponent>,
    private _formBuilder: UntypedFormBuilder,
    private readonly notificationService: NotificationsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
    this.initYearlyMessages();
    this.binaryLoading$ = this.binaryStore.loading$;
    this.parameterType$ = this.initParameterType();
    this.operatingCostForm = this.initForm();
    this.initEditForm();
    this.subscribeToParameterChange();
    this.currentValue$ = this.selectCurrentValue();
  }

  ngAfterViewInit(): void {
    this.addProfilesCtrlValidator();
  }

  initData() {
    this.projectId = this.data.scenarioIdentity.projectId;
    this.caseId = this.data.scenarioIdentity.caseId;
    this.scenarioId = this.data.scenarioIdentity.scenarioId;
  }

  initYearlyMessages() {
    this.yearlyLoadMessage = {
      loadTypeLabel: 'Scenario.labels.operationalCost.profile',
      loadTypeTooltip: 'TBD',
      loadsDataPlaceholder: 'Scenario.labels.operationalCost.profile',
      loadsDataLabelCustom: 'Scenario.labels.operationalCost.profile',
      loadsDataRequired: 'Scenario.labels.operationalCost.required',
    };
    if (this.data.formType === 'vre') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_renewable.wizard_renewable_operational_cost_hourly_profile';
    } else if (this.data.formType === 'storage') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_storage.wizard_storage_operational_cost_hourly_profile';
    } else if (this.data.formType === 'generator') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_generator.wizard_generator_operational_cost_hourly_profile';
    } else if (this.data.formType === 'station') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_station.wizard_station_operational_cost_hourly_profile';
    } else if (this.data.formType === 'vehicle') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_vehicle.wizard_vehicle_operational_cost_hourly_profile';
    } else if (this.data.formType === 'converter') {
      this.yearlyLoadMessage.loadsDataTooltip =
        'wizard_converter.wizard_converter_operational_cost_hourly_profile';
    }
  }

  initParameterType(): Observable<FormFieldOption<string>[]> {
    return new BehaviorSubject<FormFieldOption<string>[]>([
      { name: ParameterType.PDOC, value: ParameterType.PDOC },
      { name: ParameterType.FDOC, value: ParameterType.FDOC },
    ]).pipe(
      takeUntil(this.componentDestroyed$),
      map((paramTypes) =>
        paramTypes.filter((paramType) => {
          let pt = null;
          if (this.data.formType === 'vre') {
            this.parameterTypeCtrl.patchValue(ParameterType.PDOC);
            pt = paramType.value === ParameterType.PDOC;
          } else if (this.data.formType === 'storage') {
            this.parameterTypeCtrl.patchValue(ParameterType.FDOC);
            pt = paramType.value === ParameterType.FDOC;
          } else {
            this.parameterTypeCtrl.patchValue(this.parameterType);
            pt = paramType;
          }
          return pt;
        }),
      ),
    );
  }

  initForm() {
    return this._formBuilder.group({
      profiles: [null],
      parameterType: ['', Validators.required],
      outputEnergyVector: [null],
      inputEnergyVector: [null],
      startYear: this.data.startYear,
      endYear: this.data.endYear,
    });
  }

  subscribeToParameterChange() {
    return this.parameterTypeCtrl.valueChanges
      .pipe(map((value) => value === ParameterType.PDOC))
      .subscribe((value) => {
        this.toggleEVValidatorRequired(value);
        this.isPDOC$.next(value);
      });
  }

  toggleEVValidatorRequired(typeOfPDOC: boolean) {
    if (typeOfPDOC) {
      this.outputEnergyVectorCtrl.setValidators([Validators.required]);
      this.inputEnergyVectorCtrl.setValidators([]);
    } else {
      this.outputEnergyVectorCtrl.setValidators([]);
      this.inputEnergyVectorCtrl.setValidators([Validators.required]);
    }
    this.outputEnergyVectorCtrl.updateValueAndValidity({ emitEvent: true });
    this.inputEnergyVectorCtrl.updateValueAndValidity({ emitEvent: true });
  }

  initEditForm() {
    if (this.data.mode === 'edit') {
      if (this.parameterType === ParameterType.PDOC) {
        this.outputEnergyVectorCtrl.patchValue(this.energyVectorId);
      } else {
        this.inputEnergyVectorCtrl.patchValue(this.energyVectorId);
      }

      if (this.data.operatingCost.profiles !== undefined) {
        this.operatingCostForm.controls.profiles.patchValue(
          this.data.operatingCost.profiles.map((profile) => ({
            ...profile,
            loadProfile: profile.loadProfile || [],
            loadType: profile.loadType || this.resolveProfileLoadType(profile),
          })),
        );
      }

      if (
        !!!this.data.operatingCost.profiles[0].loadProfile ||
        this.data.operatingCost.profiles[0].loadProfile.length === 0
      ) {
        this.loadBinaryData(this.data.operatingCost.profiles[0]);
      }
    }
  }

  resolveProfileLoadType(profile: Profile): string | undefined {
    if (profile.isCustom) {
      return 'custom';
    }
    return undefined;
  }

  getEnergyVector(parameterType) {
    if (parameterType === ParameterType.PDOC) {
      return this.outputEnergyVectorsOptions.filter(
        (option) => option.value === this.outputEnergyVectorCtrl.value,
      )[0];
    } else {
      return this.inputEnergyVectorsOptions.filter(
        (option) => option.value === this.inputEnergyVectorCtrl.value,
      )[0];
    }
  }

  formatFormValues(values) {
    const { parameterType, startYear, endYear, profiles } = values;
    const { value: energyVectorId, name: energyVectorName } =
      this.getEnergyVector(parameterType);

    return {
      parameterType,
      energyVectorId,
      energyVectorName,
      startYear,
      endYear,
      profiles,
    };
  }

  onClose() {
    this.dialogRef.close();
  }

  loadBinaryData(selectedProfile: Profile) {
    this.loadBinaryData$(selectedProfile)
      .pipe(take(1))
      .subscribe((loads) => {
        if (!!loads) {
          const profile = (loads || ({} as LibraryLoads)).data;
          this.profileFormHelperService.setProfile({
            index: this.activeIndex,
            profiles: profile,
            type: 'bin',
          });
        }
      });
  }

  loadBinaryData$(selectedProfile: Profile) {
    if (
      !!selectedProfile &&
      (!!!selectedProfile.loadProfile ||
        selectedProfile.loadProfile.length === 0)
    ) {
      return this.binaryStore
        .get(
          this.projectId,
          this.caseId,
          this.scenarioId,
          selectedProfile.location,
          selectedProfile.originalLocalId || selectedProfile.localId,
          false,
        )
        .pipe(
          filter((loads) => !!loads),
          take(1),
        );
    }
    return of(null);
  }

  onSelectInterval(value: { selectedProfile: Profile; index: number }) {
    this.activeIndex = value.index;
    if (
      this.data.mode === 'edit' &&
      this.profileFormHelperService.forBinDownload(
        this.data.operatingCost.profiles,
        value.selectedProfile.localId,
      )
    ) {
      this.loadBinaryData(value.selectedProfile);
    }
  }

  onUpsertOk(): void {
    this.dialogRef.close(this.formatFormValues(this.operatingCostForm.value));
  }

  onErrorResponse(error: HttpErrorResponse): void {
    const errObj = Utils.resolveToEmptyObject(error.error);
    this.notificationService.showError(errObj['error'] ?? error.message);
  }

  private selectCurrentValue(): Observable<CreateOpCostBody> {
    const translator = new OperationalCostTranslator();
    return this.selectFormValue().pipe(
      map((formValue) => translator.fromForm(formValue)),
    );
  }

  private selectFormValue(): Observable<OpCostForm> {
    return this.operatingCostForm.valueChanges.pipe(
      startWith(this.operatingCostForm.value),
    );
  }

  private addProfilesCtrlValidator() {
    this.operatingCostForm.controls.profiles.setValidators([
      YearlyLoadsIntevalValidators.yearlyLoadsValid(),
    ]);
    this.operatingCostForm.controls.profiles.updateValueAndValidity();
  }
}
